import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import "react-circular-progressbar/dist/styles.css";
import "./CurrentUsageStyle.css";
import { generateRandomColor, formatDateToCustomString, formatDateToMonthYear } from "utils/helpers/Common";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const CurrentUsageView = ({ usage, handleRetryPayment, showUsageGraph, companyBillingType }) => {
	const barColor = "rgba(30, 144, 255, 1)";
	const data = {
		labels: usage?.programs_usage?.map((item) => item.program_name),
		datasets: [
			{
				label: "Projects",
				data: usage?.programs_usage?.map((item) => item.projects_count),
				backgroundColor: barColor,
				borderColor: barColor,
				borderWidth: 1,
				borderRadius: 2,
			},
		],
	};

	const options = {
		indexAxis: "y",
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: { display: false },
			tooltip: { enabled: true },
		},
		scales: {
			x: {
				beginAtZero: true,
				ticks: {
					stepSize: 1,
					precision: 0,
					callback: (value) => Number(value),
				},
				grid: {
					drawBorder: true,
					drawTicks: true,
					color: "transparent",
				},
			},
			y: {
				grid: {
					drawBorder: true,
					drawTicks: true,
					color: "transparent",
				},
			},
		},
	};
	
	return (
		<div className="pageCard currentUsage">
			{usage && (
				<>
					{usage?.status && (
						<div className="textboxBar">
							{usage?.plan?.canceled ? (
								<p>
									You have successfully unsubscribed from your current plan which ends on
									{` ${formatDateToCustomString(usage?.plan?.ends_at)}`}. Your new plan will default to Plan 1 under which you will be charged per project. You will still be able to
									access your account and view existing projects. If you subscribe to a new plan, it will start at the beginning of your next billing cycle.
								</p>
							) : usage?.status == "retry_payment" || usage?.status == "grace_period" ? (
								<>
									<p>
										<span>Your account is on hold. Retry your payments?</span>
									</p>
									<p>
										We couldn’t process your last payment due to
										{` "${usage?.reason ?? ""}" `}. Retry to keep enjoying our services
									</p>
									<button className="billingBtn reTryBtn" onClick={handleRetryPayment}>
										Re-try
									</button>
								</>
							) : (
								""
							)}
						</div>
					)}

					<div className="tagsBarFlex mb-2">
						<h4 className="h4MdTitle mb-0 mt-0">Current Plan</h4>
						<span className="activeTagBtn">{usage?.plan?.title}</span>
						<span className="activeTagBtn">{companyBillingType ?? ''}</span>
						{usage?.status == "retry_payment" && <span className="activeTagBtn bg-danger">Expired</span>}
					</div>
					{usage?.plan?.price != 0 && (
						<>
							<p className="smText mb-2">
								Renewal:
								{` ${usage?.plan?.ends_at && formatDateToCustomString(usage?.plan?.ends_at)}`}
							</p>
							<p className="smText mb-4">
								Current Billing Cycle:
								{usage?.plan?.starts_at && usage?.plan?.ends_at && <>{` ${formatDateToMonthYear(usage?.plan?.starts_at)} - ${formatDateToMonthYear(usage?.plan?.ends_at)}`}</>}
							</p>
						</>
					)}
					<div className="pannalColum">
						{usage?.plan?.price != 0 && <h5 className="pannelSmTitle">Cost</h5>}
						<div className="pannelRow">
							<p>Plan Cost</p>
							<h3>${usage?.plan?.price}</h3>
						</div>
					</div>
					{usage?.plan?.price == 0 && (
						<div className="pannalColum">
							<div className="pannelRow">
								<p>Additional Projects Cost</p>
								<h3>${usage?.additional_projects_cost}</h3>
							</div>
							<div className="pannelRow mt-2">
								<p>Additional Projects</p>
								<p>{usage?.plan_one_usage}</p>
							</div>
						</div>
					)}
					{usage?.plan?.price != 0 && (
						<div className="priceCardsAea">
							<div className="row">
								<div className="col-md-12">
									<h5 className="pannelSmTitle">Plan Usage</h5>
									<div className="priceCard progressCard">
										{(usage?.utilized_projects > 0 && showUsageGraph) && (
											<div className="progressBox">
												<Bar data={data} options={options} />
											</div>
										)}
										<div className="progressContent">
											<div className="prCardTitleBar">
												<div className="spaceBetweenBar">
													<p>Projects Used</p>
													<p>{`${usage?.utilized_projects} / ${usage?.plan?.features?.jobs_per_month_include}`}</p>
												</div>
												<div className="spaceBetweenBar">
													<p>Additional Projects</p>
													<p>{usage?.additional_projects}</p>
												</div>
											</div>
											<div className="cardFtPannel">
												<div className="cardFtPannelItem">
													<p className="pXSText">Total Projects</p>
												</div>
												<div className="cardFtPannelItem">
													<span>{usage?.total_projects}</span>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-12">
									<h5 className="pannelSmTitle">Current Billing Cycle</h5>
									<div className="priceCard invoiceCard">
										<div className="priceTblArea">
											<table className="crdTabel">
												<thead>
													<tr>
														<th className="crdTblTh">
															<p className="tblText">Title</p>
														</th>
														<th className="crdTblTh">
															<p className="tblText">Total Projects</p>
														</th>
														<th className="crdTblTh">
															<p className="tblText">Per Project</p>
														</th>
														<th className="crdTblTh">
															<p className="tblText">Total</p>
														</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td className="crdTblTh">
															<p className="tblText">Plan Price</p>
														</td>
														<td className="crdTblTh">
															<p className="tblText">{usage?.plan?.features?.jobs_per_month_include}</p>
														</td>
														<td className="crdTblTh">
															<p className="tblText">-</p>
														</td>
														<td className="crdTblTh">
															<p className="tblText bold">${usage?.plan?.price}</p>
														</td>
													</tr>
													<tr className="">
														<td className="crdTblTh">
															<p className="tblText">Additional Projects</p>
														</td>
														<td className="crdTblTh">
															<p className="tblText">{usage?.additional_projects}</p>
														</td>
														<td className="crdTblTh">
															<p className="tblText">${usage?.plan?.features?.each_additional_job}</p>
														</td>
														<td className="crdTblTh">
															<p className="tblText bold">${usage?.additional_projects_cost}</p>
														</td>
													</tr>

													<tr className="lastTrBdr">
														<td className="crdTblTh" colSpan={4}>
															<div className="d-flex align-items-center innvoiceTotalBar">
																<p className="tblText">Total Due to Date</p>
																<p className="tblText rateText">${usage?.plan?.price + usage?.additional_projects * usage?.plan?.features?.each_additional_job}</p>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default CurrentUsageView;
