import React, { useState } from "react";
import "./CompanyUserListStyle.css";
import { Link } from "react-router-dom";
import InviteUser from "../invite/InviteUser";
import { getInitialsFromName, useCheckCompanyPermission, useGetProgramsWhereUserHasPermission } from "utils/helpers/Common";
import EditCompanyUser from "../edit/EditCompanyUser";
import ConfirmationAlert from "components/alert/confirmation/ConfirmationAlert";
import ProgramDetails from "./program/ProgramDetails";
import { COMPANY_MANAGER, PROGRAM_ADMIN } from "utils/constants/UserRoles";
import { COMPANY_STANDARD_PROGRAM } from "utils/constants/Constants";
import { INVITE_MANAGE_USERS } from "utils/constants/UserPermissions";
import LegalDocumentModal from "pages/auth/signup/LegalDocumentModal";
import { ROLE_DESCRIPTION_LINK } from "utils/constants/Constants";


const CompanyUserListView = ({
	users,
	company,
	selectedUser,
	setSelectedUser,
	usersMutate,
	handleUserStatusChange,
	authUser,
	selectedProgram,
	setSelectedProgram,
	invitedUsers,
	invitedUsersMutate,
	programId = null,
}) => {
	const [showRoleDescription, setShowRoleDescription] = useState(false);
	const hasCompanyPermission = useCheckCompanyPermission([INVITE_MANAGE_USERS]);
	const userPrograms = useGetProgramsWhereUserHasPermission([INVITE_MANAGE_USERS]);
	return (
		<>
			<div className="pageCard">
				<h4 className="h4MdTitle mb-3">Manage Users for {company?.name}</h4>
				<p className="smText mb-4">You can invite new users to your company and manage existing users.</p>
				<div className="cardBox d-flex align-items-center justify-content-between mb-4">
					<div>
						<h6 className="smH6Text mb-1">Invite Users</h6>
						<p className="smText">Invite new users to create an OptiMiser account for your company at no additional cost.</p>
					</div>
					<InviteUser company={company} programId={programId} invitedUsersMutate={invitedUsersMutate} userPrograms={userPrograms} hasCompanyPermission={hasCompanyPermission}/>
				</div>

				<div className="mb-4">
					<div className="row">
						<div className="col-md-8">
							<h4 className="h4MdTitle mb-2">Active Users 
								<button className="btn btn-link p-0 ms-2  smLinkText" type="button" onClick={() => setShowRoleDescription(true)}>
								View Roles Description
							</button></h4>
							<p className="smText mb-4">To change a user’s role, select Modify. "Suspend" pauses a user's role in your Company. Suspended users can be reactivated at any time.</p>
						</div>
						
							<div className="col-md-4">
								<select class="form-select tblSelectInput" aria-label="Default select example" onChange={(e) => setSelectedProgram(e.target.value)}>
									<option value={""}>All Programs</option>
									{company?.programs
										?.filter((program) =>{
											if(program.name === COMPANY_STANDARD_PROGRAM){
												return false;
											}
											if(hasCompanyPermission){
												return true;
											}
											return userPrograms.some((userProgram) => userProgram.id === program.id);
										})
										?.map((item) => (
											<option value={item.id} selected={selectedProgram == item.id ? true : false}>
												{item.name}
											</option>
										))}
								</select>
							</div>
						
					</div>
					<div className="tableScrollAreaUser userListViewTable">
						<table className="tableArea">
							<thead>
								<tr>
									<th className="tblTh">
										<p className="tblText">Name</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Company Role</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Email</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Programs</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Action</p>
									</th>
								</tr>
							</thead>
							<div className="tableSpace"></div>
							<tbody>
								{users
									?.filter((user) => user.company_status === 1)
									?.map((user, index) => (
										<tr key={"active" + index}>
											<td className="tblTd">
												<div className="tblFlexBar">
													{user?.profile_image ? (
														<img src={user?.profile_image} className="tblUserIcon" alt="user profile" />
													) : (
														<span className="tblUserIcon">{getInitialsFromName(user?.first_name + " " + user.last_name)}</span>
													)}

													<p className="tblText">{`${user?.first_name} ${user.last_name ?? ""}`}</p>
												</div>
											</td>
											<td className="tblTd">
												<p className="tblText">{user?.role?.name ?? "None"}</p>
											</td>
											<td className="tblTd">
												<p className="tblText">{user?.email}</p>
											</td>
											<td className="tblTd">
												{user?.role?.name === COMPANY_MANAGER ? (
													<p className="tblText">All Programs</p>
												) : user?.programs?.length > 0 ? (
													<button className="tblText border-0 bg-transparent text-primary" onClick={() => setSelectedUser({ user: user, action: "programs" })}>
														View Programs
													</button>
												) : (
													<p className="tblText">None</p>
												)}
											</td>
											<td className="tblTd">
												<div className="tblLinkBar">
													{authUser?.id !== user?.id && (
														<>
															<Link className="linkText linkClr2" onClick={() => setSelectedUser({ user: user, action: "suspend" })}>
																Suspend
															</Link>
															<Link className="linkText linkClr2" onClick={() => setSelectedUser({ user: user, action: "modify" })}>
																Modify
															</Link>
														</>
													)}
												</div>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>

				<div className="mb-4">
					<h4 className="h4MdTitle mb-2">Suspended Users</h4>
					<p className="smText mb-4">Reactivate a user at any time.</p>
					<div className="tableScrollAreaUser userListViewTable">
						<table className="tableArea">
							<thead>
								<tr>
									<th className="tblTh">
										<p className="tblText">Name</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Company Role</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Email</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Programs</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Action</p>
									</th>
								</tr>
							</thead>
							<div className="tableSpace"></div>
							<tbody>
								{users
									?.filter((user) => user.company_status === 0)
									.map((user, index) => (
										<tr key={"inactive" + index}>
											<td className="tblTd">
												<div className="tblFlexBar">
													{user?.profile_image ? (
														<img src={user?.profile_image} className="tblUserIcon" alt="user profile" />
													) : (
														<span className="tblUserIcon">{getInitialsFromName(user?.first_name + " " + user.last_name)}</span>
													)}
													<p className="tblText">{`${user?.first_name} ${user.last_name ?? ""}`}</p>
												</div>
											</td>
											<td className="tblTd">
												<p className="tblText">{user?.role?.name ?? "None"}</p>
											</td>
											<td className="tblTd">
												<p className="tblText">{user?.email}</p>
											</td>
											<td className="tblTd">
												{user?.role?.name === COMPANY_MANAGER ? (
													<p className="tblText">All Programs</p>
												) : user?.programs?.length > 0 ? (
													<button className="tblText border-0 bg-transparent text-primary" onClick={() => setSelectedUser({ user: user, action: "programs" })}>
														View Programs
													</button>
												) : (
													<p className="tblText">None</p>
												)}
											</td>
											<td className="tblTd">
												<div className="tblLinkBar">
													<Link className="linkText " onClick={() => setSelectedUser({ user: user, action: "unsuspend" })}>
														Unsuspend
													</Link>
												</div>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</div>
				</div>
				<div className="mb-4">
					<h4 className="h4MdTitle mb-2">Invited Users</h4>
					<p className="smText mb-4">
						Invite new users to create an OptiMiser account so they can help manage audits, perform audits, or view audits for your company. Projects started by users you invite will be
						billed to your Plan.
					</p>
					<div className="tableScrollAreaUser userListViewTable">
						<table className="tableArea">
							<thead>
								<tr>
									<th className="tblTh">
										<p className="tblText">Name</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Company Role</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Email</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Programs</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Action</p>
									</th>
								</tr>
							</thead>
							<div className="tableSpace"></div>
							<tbody>
								{invitedUsers?.map((invitedUser, index) => (
									<tr key={"invited" + index}>
										<td className="tblTd">
											<p className="tblText">{invitedUser?.user !== null ? invitedUser.user.first_name + " " + invitedUser.user.last_name : "None"}</p>
										</td>
										<td className="tblTd">
											<p className="tblText">{invitedUser?.role?.name ?? "None"}</p>
										</td>
										<td className="tblTd">
											<p className="tblText">{invitedUser?.email}</p>
										</td>
										<td className="tblTd">
											{invitedUser?.programs?.map((program) => (
												<p className="tblText">{`${program.program_name} - (${program.role_name})`}</p>
											))}
											{invitedUser?.programs?.length == 0 && <p className="tblText"> None </p>}
										</td>
										<td className="tblTd">
											<div className="tblLinkBar">
												<Link className="linkText linkClr2" onClick={() => setSelectedUser({ user: invitedUser, action: "revoke-invite" })}>
													Revoke Invite
												</Link>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			{selectedUser.action === "modify" && (
				<EditCompanyUser
					show={selectedUser.action}
					user={selectedUser.user}
					handleClose={() => setSelectedUser({ user: null, action: "" })}
					usersMutate={usersMutate}
					company={company}
					programId={programId}
				/>
			)}

			{["revoke-invite", "suspend", "unsuspend"].includes(selectedUser.action) && (
				<ConfirmationAlert
					title={"Are you sure?"}
					description={
						selectedUser.action === "revoke-invite"
							? "Are you sure you want to Revoke the invite? This action cannot be undone."
							: selectedUser.action === "suspend"
							? "Are you sure you want to Suspend the user?"
							: "Are you sure you want to reactivate the user?"
					}
					show={["revoke-invite", "suspend", "unsuspend"].includes(selectedUser.action)}
					handleClose={() => setSelectedUser({ user: null, action: "" })}
					handleConfirm={handleUserStatusChange}
					confirmBtnText={"Yes"}
					cancelBtnText={"Cancel"}
				/>
			)}
			{selectedUser.action === "programs" && (
				<ProgramDetails
					show={selectedUser.action}
					title={selectedUser?.user?.first_name + " " + selectedUser?.user?.last_name}
					handleClose={() => setSelectedUser({ user: null, action: "" })}
					companyPrograms={company?.programs?.filter((program) => program.name !== COMPANY_STANDARD_PROGRAM)}
					programs={selectedUser?.user?.programs}
					userRole={selectedUser?.user?.role?.name ?? "None"}
				/>
			)}
			{showRoleDescription && (
				<LegalDocumentModal show={showRoleDescription} handleClose={() => setShowRoleDescription(false)} documentTitle={"Role Description"} documentLink={ROLE_DESCRIPTION_LINK} />
			)}
		</>
	);
};

export default CompanyUserListView;
