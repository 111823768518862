import React, { useState, useEffect } from "react";
import "./CostingStyle.css";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { filterCompanyPrograms, useCheckCompanyPermission, useGetProgramsWhereUserHasPermission } from "utils/helpers/Common";
import { EDIT_COMPANY_MEASURES_STAGES } from "utils/constants/UserPermissions";

const CompanyProgram = ({ company }) => {
	const [hideDeprecated, setHideDeprecated] = useState(false);

	const [companyPrograms, setCompanyPrograms] = useState([]);
	const hasCompanyPermission = useCheckCompanyPermission([EDIT_COMPANY_MEASURES_STAGES]);
	const userPrograms = useGetProgramsWhereUserHasPermission([EDIT_COMPANY_MEASURES_STAGES]);

	useEffect(() => {
		company && setCompanyPrograms(filterCompanyPrograms({ company: company, hideDeprecated: hideDeprecated }));
	}, [company, hideDeprecated]);

	return (
		<div className="pageCard">
			<div className="TitleBox mb-4">
				<h4>Measures</h4>
				<p>View company and program measures</p>
			</div>
			<div className="costingBoxArea">
				<p>Company</p>
				<div className="btnsGirdList">
					<Link to={`/setting/company/${company?.id}/measures/company`} className="cmnyBtn">
						{company?.name}
					</Link>
				</div>
			</div>
			<div className="costingBoxArea">
				<p>Programs</p>
				<Form.Check label="Hide Deprecated" checked={hideDeprecated} onChange={() => setHideDeprecated(!hideDeprecated)} className="mb-2" />
				<div className="btnsGirdList">
					{companyPrograms
						?.filter((program) => (hasCompanyPermission ? true : userPrograms?.some((userProgram) => userProgram.id === program.id)))
						.map((program, pIndex) => (
							<Link
								className={`multiSelectItem color2 ${program.is_trashed ? "trashedItem" : ""}`}
								key={"program" + pIndex}
								to={`/setting/company/${company?.id}/measures/program/${program?.pivot_id}`}
							>
								{program.name}
							</Link>
						))}
				</div>
			</div>
		</div>
	);
};

export default CompanyProgram;
