import React, { useState } from "react";
import AddProgramView from "./AddProgramView";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../../../redux/reducers/LoaderSlice";
import { toast } from "react-toastify";
import { createCompanyProgramApi } from "services/ProgramService";
import useFileUpload from "hooks/useFileUpload";

const AddProgram = ({ companyName, companyId, programsMutate, companyMutate }) => {
	const [programName, setProgramName] = useState("");
	const [show, setShow] = useState(false);
	const dispatch = useDispatch();
	const { file, onFileChange, deleteFile, setFile } = useFileUpload();
	const handleShow = () => setShow(true);
	const handleClose = () => {
		setProgramName("");
		setFile(null);
		setShow(false);
	};

	const handleSubmit = () => {
		if (!programName) {
			toast.error("Please enter program name");
			return;
		}

		const formData = new FormData();
		formData.append("name", programName);
		formData.append("company_id", companyId);
		if (file) {
			formData.append("logo", file);
		}

		dispatch(showLoader());
		createCompanyProgramApi(formData)
			.then((res) => {
				handleClose();
				programsMutate();
				companyMutate();
				toast.success("Program added successfully");
				setProgramName("");
			})
			.catch((err) => {
				toast.error(err.response.data.message);
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};

	return (
		<AddProgramView
			companyName={companyName}
			programName={programName}
			setProgramName={setProgramName}
			show={show}
			handleShow={handleShow}
			handleClose={handleClose}
			handleSubmit={handleSubmit}
			file={file}
			onFileChange={onFileChange}
			deleteFile={deleteFile}
		/>
	);
};

export default AddProgram;
