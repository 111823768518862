import React from "react";
import WizardLogo from "assets/images/wizard-logo.png";
import Video from "assets/images/our-services.mp4";
import ThumbnailImage from "assets/images/thumbnail-img.png";
import "./CompanyOnboardingStyle.css";
import EditCompanyProfile from "../setting/company/detail/edit/EditCompanyProfile";
import PricingPlan from "../setting/company/pricingPlan/PricingPlan";
import InviteUser from "../setting/company/user/invite/InviteUser";
import { ONBOARDING_STEPS } from "utils/constants/Constants";
import checkIcon from "assets/images/icons/check-md-icon.svg";
import circleCrossIcon from "assets/images/icons/circle-cross-icon.svg";
import BackArrow from "assets/images/icons/back-arrow-icon.svg";
const CompanyOnboardingView = ({
	company,
	handleContinue,
	step,
	companyMutate,
	handleStepBack,
	searchParams,
	setSearchParams,
	setSuccessAlert,
	onboarding,
	handleCompleteProfile,
	handleSkipOnboarding,
	handleStart,
	handleBack,
}) => {
	return (
		<section className="wizardSection">
			<img src={WizardLogo} className="wizardLogo" alt="" />
			{!onboarding && step === 0 ? (
				<div className="wizardAreaBox">
					<button className="circleCrossBtn" onClick={handleSkipOnboarding}>
						<img src={circleCrossIcon} className="circleCrossIcon" alt="" />
					</button>
					<h3 className="mdTitle">Complete Your Profile</h3>
					<p className="pText text-center">Complete your profile or Skip for now?</p>
					<div className="d-flex align-items-center gap-3 btnCardBox">
						<button className="btn mdBtn primaryBtn" onClick={handleCompleteProfile}>
							Complete Profile
						</button>
						<span className="orText">or</span>
						<button className="btn mdBtn primaryBtn skipBtn" onClick={handleSkipOnboarding}>
							Skip
						</button>
					</div>
				</div>
			) : (
				<div className="wizardArea">
					<img src={BackArrow} className="backArrow" alt="" onClick={handleBack} />
					<h3 className="mdTitle">Complete Your Profile</h3>
					<div className="timeLineArea">
						<div className="timeLineContainer ">
							<div className={`timeLineItem ${step === ONBOARDING_STEPS.COMPANY_PROFILE ? "itemFocus" : step > ONBOARDING_STEPS.COMPANY_PROFILE ? "itemCompleted" : ""}`}>
								<div className="circleBx">
									<div className="circleDot"></div>
									<img src={checkIcon} className="checkSvg" alt="" />
								</div>
								<p className="timeLineText">Company Information</p>
							</div>
							<div className={`timeLineItem ${step === ONBOARDING_STEPS.SELECT_PLAN ? "itemFocus" : step > ONBOARDING_STEPS.SELECT_PLAN ? "itemCompleted" : ""}`}>
								<div className="circleBx">
									<div className="circleDot"></div>
									<img src={checkIcon} className="checkSvg" alt="" />
								</div>
								<p className="timeLineText">Select Plan</p>
							</div>
							<div className={`timeLineItem ${step === ONBOARDING_STEPS.INVITE_TEAM ? "itemFocus" : step > ONBOARDING_STEPS.INVITE_TEAM ? "itemCompleted" : ""}`}>
								<div className="circleBx">
									<div className="circleDot"></div>
									<img src={checkIcon} className="checkSvg" alt="" />
								</div>
								<p className="timeLineText">Invite Team</p>
							</div>
							<div className={`timeLineItem ${step === ONBOARDING_STEPS.TEST_DRIVE ? "itemFocus" : step > ONBOARDING_STEPS.TEST_DRIVE ? "itemCompleted" : ""}`}>
								<div className="circleBx">
									<div className="circleDot"></div>
									<img src={checkIcon} className="checkSvg" alt="" />
								</div>
								<p className="timeLineText">Test Drive OptiMiser</p>
							</div>
						</div>
					</div>
					<div className="wizardContentWrapper">
						<div className={`wizardStepContent`}>
							{step === ONBOARDING_STEPS.COMPANY_PROFILE && (
								<EditCompanyProfile
									company={company}
									companyMutate={companyMutate}
									isEditAble={true}
									saveBtnText="Continue"
									cancelBtnText="Back"
									onCancelCallback={handleStepBack}
									onSaveCallback={handleContinue}
									showCancelBtn={false}
								/>
							)}
							{step === ONBOARDING_STEPS.SELECT_PLAN && (
								<PricingPlan
									company={company}
									showManageCard={false}
									showFooterText={false}
									handleBack={handleStepBack}
									handleContinue={handleContinue}
									isOnboarding={true}
									searchParams={searchParams}
									setSearchParams={setSearchParams}
									setSuccessAlert={setSuccessAlert}
								/>
							)}
							{step === ONBOARDING_STEPS.INVITE_TEAM && (
								<div className="pageCard">
									<div className="mb-4">
										<div className="row">
											<div className="col-md-8">
												<h4 className="mb-2 h4MdText ">Invite a user to {company?.name}</h4>
												<p className="smText mb-4 wdMax80Pr">
													Invited users will receive an email with a link to join {company?.name}. Please select the appropriate permission type for this user, however their
													permission role this can be changed at any time in the Users tab.
												</p>
											</div>
										</div>
										<InviteUser
											company={company}
											useModal={false}
											saveBtnText="Continue"
											cancelBtnText="Back"
											callback={handleContinue}
											onSkip={handleContinue}
											onCancel={handleStepBack}
										/>
									</div>
								</div>
							)}
							{step === ONBOARDING_STEPS.TEST_DRIVE && (
								<div className="pageCard">
									<div className="mb-4">
										<div className="row">
											<div className="col-md-6">
												<h4 className="mb-2 h4MdText ">Start with OptiMiser</h4>
												<p className="smText mb-4 ">
													Click the Start button below to begin using OptiMiser. You will be guided through an in-app tutorial.
												</p>
											</div>
										</div>
										{/* <div className="row mt-4">
											<div className="col-md-4 videoFrameCol">
												<video autoPlay={false} controls playsInline={false} muted loop={false} className="videoFrame" poster={ThumbnailImage}>
													<source src={Video} type="video/mp4" />
												</video>
											</div>
											<div className="col-md-4 videoFrameCol">
												<video autoPlay={false} controls playsInline={false} muted loop={false} className="videoFrame" poster={ThumbnailImage}>
													<source src={Video} type="video/mp4" />
												</video>
											</div>
											<div className="col-md-4 videoFrameCol">
												<video autoPlay={false} controls playsInline={false} muted loop={false} className="videoFrame" poster={ThumbnailImage}>
													<source src={Video} type="video/mp4" />
												</video>
											</div>
										</div> */}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="wizardBtnGrp">
						{step === ONBOARDING_STEPS.TEST_DRIVE && (
							<>
								<div>
									<button className="btn  primaryLightBtn">Introduction to OptiMiser</button>
								</div>
								<div className="d-flex align-items-center gap-3">
									<button className="btn mdBtn primaryBtn" onClick={handleStart}>
										Start
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			)}
		</section>
	);
};

export default CompanyOnboardingView;
