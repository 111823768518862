import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./AddCardStyle.css";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
const AddCard = ({ show, handleClose, handleAddCard, firstCard }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { loading } = useSelector(state => state.loader)
  const [error, setError] = useState(null);
  const [isDefault, setIsDefault] = useState(false);
  const [name, setName] = useState("");
  const [tokenId, setTokenId] = useState(null);

  const handleSave = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const { error: stripeError, token } = await stripe.createToken(cardElement);
    if (stripeError) {
      setError(stripeError.message);
    } else if (!name) {
      setError("Name is required");
    } else {
      setTokenId(token.id);
      handleAddCard({
        token: token.id,
        isDefault: firstCard ? true : isDefault,
        name: name,
      });
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="frmModalArea smModal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <h5 className="mdlTitle">Add Card</h5>
            <p className="mdlPtext">Add your payment card hassle-free.</p>
            <form className="mdlFormBox">
              <div className="inputFieldBar mb-3">
                <label>Card Number</label>
                <CardNumberElement
                  options={{
                    showIcon: true,
                    placeholder: "Card Number",
                  }}
                />
              </div>

              <div className="inputFieldBar mb-3">
                <label className="labeltextSc">Name on Card</label>
                <input
                  type="text"
                  className="inputField"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="inputFieldBar mb-3">
                    <label>CVC</label>
                    <CardCvcElement />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="inputFieldBar mb-3">
                    <label>Expiry Date</label>
                    <CardExpiryElement />
                  </div>
                </div>
              </div>
              {error && !tokenId && (
                <div role="alert" className="mdlPtext text-danger">
                  {error}
                </div>
              )}
            </form>
            {!firstCard && (
              <div class="form-check mb-5 mt-2">
                <input
                  class="form-check-input"
                  type="checkbox"
                  checked={isDefault}
                  onChange={() => setIsDefault(!isDefault)}
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  <h5 className="checkTitle"> Set as default</h5>
                </label>
                <p className="mdlPtext">
                  Scheduled payment will be automatically deducted from this
                  card.
                </p>
              </div>
            )}

            <div className="d-flex align-items-center justify-content-end gap-4 mdlFtbtns">
              <Button
                className="bdrBtn mrLauto cancelBtn saveBtn"
                onClick={handleSave}
                disabled={loading}
              >
                Save
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddCard;
