import * as yup from "yup";
import { UPPERCASE_REGEX, LOWERCASE_REGEX, NUMBER_REGEX, SPECIAL_CHAR_REGEX, PHONE_REGEX, EMAIL_REGEX } from "../constants/Constants";
export const SignUpSchema = yup.object().shape({
	company_name: yup.string().required("Company Name is required"),
	first_name: yup.string().required("First Name is required"),
	last_name: yup.string().required("Last Name is required"),
	email: yup.string().matches(EMAIL_REGEX, "Email must be a valid email").required("Email is required"),
	programs: yup.array(),
	phone_number: yup.string().matches(PHONE_REGEX, "Phone Number is invalid"),
	password: yup
		.string()
		.required("Password is required")
		.test("password-complexity", (value, { createError, path }) => {
			const uppercaseValid = UPPERCASE_REGEX.test(value);
			const lowercaseValid = LOWERCASE_REGEX.test(value);
			const numberValid = NUMBER_REGEX.test(value);
			const specialCharValid = SPECIAL_CHAR_REGEX.test(value);

			if (!uppercaseValid || !lowercaseValid || !numberValid || !specialCharValid) {
				return createError({
					message: "Password is invalid",
					path: "password",
				});
			}

			return true;
		})
		.min(8, "Password must be at least 8 characters"),
	password_confirmation: yup
		.string()
		.oneOf([yup.ref("password"), null], "Password must match")
		.required("Confirm Password is required"),
	legal_agreement: yup.boolean().oneOf([true], "Please accept the Eula, Privacy Policy and Terms of Service"),
});

export const JoinCompanySchema = yup.object().shape({
	company_id: yup.string().required("Company Id is required"),
	first_name: yup.string().required("Name is required"),
	email: yup.string().matches(EMAIL_REGEX, "Email must be a valid email").required("Email is required"),
	phone_number: yup.string().matches(PHONE_REGEX, "Phone Number is invalid"),
	password: yup
		.string()
		.required("Password is required")
		.test("password-complexity", (value, { createError, path }) => {
			const uppercaseValid = UPPERCASE_REGEX.test(value);
			const lowercaseValid = LOWERCASE_REGEX.test(value);
			const numberValid = NUMBER_REGEX.test(value);
			const specialCharValid = SPECIAL_CHAR_REGEX.test(value);

			if (!uppercaseValid || !lowercaseValid || !numberValid || !specialCharValid) {
				return createError({
					message: "Password is invalid",
					path: "password",
				});
			}

			return true;
		})
		.min(8, "Password must be at least 8 characters"),
	password_confirmation: yup
		.string()
		.oneOf([yup.ref("password"), null], "Password must match")
		.required("Confirm Password is required"),
});

export const SignInSchema = yup.object().shape({
	email: yup.string().matches(EMAIL_REGEX, "Email must be a valid email").required("Email is required"),
	password: yup.string().required("Password is required"),
});

export const ForgotPasswordSchema = yup.object().shape({
	email: yup.string().matches(EMAIL_REGEX, "Email must be a valid email").required("Email is required"),
});

export const ResetPasswordSchema = yup.object().shape({
	password: yup
		.string()
		.required("New Password is required")
		.test("password-complexity", (value, { createError, path }) => {
			const uppercaseValid = UPPERCASE_REGEX.test(value);
			const lowercaseValid = LOWERCASE_REGEX.test(value);
			const numberValid = NUMBER_REGEX.test(value);
			const specialCharValid = SPECIAL_CHAR_REGEX.test(value);

			if (!uppercaseValid || !lowercaseValid || !numberValid || !specialCharValid) {
				return createError({
					message: "Password is invalid",
					path: "password",
				});
			}

			return true;
		})
		.min(8, "Password must be at least 8 characters"),
	password_confirmation: yup
		.string()
		.oneOf([yup.ref("password"), null], "New Password must match")
		.required("Confirm Password is required"),
});
