import React, { useEffect, useState } from "react";
import EditCompanyProfileView from "./EditCompanyProfileView";
import { useEditCompanyProfileForm } from "services/CompanyService";
import { useGetPrograms, useGetCompanyCustomPrograms } from "services/ProgramService";
import { useCheckPermissions } from "utils/helpers/Common";
import { EDIT_COMPANIES } from "utils/constants/UserPermissions";
import { COMPANY_STANDARD_PROGRAM } from "utils/constants/Constants";

const EditCompanyProfile = ({ company, companyMutate, isEditAble, saveBtnText = "Save", cancelBtnText = "Cancel", showCancelBtn = true, onCancelCallback, onSaveCallback }) => {
	const [editable, setEditable] = useState(false);
	const [allPrograms, setAllPrograms] = useState([]);
	const [selectedPrograms, setSelectedPrograms] = useState([]);
	const [hideDeprecated, setHideDeprecated] = useState(false);
	const { register, handleFileUpload, handleSubmit, errors, Controller, control, reset, setError, isDirty, append, remove } = useEditCompanyProfileForm(company, companyMutate);
	const { programs } = useGetPrograms();
	const { customPrograms } = useGetCompanyCustomPrograms(company?.id);
	const canEdit = useCheckPermissions([EDIT_COMPANIES]);

	useEffect(() => {
		setEditable(isEditAble);
	}, [isEditAble]);
	useEffect(() => {
		if (company?.programs?.length > 0) {
			setSelectedPrograms(
				company.programs.map((program) => ({
					label: program.name,
					value: program.id,
					is_trashed: program.is_trashed,
					company_id: program.company_id,
				}))
			);
		}
	}, [company]);

	useEffect(() => {
		if (programs && customPrograms) {
			setAllPrograms([...programs, ...customPrograms]);
		}
	}, [programs, customPrograms]);

	const _setEditable = () => {
		if (isEditAble) {
			setEditable(true);
		} else {
			setEditable(!editable);
		}
	};
	const handleProgramSelect = (e) => {
		const clonedSelectedPrograms = [...selectedPrograms];
		const program = clonedSelectedPrograms.find((program) => program.value === e.value);
		if (!program) {
			clonedSelectedPrograms.push(e);
			append(e.value);
			setError("programs", "");
			setSelectedPrograms(clonedSelectedPrograms);
		}
	};

	const handleProgramRemove = (pIndex) => {
		const clonedSelectedPrograms = [...selectedPrograms];
		const filteredPrograms = clonedSelectedPrograms.filter((program) => program.label !== COMPANY_STANDARD_PROGRAM && (!hideDeprecated || !program.is_trashed));
		filteredPrograms.splice(pIndex, 1);
		remove(pIndex);
		setSelectedPrograms(filteredPrograms);
	};
	return (
		<EditCompanyProfileView
			register={register}
			handleFileUpload={handleFileUpload}
			handleSubmit={handleSubmit}
			errors={errors}
			company={company}
			editable={editable}
			setEditable={_setEditable}
			Controller={Controller}
			control={control}
			reset={reset}
			handleProgramSelect={handleProgramSelect}
			handleProgramRemove={handleProgramRemove}
			programs={allPrograms}
			selectedPrograms={selectedPrograms?.filter((program) => program.label !== COMPANY_STANDARD_PROGRAM && (!hideDeprecated || !program.is_trashed))}
			canEdit={canEdit}
			saveBtnText={saveBtnText}
			cancelBtnText={cancelBtnText}
			showCancelBtn={showCancelBtn}
			onSaveCallback={onSaveCallback}
			onCancelCallback={onCancelCallback}
			isDirty={isDirty}
			hideDeprecated={hideDeprecated}
			setHideDeprecated={setHideDeprecated}
		/>
	);
};

export default EditCompanyProfile;
