import React from "react";
import Modal from "react-bootstrap/Modal";
import "./ProgramDetailsStyle.css";
import { Link } from "react-router-dom";
import { PROGRAM_ADMIN } from "utils/constants/UserRoles";

const ProgramDetails = ({ show, handleClose, title, userRole, programs, companyPrograms, showViewDetails = false }) => {
	const canManage = showViewDetails && programs?.some(program => program?.role?.name === PROGRAM_ADMIN);

	return (
		<Modal show={show} onHide={handleClose} centered className="programsRoleModal">
			<Modal.Header closeButton>
				<h4 className="prgrmTitle">
					{`${title ?? ""}`}
					{userRole && <span> ({userRole})</span>}
				</h4>
			</Modal.Header>
			<Modal.Body className="modalBody">
				<div className="modalBoxArea">
					<div className="mdpPrgmList">
						<div className="mdlPrgmListItem">
							<h6 className="h6TextH wdLeftclm">Programs</h6>
							<h6 className="h6TextH wdRightclm">Roles</h6>
							{canManage && <h6 className="h6TextH wdRightclm">Manage</h6>}
						</div>
						{userRole && userRole !== "None" && (
							<>
								{companyPrograms
									?.filter((program) => !programs?.some((p) => p.id === program.id))
									?.map((program, index) => (
										<div className="mdlPrgmListItem" key={"company-program" + index}>
											<p className="pTextP wdLeftclm">{program?.name}</p>
											<p className="pTextP wdRightclm">{userRole}</p>
										</div>
									))}
							</>
						)}

						{programs?.map((program, index) => (
							<div className="mdlPrgmListItem" key={"user-program" + index}>
								<p className="pTextP wdLeftclm">{program?.name}</p>
								<p className="pTextP wdRightclm">{program?.role?.name}</p>
								{(showViewDetails && program?.role?.name === "Program Admin") && 
									<p className="pTextP wdRightclm">
										<Link to={`/setting/company/${show}/program/${program.id}`} >Manage</Link>
									</p>
								}
							</div>
						))}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ProgramDetails;
