import React, { useEffect, useState } from "react";

const TableCheckbox = ({
    stage,
    fieldId,
    isReadOnly,
    handleChange,
    stageIndex,
    isCustomized,
    isInherited,
    isStockValue
}) => {
    const [value, setValue] = useState(false);

    useEffect(() => {
        setValue(stage ?? "");
    }, [stage, isStockValue]);

    const backgroundColor =
    isCustomized && !isInherited ? "#ffd28073" : isInherited ? "#FFFFE0" : "";

    return (
        <div className={`d-flex justify-content-center align-items-center tblText  inputTblText ${isReadOnly ? "bgGray" : ""}`} style={{ backgroundColor: backgroundColor }}>
            <input
                type="checkbox"
                checked={value}
                disabled={isReadOnly}
                onChange={(e) => handleChange(e.target.checked, stageIndex)}
                className="stage-checkbox"
            />
        </div>
    );
};

export default TableCheckbox; 