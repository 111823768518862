import React, { useEffect, useState } from "react";
import InvoiceDetailView from "./InvoiceDetailView";
import { useGetCompanyInvoiceDetail } from "services/CompanyService";
import { formatDateToCustomString } from "utils/helpers/Common";

const InvoiceDetail = ({ show, invoice, handleClose, company }) => {
	const [search, setSearch] = useState(null);
	const [items, setItems] = useState([]);
	const { details } = useGetCompanyInvoiceDetail(company, invoice);

	useEffect(() => {
		if (details?.items?.length > 0) {
			if (search) {
				const filteredItems = details?.items.filter((item) => {
					const id = item.id;
					const CreatedAt = formatDateToCustomString(item.CreatedAt);
					const Program = item.program?.name ?? "";
					const Stage = item.stage?.name ?? "";
					const Name = (item.creator?.first_name ?? "") + " " + (item.creator?.last_name ?? "");
					const ProjectName = item.ProjectName;
					const ProjectAddress = item.ProjectAddress;
					const nameFilter = search
										.toLowerCase()
										.split(/\s+/)
										.every(word => Name.toLowerCase().includes(word));
					return (
						id?.toString().includes(search.toLowerCase()) ||
						CreatedAt?.toLowerCase().includes(search.toLowerCase()) ||
						Program.toLowerCase().includes(search.toLowerCase()) ||
						Stage.toLowerCase().includes(search.toLowerCase()) ||
						nameFilter ||
						ProjectName?.toLowerCase().includes(search.toLowerCase()) ||
						ProjectAddress?.toLowerCase().includes(search.toLowerCase())
					);
				});
				setItems(filteredItems);
			} else {
				setItems(details?.items);
			}
		}
	}, [details, search]);

	const handleDateSort = (order) => {
		const clonedItem = [...items];
		const sortedItems = clonedItem.sort((a, b) => {
			let dateA = new Date(a.CreatedAt);
			let dateB = new Date(b.CreatedAt);
			if (order === "asc") {
				return dateA - dateB;
			} else {
				return dateB - dateA;
			}
		});

		setItems(sortedItems);
	};

	return (
		<InvoiceDetailView
			show={show}
			handleClose={handleClose}
			company={company}
			invoice={invoice}
			details={details}
			search={search}
			setSearch={setSearch}
			items={items}
			handleDateSort={handleDateSort}
		/>
	);
};

export default InvoiceDetail;
