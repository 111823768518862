import React from "react";
import { Routes, Route } from "react-router-dom";
import SignUp from "../pages/auth/signup/SignUp";

const AuthNavigator = () => {
  return (
    <Routes>
      <Route exact path="/" element={<SignUp />} />
      <Route exact path="/reset-password" element={<SignUp />} />
      <Route exact path="/join-company" element={<SignUp />} />
    </Routes>
  );
};

export default AuthNavigator;
