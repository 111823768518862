import { React, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ImgEditIcon from "assets/images/icons/imgEditIcon.svg";
import UploadIcon from "assets/images/icons/upload-icon.svg";
import "./UploadProfileImageStyle.css";
import UploadImageArea from "./UploadImageArea";
import useFileUpload from "hooks/useFileUpload";

const UploadProfileImage = ({ onSave, callback = () => { }, title }) => {
  const [show, setShow] = useState(false);
  const { file, onFileChange, deleteFile, setFile } = useFileUpload();

  const handleClose = () => {
    setShow(false);
    setFile(null);
    callback();
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Button onClick={handleShow} className="upLoadBtn">
        <img src={ImgEditIcon} className="ImgEditIcon" />
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        className="frmModalArea"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h5 className="h5Title mb-2">{`${title ? title : "Picture Upload"}`}</h5>
          <p className="smText mdlTextMaxWd mb-4">
            Personalize your OptiMiser profile by uploading a photo!
          </p>
          <UploadImageArea
            file={file}
            onFileChange={onFileChange}
            deleteFile={deleteFile}
          />
          {file && (
            <button
                className="fillPrimarBtn mrLauto mt-4"
                type="button"
                onClick={() => onSave(file, handleClose)}
            >
                Save
            </button>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadProfileImage;
