import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import "./InvoiceListStyle.css";
import InvoiceDetail from "../detail/InvoiceDetail";
import NoRecordInvoice from "assets/images/icons/no-record-icon.svg";

const InvoiceListView = ({ data, handleDownload, itemsPerPage, selectedInvoice, setSelectedInvoice, company }) => {
	const [itemOffset, setItemOffset] = useState(0);
	const pageCount = Math.ceil(data?.invoices?.length / itemsPerPage);
	const endOffset = itemOffset + itemsPerPage;
	const currentItems = data?.invoices?.slice(itemOffset, endOffset);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % data?.invoices?.length;
		setItemOffset(newOffset);
	};
	return (
		<>
			<div className="pageCard vtHeu">
				<h4 className="h4MdTitle mb-3">Invoices & Receipts</h4>
				<p className="smText mb-4">Review current and past invoices.</p>

				<div className="mb-4">
					<div className="tableScrollArea">
						{data?.invoices?.length > 0 ? (
							<table className="tableArea pricingTable mb-3">
								<thead>
									<tr>
										<th className="tblTh">
											<p className="tblText">Invoice #</p>
										</th>
										<th className="tblTh">
											<p className="tblText">Billing Type</p>
										</th>
										<th className="tblTh">
											<p className="tblText">Billing Cycle</p>
										</th>
										<th className="tblTh">
											<p className="tblText">Projects</p>
										</th>
										<th className="tblTh">
											<p className="tblText">Total Amount</p>
										</th>
										<th className="tblTh">
											<p className="tblText">Action</p>
										</th>
									</tr>
								</thead>
								<div className="tableSpace"></div>
								<tbody>
									{currentItems?.map((invoice, index) => (
										<tr className="" key={"inovice" + index}>
											<td className="tblTd">
												<div className="tblFlexBar">
													<p className="tblText linkText" onClick={() => setSelectedInvoice(invoice)}>
														{invoice.invoice_number}
													</p>
												</div>
											</td>
											<td className="tblTd">
												<div className="tblFlexBar">
													<p className="tblText">{invoice?.billing_type ?? "-"}</p>
												</div>
											</td>
											<td className="tblTd">
												<div className="tblFlexBar">
													<p className="tblText">{`${invoice.start_date} - ${invoice.end_date}`}</p>
												</div>
											</td>
											<td className="tblTd">
												<div className="tblFlexBar">
													<p className="tblText">{invoice.utilized_projects + invoice.additional_projects}</p>
												</div>
											</td>
											<td className="tblTd">
												<div className="tblFlexBar">
													<p className="tblText">${invoice.total_amount}</p>
												</div>
											</td>
											<td className="tblTd">
												{invoice.pdf_url && (
													<button className="chooseBtn" onClick={() => handleDownload(invoice)}>
														Download
													</button>
												)}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<div className="emptyBox">
								<img src={NoRecordInvoice} />
								<p>There are no invoices available.</p>
							</div>
						)}
					</div>
				</div>
				{data?.invoices?.length > 0 && (
					<div className="pagination">
						<ReactPaginate breakLabel="..." nextLabel="" onPageChange={handlePageClick} pageRangeDisplayed={5} pageCount={pageCount} previousLabel="" renderOnZeroPageCount={null} />
					</div>
				)}
			</div>
			<InvoiceDetail show={selectedInvoice} invoice={selectedInvoice} handleClose={() => setSelectedInvoice(null)} company={company} />
		</>
	);
};

export default InvoiceListView;
