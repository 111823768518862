import React from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import UploadImageArea from "components/upload/uploadProfileImage/UploadImageArea";

/**
 * Modal component for creating or editing a program. It contains a form with a name field and a logo field.
 * @param {string} companyName - Name of the company to add program to (optional)
 * @param {string} programName - Name of the program
 * @param {function} setProgramName - Function to update program name
 * @param {function} handleSubmit - Function called when form is submitted
 * @param {boolean} show - Controls visibility of modal
 * @param {function} handleClose - Function to close the modal
 * @param {File} file - Selected logo file
 * @param {function} onFileChange - Handler for logo file selection
 * @param {function} deleteFile - Function to remove selected logo file
 * @param {boolean} isEdit - Whether form is in edit mode
 * @param {React.ReactNode} triggerButton - Button that triggers the modal
 * @param {string} imageUrl - URL of existing program logo
 */
const ProgramFormModal = ({
    companyName = null,
    programName,
    setProgramName,
    handleSubmit,
    show,
    handleClose,
    file,
    onFileChange,
    deleteFile,
    isEdit = false,
    triggerButton,
    imageUrl
}) => {
    return (
        <>
            {triggerButton}
            <Modal show={show} onHide={handleClose} className="frmModalArea maxWd500" size="sm">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className="h5Title mb-3">
                        {isEdit ? `Edit Program` : companyName ? `Add Program to ${companyName}` : 'Create a New Program'}
                    </h5>
                    <Form className="formArea">
                        <div className="mobVtScroll">
                            <Row>
                                <Col md="12">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="labelText">Name</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Enter Name" 
                                            className="inputField" 
                                            value={programName} 
                                            onChange={(e) => setProgramName(e.target.value)} 
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md="12">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="labelText">Program Logo</Form.Label>
                                        <UploadImageArea
                                            imageUrl={imageUrl}
                                            file={file}
                                            onFileChange={onFileChange}
                                            deleteFile={deleteFile}
                                            onSave={handleSubmit}
                                            handleClose={handleClose}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <svg width="100%" height="2" viewBox="0 0 100% 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line x1="-3.05176e-05" y1="1" x2="100%" y2="1" stroke="#CFD4D9" strokeWidth="2" strokeDasharray="4 4" />
                                    </svg>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col md="12">
                                <button 
                                    className="fillPrimarBtn mrLauto mt-4 InviteBtn" 
                                    type="button" 
                                    onClick={() => handleSubmit(handleClose)}
                                >
                                    {isEdit ? 'Update' : 'Save'}
                                </button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProgramFormModal; 