import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Flagicon from "assets/images/icons/flag-icon.png";
import Form from "react-bootstrap/Form";
import PasswordRule from "components/passwordRule/PasswordRule";
const JoinCompanyView = ({
  register,
  errors,
  handleSubmit,
  password,
  setPassword,
  companyName
}) => {
  return (
    <>
      <h5 className="mdH5 mb-1">Join {companyName}</h5>
      <p className="smText mb-4">
        {companyName} has invited you to join their company! Create your OptiMiser account to begin. Already have an
        OptiMiser account? Sign in to your account and accept
        {` ${companyName}`}’s invite.
      </p>
      <Form className="formArea">
        <svg
          width="100%"
          height="2"
          viewBox="0 0 100% 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="dashedLine"
        >
          <line
            y1="1"
            x2="100%"
            y2="1"
            stroke="#CFD4D9"
            stroke-width="2"
            stroke-dasharray="4 4"
          />
        </svg>
        <Row>
          <Col md="6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="labelText">First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                className="inputField"
                {...register("first_name")}
              />
              {errors.first_name && (
                <p className="text-danger">{errors.first_name.message}</p>
              )}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="labelText">Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                className="inputField"
                {...register("last_name")}
              />
              {errors.last_name && (
                <p className="text-danger">{errors.last_name.message}</p>
              )}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="labelText">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                className="inputField"
                readOnly
                {...register("email")}
              />
              {errors.email && (
                <p className="text-danger">{errors.email.message}</p>
              )}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="labelText">
                <img src={Flagicon} className="Flagicon" />
                Phone
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone"
                className="inputField"
                {...register("phone_number")}
              />
              {errors.phone_number && (
                <p className="text-danger">{errors.phone_number.message}</p>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="labelText">Password</Form.Label>
              <Form.Control
                type="Password"
                placeholder="Password"
                className="inputField"
                {...register("password", {
                  onChange: (e) => {
                    setPassword(e.target.value);
                  },
                })}
              />
              {errors.password && (
                <p className="text-danger">{errors.password.message}</p>
              )}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="labelText">Confirm Password</Form.Label>
              <Form.Control
                type="Password"
                placeholder="Confirm Password"
                className="inputField"
                {...register("password_confirmation")}
              />
              {errors.password_confirmation && (
                <p className="text-danger">
                  {errors.password_confirmation.message}
                </p>
              )}
            </Form.Group>
          </Col>
          <PasswordRule password={password} color={"green"} />
          <Col md="12">
            <button
              type="button"
              className="submitBtn mrLauto mt-3"
              onClick={handleSubmit}
            >
              Create Account
            </button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default JoinCompanyView;
