import { PHONE_REGEX, ZIP_REGEX } from "utils/constants/Constants";
import * as yup from "yup";

export const UpdateCompanyProfileSchema = yup.object().shape({
	name: yup.string().required("Company Name is required"),
	state: yup.object().shape({
		value: yup.string().required("State is required"),
		label: yup.string().required("State is required"),
	}),
	city: yup.string(),
	office_phone: yup.string().matches(PHONE_REGEX, "Phone Number is invalid"),
	address: yup.string(),
	programs: yup.array(),
	zip_code: yup.string().matches(ZIP_REGEX, "Zip Code is invalid"),
});

export const EditCompanyUserSchema = yup.object().shape({
	role_id: yup.string().required("Permission is required"),
});

export const InviteUserSchema = yup.object().shape({
	email: yup.string().email("Email must be a valid email").required("Email is required"),
	first_name: yup.string().required("First Name is required"),
	last_name: yup.string().required("Last Name is required"),
	role: yup.string().required("Permission is required"),
	programs: yup.array().min(1, "Program is required"),
});
