import React from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import DeleteIcon from "assets/images/icons/delete-icon.svg";
import plusIcon from "assets/images/icons/add-plus-icon.svg";
const EditCompanyUserView = ({
	show,
	handleClose,
	handleSubmit,
	roles,
	errors,
	formValues,
	handleChange,
	handleProgramChange,
	handleAdd,
	handleRemoveProgram,
	programRoleOptions,
	showPrograms,
	programs,
	user,
	programId = null,
}) => {
	return (
		<Modal show={show} onHide={handleClose} className="frmModalArea ModifyModal">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<h5 className="h5Title mb-5">Modify User - {`${user?.first_name} ${user?.last_name}`}</h5>
				<Form className="formArea modifyModalForm">
					<Row>
						{!programId &&
						<Col md="12">
							<Form.Group className="selectFieldBar" controlId="role">
								<Form.Label className="labelText">Company Role</Form.Label>
								<Form.Select aria-label="role select" className="SelectField" name="role_id" onChange={handleChange}>
									<option value="">None</option>
									{roles
										?.filter((item) => item.type == "company")
										.map((role) => (
											<option value={role.id} selected={role.id == formValues.role_id ? true : false}>
												{role.name}
											</option>
										))}
								</Form.Select>
							</Form.Group>
							{errors?.role_id && <p className="text-danger">{errors.role_id}</p>}
						</Col>
					}
						{showPrograms && programs?.length > 0 && (
							<>
								<Col md="12 mb-3">
									<Form.Label className="labelText">Program</Form.Label>
									{formValues.programs.map((program, pIndex) => (
										<div className="programsFlexBar" key={"program-" + pIndex}>
											<div className="programsFieldBar">
												<Form.Select
													aria-label="program select"
													className="SelectField"
													name={"program_id"}
													value={program.program_id}
													onChange={(e) => handleProgramChange(pIndex, e)}
												>
													<option value="">Select Program</option>
													{programs?.map((item) => (
														<option value={item.id} selected={program.program_id == item.id ? true : false}>
															{item.name}
														</option>
													))}
												</Form.Select>
												{errors[`program_id-${pIndex}`] && <p className="text-danger">{errors[`program_id-${pIndex}`]}</p>}
											</div>
											<div className="programsFieldBar">
												<Form.Select
													aria-label="program role select"
													className="SelectField "
													name={"role_id"}
													value={program.role_id}
													onChange={(e) => handleProgramChange(pIndex, e)}
												>
													<option value="">Role</option>
													{programRoleOptions.map((role) => (
														<option value={role.id} selected={program.role_id == role.id ? true : false}>
															{role.name}
														</option>
													))}
												</Form.Select>
												{errors[`role_id-${pIndex}`] && <p className="text-danger">{errors[`role_id-${pIndex}`]}</p>}
											</div>
											{!programId &&
											<div className="programsFieldBar fDeleteBtn">
												<button className="fieldDeleteBtn" type="button" onClick={() => handleRemoveProgram(pIndex)}>
													<img src={DeleteIcon} alt="delete" />
												</button>
											</div>
											}
										</div>
									))}
								</Col>
								{!programId &&
								<Col md="12">
									<button className="addPlusBtn" type="button" onClick={handleAdd}>
										<img src={plusIcon} className="plusIcon" alt="add" />
										<span>Add Program </span>
									</button>
								</Col>
								}
							</>
						)}
					</Row>
					<Row>
						<Col md="12">
							<div className="mdlFtBar ftBdr">
								<button className="fillPrimarBtn mrLauto  InviteBtn send " type="button" onClick={handleSubmit}>
									Save
								</button>
							</div>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default EditCompanyUserView;
