import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import StageTableView from "./StageTableView";
import {
  addCompanyStageApi,
  addProgramStageApi,
  useGetStages,
  useGetHPXMLEventTypes,
} from "services/StageService";
import { setItemsBorder } from "utils/helpers/Common";

const StageTable = ({ companyId, programId, type }) => {
  const [stageItems, setStageItems] = useState([]);
  const navigate = useNavigate();
  const { stageTitle } = useSelector((state) => state.company);
  const { stages, stagesMutate } = useGetStages(companyId, programId);
  const { hpxmlEventTypes } = useGetHPXMLEventTypes();


  useEffect(() => {
    if (type == "company") {
      const stockItems =
        stages?.filter(
          (item) => item.is_stock_associated || item.is_stock_stage
        ) ?? [];
      const customItems =
        stages?.filter(
          (item) => !item.is_stock_associated && !item.is_stock_stage
        ) ?? [];
      setStageItems([...setItemsBorder(stockItems), ...customItems]);
    } else {
      setStageItems(stages);
    }
  }, [stages]);

  const handleBack = () => {
    navigate(`/setting/company/${companyId}`);
  };

  const handleChange = (value, stageIndex, fieldType = 'name') => {
    const companyProgramId = stageItems[stageIndex].program_id;
    const data = {
      company_id: companyId,
      program_id: companyProgramId || programId,
      stage_id: stageItems[stageIndex].is_stock_associated
        ? stageItems[stageIndex].id
        : stageItems[stageIndex].stage_id,
      stage_value_id:
        !stageItems[stageIndex].is_stock_associated &&
          !stageItems[stageIndex].stage_id
          ? stageItems[stageIndex].id
          : null,
      [fieldType]: value,
    };
    const apiCall =
      companyId && (programId || companyProgramId)
        ? addProgramStageApi
        : addCompanyStageApi;
    apiCall(data)
      .then(() => {
        stagesMutate();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <StageTableView
      title={stageTitle}
      handleBack={handleBack}
      stages={stageItems}
      type={type}
      companyId={companyId}
      programId={programId}
      handleChange={handleChange}
      stagesMutate={stagesMutate}
      hpxmlEventTypes={hpxmlEventTypes}
    />
  );
};

export default StageTable;
