import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import plusIcon from "assets/images/icons/add-plus-icon.svg";
import DeleteIcon from "assets/images/icons/delete-icon.svg";
import FieldLabel from "components/form/label/FieldLabel";
import { ROLE_DESCRIPTION_LINK } from "utils/constants/Constants";
import LegalDocumentModal from "pages/auth/signup/LegalDocumentModal";

const InviteUserView = ({
	company,
	errors,
	handleSubmit,
	roles,
	handleAdd,
	handleProgramSelect,
	handleChange,
	formValues,
	setFormValues,
	setErrors,
	showPrograms,
	setShowPrograms,
	programRoleOptions,
	programs,
	useModal,
	saveBtnText,
	cancelBtnText,
	callback,
	onSkip,
	onCancel,
	handleRemoveProgram,
	showRoleDescription,
	setShowRoleDescription,
	programId = null,
	hasCompanyPermission
}) => {
	const [show, setShow] = useState(false);
	const companyHasPrograms = company?.special_features?.programs == true && programs?.length > 0;
	const handleClose = () => {
		setShow(false);
		setShowPrograms(true);
		setFormValues({
			company_id: company.id,
			email: "",
			first_name: "",
			last_name: "",
			role: "",
			programs: [{ program_id: "", role_id: "" }],
		});
		setErrors({});
		callback && callback();
	};
	const handleShow = () => setShow(true);
	const FormContent = (
		<>
			<Form className="formArea">
				<div className="mobVtScroll">
					<Row>
						<Col md="12">
							<Form.Group className="mb-3" controlId="email">
								<FieldLabel label="Email" required />
								<Form.Control type="email" placeholder="Email" className="inputField " name="email" value={formValues.email} onChange={handleChange} />
								{errors.email && <p className="text-danger">{errors.email}</p>}
							</Form.Group>
						</Col>
						{hasCompanyPermission && (
							<>
								<Col md="12">
									<Form.Group className="mb-3" controlId="role">
										<FieldLabel label="Company Role" />
										<Form.Select aria-label="Default select example " className="SelectField " name="role" onChange={(e) => handleChange(e)}>
											{companyHasPrograms && (
												<option value="">None</option>
												)}
											{roles
												?.filter((item) => item.type === "company")
												.map((role) => (
													<option value={role.id}>{role.name}</option>
												))}
										</Form.Select>
										<button className="btn btn-link p-0 float-end smText" type="button" onClick={() => setShowRoleDescription(true)}>
											View Role Description
										</button>
										{errors.role && <p className="text-danger">{errors.role}</p>}
									</Form.Group>
								</Col>
							</>
						)
						}
						{showPrograms && (
							<>
								{companyHasPrograms ? (
									<>
										<FieldLabel label="Programs" required={!formValues.role ? true : false} />
										{formValues.programs.map((program, pIndex) => (
											<>
												<Col md="6">
													<Form.Group className="mb-3" controlId={"program" + pIndex}>
														<Form.Select
															aria-label="program select"
															className="SelectField"
															name={"program_id"}
															value={program.program_id}
															onChange={(e) => handleProgramSelect(pIndex, e)}
														>
															<option value="">Select Program</option>
															{programs?.map((item) => (
																<option value={item.id} selected={program.program_id == item.id ? true : false}>
																	{item.name}
																</option>
															))}
														</Form.Select>
													</Form.Group>
													{errors[`program_id-${pIndex}`] && <p className="text-danger">{errors[`program_id-${pIndex}`]}</p>}
												</Col>
												<Col md="4">
													<Form.Group className="mb-3" controlId={"role" + pIndex}>
														<Form.Select
															aria-label="role select"
															className="SelectField "
															name={"role_id"}
															value={program.role_id}
															onChange={(e) => handleProgramSelect(pIndex, e)}
														>
															<option value="">Role</option>
															{programRoleOptions.map((role) => (
																<option value={role.id}>{role.name}</option>
															))}
														</Form.Select>
													</Form.Group>
													{errors[`role_id-${pIndex}`] && <p className="text-danger">{errors[`role_id-${pIndex}`]}</p>}
												</Col>
												{ !programId && (
													<Col md="2">
														<div className="programsFieldBar fDeleteBtn">
															<button className="fieldDeleteBtn" type="button" onClick={() => handleRemoveProgram(pIndex)}>
																<img src={DeleteIcon} alt="delete" />
															</button>
														</div>
													</Col>
													)
												}
											</>
										))}
										{formValues.programs.length < programs.length && (
											<Col md="12">
												<button className="addPlusBtn" type="button" onClick={handleAdd}>
													<img src={plusIcon} className="plusIcon" alt="plus icon" />
													<span>Add Program </span>
												</button>
											</Col>
										)}
									</>
								) : (
									<>
										{(!formValues.role && companyHasPrograms) && (
											<Col md="12">
												<Alert variant="warning">If you want to invite a user with company role None then please assign at least one program to the company.</Alert>
											</Col>
										)}
									</>
								)}
							</>
						)}
					</Row>

					<Row>
						<Col md="12">
							<svg width="100%" height="2" viewBox="0 0 100% 2" fill="none" xmlns="http://www.w3.org/2000/svg">
								<line x1="-3.05176e-05" y1="1" x2="100%" y2="1" stroke="#CFD4D9" stroke-width="2" stroke-dasharray="4 4" />
							</svg>
						</Col>
					</Row>
				</div>
				<Row>
					<Col md="12">
						<div className="d-flex align-items-center gap-3 justify-content-end footerBtnBar px-3">
							{onCancel && (
								<button className="btn mdBtn border" onClick={onCancel}>
									{cancelBtnText}
								</button>
							)}
							{onSkip && (
								<button className="btn mdBtn border" onClick={onSkip}>
									Skip
								</button>
							)}
							<button className="btn mdBtn primaryBtn" type="button" onClick={() => handleSubmit(handleClose)}>
								{saveBtnText}
							</button>
						</div>
					</Col>
				</Row>
			</Form>
			{showRoleDescription && (
				<LegalDocumentModal show={showRoleDescription} handleClose={() => setShowRoleDescription(false)} documentTitle={"Role Description"} documentLink={ROLE_DESCRIPTION_LINK} />
			)}
		</>
	);

	return useModal ? (
		<>
			<Button onClick={handleShow} className="InviteUserBtn">
				Invite a new user
			</Button>
			<Modal show={show} onHide={handleClose} className="frmModalArea">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<h5 className="h5Title mb-3">Invite a user to {company?.name}</h5>
					<p className="smText mdlTextMaxWd mb-4">
						Invited users will receive an email with a link to join {` ${company?.name}`}. Please select the appropriate permission type for this user. Their permission role can be
						adjusted at any time in the Users tab.
					</p>
					{FormContent}
				</Modal.Body>
			</Modal>
		</>
	) : (
		<div>{FormContent}</div>
	);
};

export default InviteUserView;
