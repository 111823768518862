import React from "react";
import Select from "react-select";
import PbackIcon from "assets/images/icons/back-icon.svg";
import SearchIcon from "assets/images/icons/search-icon.svg";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import InfoColorBox from "components/infoColorBox/InfoColorBox";
import { useCheckCompanyPermission, useGetProgramsWhereUserHasPermission } from "utils/helpers/Common";
import { EDIT_COMPANY_MEASURES_STAGES } from "utils/constants/UserPermissions";
const CostingTableView = ({
	lineItems,
	handleBack,
	companyName,
	programName,
	handleChange,
	query,
	setQuery,
	categories,
	selectedCategory,
	setSelectedCategory,
	handleLoadMore,
	currentPage,
	lastPage,
	headers,
	programs,
	setSelectedProgram,
	selectedProgram,
	type,
	showBackBtn = true,
	userRole = null,
}) => {
	const hasCompanyPermission = useCheckCompanyPermission([EDIT_COMPANY_MEASURES_STAGES]);
	const userPrograms = useGetProgramsWhereUserHasPermission([EDIT_COMPANY_MEASURES_STAGES]);
	return (
		<div className="pageCard">
			<InfoColorBox />
			<div className="tblHeaderArea frmActionHead">
				<div className="tblLeftClm">
					<div className="flexTitleBox">
						{showBackBtn && <img src={PbackIcon} className="backIcon" onClick={handleBack} />}
						<div className="TitleBox">
							{selectedProgram ? <h4>{companyName}</h4> : <h4>{programName ?? companyName}</h4>}

							<p>Manage {type} measures</p>
						</div>
					</div>
				</div>
				<div className="tblRightClm">
					{type == "company" && (
						<Select
							className="tblSelectInput filterSelect"
							isClearable={true}
							placeholder="All Program"
							options={programs?.filter((program) => hasCompanyPermission ? true : userPrograms?.some((userProgram) => userProgram.id === program.id))?.map((c) => ({ value: c.id, label: c.name }))}
							onChange={(e) => setSelectedProgram(e?.value || "")}
							styles={{
								control: (baseStyles, state) => ({
									...baseStyles,
									border: 0,
									boxShadow: "none",
								}),
							}}
						/>
					)}

					<div className="tblSearchBar">
						<input type="search" className="searchField" placeholder="Search" value={query} onChange={(e) => setQuery(e.target.value)} />
						<img src={SearchIcon} className="searchIcon" alt="" />
					</div>
					<Select
						className="tblSelectInput"
						isClearable={true}
						placeholder="All Category"
						options={categories?.map((c) => ({ value: c.id, label: c.name }))}
						onChange={(e) => setSelectedCategory(e?.value || "")}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								border: 0,
								boxShadow: "none",
							}),
						}}
					/>
				</div>
			</div>
			<div className="tableScrollArea costingTableArea verticalScroll">
				<table className="tableArea  ">
					<TableHeader headers={headers} />
					<div className="tableSpace"></div>
					<tbody>
						{lineItems?.length > 0 &&
							lineItems.map((item, index) => {
								return (
									<TableRow
										key={index + "LineItem"}
										lineItemIndex={index}
										lineItem={item}
										handleChange={handleChange}
										headers={headers}
										type={type}
										hasCompanyPermission={hasCompanyPermission}
									/>
								);
							})}
					</tbody>
				</table>
			</div>
			{currentPage < lastPage && (
				<div className="d-flex justify-content-center align-items-center">
					<button className="loadMoreBtn" onClick={handleLoadMore}>
						Load More
					</button>
				</div>
			)}
		</div>
	);
};

export default CostingTableView;
