import React from "react";
import Select from "react-select";
import { CUSTOMIZED_COLOR, INHERITED_COLOR, DISABLED_COLOR } from "utils/constants/Constants";
const TableSelect = ({ options, value, onChange, isCustomized, isInherited, isDisabled }) => {
	const backgroundColor = isCustomized && !isInherited ? CUSTOMIZED_COLOR : isInherited ? INHERITED_COLOR : isDisabled ? DISABLED_COLOR : "#ffffff";
	return (
		<Select
			options={options}
			value={options?.find((option) => option.value === value)}
			onChange={onChange}
			className="stageSelect"
			isDisabled={isDisabled}
			styles={{
				control: (base) => ({
					...base,
					border: "none",
					boxShadow: "none",
					backgroundColor: backgroundColor,
					"&:hover": {
						border: "none",
					},
				}),
			}}
		/>
	);
};

export default TableSelect;
