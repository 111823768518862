import React from "react";
import Modal from "react-bootstrap/Modal";
const LegalDocumentModal = ({ show, handleClose, documentTitle, documentLink }) => {
	return (
		<Modal show={show} onHide={handleClose} size="xl" className="legalDocumentModal">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<div className="modalCardBox ">
					<h5>{documentTitle}</h5>
					<iframe src={documentLink} title={documentTitle} className="w-100 "></iframe>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default LegalDocumentModal;
