import React, { useEffect, useState } from "react";

const TableInput = ({
  data,
  handleChange,
  fieldId,
  header,
  lineItemIndex,
  isLaborCostCustomized,
  isMaterialCostCustomized,
  isLaborCostInherited,
  isMaterialCostInherited,
  isReadOnly,
  isDisabled,
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(data ?? "");
  }, [data]);

  const backgroundColor =
    header == "labor_cost" && isLaborCostCustomized && !isLaborCostInherited
      ? "#ffd28073"
      : header == "material_cost" &&
        isMaterialCostCustomized &&
        !isMaterialCostInherited
      ? "#ffd28073"
      : header == "labor_cost" && isLaborCostInherited
      ? "#FFFFE0 "
      : header == "material_cost" && isMaterialCostInherited
      ? "#FFFFE0"
      : "";

  return (
    <input
      className={`tblText  inputTblText ${isReadOnly ? "bgGray" : ""}`}
      style={{ backgroundColor: backgroundColor }}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => handleChange(value, fieldId, header, lineItemIndex)}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleChange(value, fieldId, header, lineItemIndex);
        }
      }}
      readOnly={isReadOnly}
      disabled={isDisabled}
    />
  );
};

export default TableInput;
