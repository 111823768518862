import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

/**
 * Reusable modal component for displaying messages and navigating on close.
 * 
 * @param {boolean} show - Controls the visibility of the modal.
 * @param {function} handleClose - Function to close the modal.
 * @param {string} text - The text content to display inside the modal.
 * @param {string} navigateUrl - The URL to navigate to on modal close.
 */
const OptimiserModal = ({ show, handleClose, text, navigateUrl = null }) => {
    const navigate = useNavigate();

    const handleModalClose = () => {
        handleClose(); 
        navigateUrl && navigate(navigateUrl);
    };

    return (
        <Modal 
            show={show} 
            onHide={handleModalClose} 
            className="frmModalArea maxWd500" 
            size="sm"
            centered
            >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <h5 className="h5Title mb-3">{text}</h5>
            </Modal.Body>
        </Modal>
    );
};

export default OptimiserModal;
