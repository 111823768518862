import React, { useEffect, useState } from "react";
import ProgramAdminDetailView from "./ProgramAdminDetailView";
import { useNavigate, useParams, useSearchParams, useMatch } from "react-router-dom";
import { useGetCompanyDetails, useGetCompanyUserRole, useGetCompanyUserPrograms } from "services/CompanyService";
import { useSelector, useDispatch } from "react-redux";
import { setUserRole } from "../../../../redux/reducers/AuthSlice";
import { useCheckHasInternalRole } from "utils/helpers/Common";
import {
	COMPANY_USERS_TAB,
	COMPANY_MEASURES_TAB,
} from "utils/constants/Constants";

const ProgramAdminDetail = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [activeTab, setActiveTab] = useState(COMPANY_USERS_TAB);
	const [successAlert, setSuccessAlert] = useState(false);
	const { id, programId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { companyRole } = useGetCompanyUserRole(id, user.id, programId);
	const { programs } = useGetCompanyUserPrograms(id, user.id, programId);
	const { company, companyMutate } = useGetCompanyDetails(id);
	const isInternalUser = useCheckHasInternalRole();
	const isCostingUrl = useMatch("/setting/company/:id/measures/:type");

	useEffect(() => {
		dispatch(setUserRole(companyRole));
	}, [companyRole]);

	const handleBackButton = () => {
		navigate("/setting/company");
	};

	useEffect(() => {
		if (isCostingUrl) {
			setActiveTab(COMPANY_MEASURES_TAB);
		}
	}, [isCostingUrl]);

	return (
		<ProgramAdminDetailView
			company={company}
			companyMutate={companyMutate}
			handleBackButton={handleBackButton}
			activeTab={activeTab}
			setActiveTab={setActiveTab}
			successAlert={successAlert}
			setSuccessAlert={setSuccessAlert}
			searchParams={searchParams}
			setSearchParams={setSearchParams}
			isInternalUser={isInternalUser}
			user={user}
			programId={programId}
			program={programs?.length ? programs[0] : null}
		/>
	);
};

export default ProgramAdminDetail;
