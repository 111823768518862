import React from "react";
import "./PasswordCredentialsStyles.css";
import ChangePassword from "./changePassword/ChangePassword";
const PasswordCredentials = () => {
  return (
    <div className="pageCard">
      <h5 className="smH5title mb-2">OptiMiser Password</h5>
      <p className="mdText mb-5">
        Change the password you use to log into OptiMiser.
      </p>
      <ChangePassword />
    </div>
  );
};

export default PasswordCredentials;
