import useSWR from "swr";
import httpClient from "./http-client";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorToast from "components/toast/ErrorToast";
import { ChangePasswordSchema, UpdateProfileSchema } from "utils/validations/ProfileValidation";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../redux/reducers/LoaderSlice";
import { fetchResponseErrors, handleApiResponseError, SwrConfigOptions, handleSwrResponseError } from "utils/helpers/Common";
import { toast } from "react-toastify";
import { authUser } from "../redux/reducers/AuthSlice";

export const useGetUser = () => {
	const dispatch = useDispatch();
	const { data: user, error, mutate } = useSWR({ url: `/user/auth-user`, dispatch }, SwrConfigOptions);
	if (error) {
		handleSwrResponseError(error);
	}
	return { user: user?.data, userMutate: mutate };
};
export const useEditProfileForm = (user, userMutate) => {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
		control,
	} = useForm({
		resolver: yupResolver(UpdateProfileSchema),
		values: {
			first_name: user?.first_name,
			last_name: user?.last_name,
			email: user?.email,
			phone_number: user?.phone_number,
			address_1: user?.address_1,
			address_2: user?.address_2,
			zip_code: user?.zip_code ?? "",
			city: user?.city,
			state: user?.state ? { label: user?.state, value: user?.state } : { label: null, value: null },
			website: user?.website,
		},
	});
	const _handleSubmit = (data, callback) => {
		dispatch(showLoader());
		updateProfileApi(user.id, data)
			.then((res) => {
				toast.success("Profile updated successfully");
				dispatch(authUser(res.data.data));
				callback && callback();
				userMutate && userMutate();
			})
			.catch((err) => {
				toast.error(<ErrorToast error={fetchResponseErrors(err.response)} />);
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};
	const handleFileUpload = (file, handleClose) => {
		const formData = new FormData();
		formData.append("profile_image", file);
		dispatch(showLoader());
		uploadProfileImageApi(formData)
			.then((res) => {
				toast.success("Profile image updated successfully");
				dispatch(authUser(res.data.data));
				userMutate && userMutate();
				handleClose && handleClose();
			})
			.catch((err) => {
				toast.error(<ErrorToast error={fetchResponseErrors(err.response)} />);
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};
	return {
		register,
		errors,
		handleSubmit: handleSubmit(_handleSubmit),
		handleFileUpload,
		Controller,
		control,
		isDirty,
	};
};

export const useChangePasswordForm = () => {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		getFieldState,
		formState: { errors },
		getValues,
		reset,
		setError,
	} = useForm({
		resolver: yupResolver(ChangePasswordSchema),
	});
	const _handleSubmit = (data, handleClose) => {
		dispatch(showLoader());
		changePasswordApi(data)
			.then((res) => {
				toast.success(res.data.message);
				handleClose();
				reset();
			})
			.catch((err) => {
				handleApiResponseError(setError, err.response);
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};
	return {
		register,
		errors,
		handleSubmit: handleSubmit(_handleSubmit),
		getValues,
		getFieldState,
		reset,
	};
};

export const changePasswordApi = (data) => {
	return httpClient.post("/user/change-password", data);
};

export const updateProfileApi = (id, data) => {
	return httpClient.put(`/user/${id}`, data);
};
export const uploadProfileImageApi = (data) => {
	return httpClient.post(`/user/profile-image`, data);
};

export const logoutApi = () => {
	return httpClient.post("/logout");
};
