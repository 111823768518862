import React from "react";
import TableInput from "./TableInput";
const TableRow = ({ lineItemIndex, lineItem, handleChange, headers, type, hasCompanyPermission }) => {
	return (
		<tr
			key={lineItemIndex + "row"}
			className={`${
				lineItem["border_color"] == "lightgreen" && lineItem["border_top"]
					? "bdrClrLightGreen bdrTopLightGreen"
					: lineItem["border_color"] == "lightgreen" && lineItem["border_bottom"]
					? "bdrClrLightGreen bdrBottomLightGreen"
					: lineItem["border_color"] == "lightgreen"
					? "bdrClrLightGreen"
					: lineItem["border_color"] == "darkgreen" && lineItem["border_top"]
					? "bdrClrDarkGreen bdrTopDarkGreen"
					: lineItem["border_color"] == "darkgreen" && lineItem["border_bottom"]
					? "bdrClrDarkGreen bdrBottomDarkGreen"
					: lineItem["border_color"] == "darkgreen"
					? "bdrClrDarkGreen"
					: ""
			}`}
		>
			{headers?.map((header, headerIndex) => {
				return (
					<td key={lineItemIndex + headerIndex + "td"} className={`tblTd ${type == "company" && header.value == "program" ? "thirdTdSticky" : ""}`}>
						{header.value == "labor_cost" || header.value == "material_cost" ? (
							<TableInput
								data={lineItem[header.value]}
								handleChange={handleChange}
								fieldId={lineItem["id"]}
								lineItemIndex={lineItemIndex}
								header={header.value}
								isLaborCostCustomized={lineItem["is_labor_cost_customized"]}
								isMaterialCostCustomized={lineItem["is_material_cost_customized"]}
								isLaborCostInherited={lineItem["is_labor_cost_inherited"]}
								isMaterialCostInherited={lineItem["is_material_cost_inherited"]}
								isReadOnly={lineItem["is_stock_measure"] ? true : lineItem["is_company_measure"] && !hasCompanyPermission ? true : false}
								isDisabled={false}
							/>
						) : (
							<p className="tblText">{`${
								header.value == "program" && lineItem["is_stock_measure"]
									? "Stock Value"
									: header.value == "program" && lineItem["is_company_measure"]
									? "Company Value"
									: lineItem[header.value]
							}`}</p>
						)}
					</td>
				);
			})}
		</tr>
	);
};

export default TableRow;
