import React, { useState } from "react";
import { useParams } from "react-router-dom";
import StageView from "./StageView";
import StageTable from "./table/StageTable";
import { useDispatch } from "react-redux";

const Stage = ({ company }) => {
  const [hideDeprecated, setHideDeprecated] = useState(false);
  const { id, type, programId } = useParams();
  const dispatch = useDispatch();
  return (
    <>
      {type == "company" || type == "program" ? (
        <StageTable companyId={id} programId={programId} type={type} />
      ) : (
        <StageView company={company} dispatch={dispatch} hideDeprecated={hideDeprecated} setHideDeprecated={setHideDeprecated} />
      )}
    </>
  );
};

export default Stage;
