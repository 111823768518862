import httpClient from "./http-client";
import useSWR from "swr";
import { useDispatch } from "react-redux";
import {
  SwrConfigOptions,
  addParamsToURL,
  handleSwrResponseError,
} from "utils/helpers/Common";
import { toast } from "react-toastify";
export const useGetStages = (companyId, programId) => {
  const dispatch = useDispatch();
  const {
    data: stages,
    error,
    mutate,
  } = useSWR(
    companyId
      ? {
          url: addParamsToURL("/stages", {
            company_id: companyId,
            program_id: programId,
          }),
          dispatch,
        }
      : null,
    SwrConfigOptions
  );
  if (error) {
    toast.error("Network error occurs");
  }
  return { stages: stages?.data, stagesMutate: mutate };
};

export const useGetHPXMLEventTypes = () => {
  const dispatch = useDispatch();
  const { data: hpxmlEventTypes, error, mutate } = useSWR(
    {
      url: "/stages/hpxml-event-types",
      dispatch,
    },
    SwrConfigOptions
  );
  if (error) {
    toast.error("Network error occurred");
  }
  return { hpxmlEventTypes: hpxmlEventTypes?.data, hpxmlEventTypesMutate: mutate };
};

export const addCompanyStageApi = (data) => {
  return httpClient.post("/stages/company", data);
};

export const addProgramStageApi = (data) => {
  return httpClient.post("/stages/program", data);
};
