import React from "react";

const MeasureTableHeader = ({ headers }) => {
  return (
    <thead>
      <tr>
        <th></th>
        {headers
          .filter((item) => item.value !== "program")
          .map((item, index) => (
            <th
              className="tblTh"
              key={index}
              style={{ backgroundColor: item?.color ? item.color : "" }}
            >
              <p className="tblText">{item.label}</p>
            </th>
          ))}
      </tr>
    </thead>
  );
};

export default MeasureTableHeader;
