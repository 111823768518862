import axios from "axios";
import { API_BASE_URL, SESSION_TOKEN_COOKIE_NAME, ZEUS_SESSION_COOKIE_NAME } from "utils/constants/Constants";
import { getCookie, removeCookie } from "utils/helpers/Common";
const httpClient = axios.create({
	baseURL: API_BASE_URL,
});

httpClient.interceptors.request.use(
	function (config) {
		config.headers["Authorization"] = `Bearer ${getCookie("session_token")}`;
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);
httpClient.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response.status == 401) {
			removeCookie(SESSION_TOKEN_COOKIE_NAME);
			removeCookie(ZEUS_SESSION_COOKIE_NAME);
			localStorage.clear();
			return (window.location.href = "/");
		}
		return Promise.reject(error);
	}
);

export default httpClient;
