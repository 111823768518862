import useSWR from "swr";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import httpClient from "./http-client";
import { SwrConfigOptions, handleSwrResponseError } from "utils/helpers/Common";

export const useGetPrograms = () => {
	const dispatch = useDispatch();
	const { data: programs, error } = useSWR(
		{
			url: `/programs`,
			dispatch,
		},
		SwrConfigOptions
	);
	if (error) {
		handleSwrResponseError(error);
	}
	return { programs: programs?.data };
};

export const useGetCompanyCustomPrograms = (companyId, isTrashed) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		data: customPrograms,
		error,
		mutate,
	} = useSWR(
		companyId
			? {
					url: `/company-programs?company_id=${companyId}&is_trashed=${isTrashed ? 1 : 0}`,
					dispatch,
			  }
			: null,
		SwrConfigOptions
	);
	if (error) {
		handleSwrResponseError(error, navigate, "/setting/company");
	}
	return { customPrograms: customPrograms?.data, programsMutate: mutate };
};

export const createCompanyProgramApi = (data) => {
	return httpClient.post("/company-programs", data);
};

export const assignCompanyProgramApi = (data) => {
	return httpClient.post("/company-programs/assign", data);
};

export const updateCompanyProgramApi = (id, data) => {
	return httpClient.put(`/company-programs/${id}`, data);
};

export const updateCompanyProgramLogoApi = (programId, data) => {
	return httpClient.post(`/company-programs/${programId}/logo`, data);
};

export const unassignCompanyProgramApi = (id, data) => {
	return httpClient.put(`/company-programs/${id}/unassign`, data);
};
export const deleteCompanyProgramApi = (id, companyId) => {
	return httpClient.delete(`/company-programs/${id}?company_id=${companyId}`);
};

export const restoreCompanyProgramApi = (id, companyId) => {
	return httpClient.put(`/company-programs/${id}/restore?company_id=${companyId}`);
};
