import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Tabs.css";
const TabsList = ({ listData, activeIndex = 0, onChange }) => {
  const [tabIndex, setTabIndex] = useState(activeIndex);
  useEffect(() => {
    setTabIndex(activeIndex);
  }, [activeIndex]);
  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={tabIndex}
      onSelect={(k) => {
        setTabIndex(k);
        onChange && onChange(k);
      }}
      className="mb-3 underLineTabsList"
    >
      {listData &&
        listData.map((list, idx) => (
          <Tab
            key={idx}
            eventKey={list.eventKey ?? `${idx}`}
            title={list.title}
          >
            {list.body}
          </Tab>
        ))}
    </Tabs>
  );
};

export default TabsList;
