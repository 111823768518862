import React from "react";
import MobLogo from "assets/images/logo-img.png";
import "./MobileHeaderStyles.css";
import MobileSideBar from "layouts/sidebar/mobile/MobileSideBar";

function MobileHeader() {
  return (
    <div className="mobHeaderArea">
      <MobileSideBar />
      <div className="mobLogoBox">
        <img src={MobLogo} className="mobLogoImg" />
      </div>
    </div>
  );
}

export default MobileHeader;
