import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import PersistedReducer from "./reducers/Index";

const store = configureStore({
  reducer: PersistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
const persistor = persistStore(store);
export { store, persistor };
