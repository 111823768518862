import React from "react";

const TableHeader = ({ headers }) => {
  return (
    <thead>
      <tr>
        {headers?.map((header, index) => (
          <th
            className={`tblTh ${
              header.value == "program" ? "thirdTdSticky" : ""
            }`}
            key={"header" + index}
            style={{ backgroundColor: header?.color ? header.color : "" }}
          >
            <p
              className="tblText"
              style={{
                color: header?.color == "darkgreen" ? "white" : "",
              }}
            >
              {header.label}
            </p>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
