import React, { useEffect, useState } from "react";
import SignUpView from "./SignUpView";
import { useSignUpForm } from "services/AuthService";
import { useMatch, useSearchParams, useLocation } from "react-router-dom";
import { useImpersonation } from "hooks/useImpersonation";

const SignUp = () => {
	useImpersonation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [activeTab, setActiveTab] = useState("tab-1");
	let location = useLocation();
	const { register, handleSubmit, errors } = useSignUpForm();
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
	const [showSignInModal, setShowSignInModal] = useState(searchParams.get("signin") === "true");
	const [password, setPassword] = useState(null);
	const [legalDocumentModalData, setLegalDocumentModalData] = useState({
		show: false,
		documentTitle: "",
		documentLink: "",
	});
	const [passwordType, setPasswordType] = useState('password');
	const [confirmPasswordType, setConfirmPasswordType] = useState('password');

	const match = useMatch("/reset-password");
	const isJoinCompanyUrl = useMatch("/join-company");

	useEffect(() => {
		if (match) {
			setShowResetPasswordModal(true);
		}
		if (isJoinCompanyUrl) {
			setActiveTab("tab-2");
		}
		setShowSignInModal(searchParams.get("signin") === "true");
		if (searchParams.get('enforce_tab1') === 'true') {
			setActiveTab("tab-1");
			searchParams.delete('enforce_tab1');
		
			const newUrl = searchParams.toString()
				? `${window.location.pathname}?${searchParams.toString()}`
				: window.location.pathname;
		
			window.history.replaceState(null, '', newUrl);
		}		
	}, [match, isJoinCompanyUrl, searchParams]);

	const togglePasswordType = (field) => {
		if(field === 'password') {
			passwordType === 'password' ? setPasswordType('text') : setPasswordType('password')
		}else if(field === 'confirmPassword') {
			confirmPasswordType === 'password' ? setConfirmPasswordType('text') : setConfirmPasswordType('password')
		}
	}
	
	return (
		<SignUpView
			register={register}
			handleSubmit={handleSubmit}
			errors={errors}
			showResetPasswordModal={showResetPasswordModal}
			setShowResetPasswordModal={setShowResetPasswordModal}
			showSignInModal={showSignInModal}
			searchParams={searchParams}
			isJoinCompanyUrl={isJoinCompanyUrl}
			password={password}
			setPassword={setPassword}
			activeTab={activeTab}
			setActiveTab={setActiveTab}
			location={location}
			legalDocumentModalData={legalDocumentModalData}
			setLegalDocumentModalData={setLegalDocumentModalData}
			passwordType={passwordType}
			confirmPasswordType={confirmPasswordType}
			togglePasswordType={togglePasswordType}
		/>
	);
};

export default SignUp;
