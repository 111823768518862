import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { SWRConfig } from "swr";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { fetcher } from "utils/helpers/Common";
import RootNavigator from "./navigations/RootNavigator";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../src/styles/global.css";
import "../src/styles/responsive.css";
import Loader from "components/loader/Loader";
import { STRIPE_KEY } from "utils/constants/Constants";

const stripePromise = loadStripe(STRIPE_KEY);
function App() {
  const { loading } = useSelector((state) => state.loader);

  const options = {
    style: {
      base: {
        border: "1px solid red",
        iconColor: "#c4f0ff",
        color: "#fff",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#87BBFD",
        },
      },
      invalid: {
        iconColor: "#FFC7EE",
        color: "#FFC7EE",
      },
    },
  };
  return (
    <>
      {loading && <Loader />}
      <ToastContainer
        position="bottom-center"
        style={{ zIndex: 99999999 }}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className={"toastMessage"}
        theme="dark"
      />
      <SWRConfig
        value={{
          fetcher,
          revalidateIfStale: true,
          revalidateOnMount: true,
          revalidateOnReconnect: true,
          refreshInterval: 80000,
        }}
      >
        <Elements stripe={stripePromise} options={options}>
          <BrowserRouter>
            <Routes>
              <Route path="/*" exact element={<RootNavigator />} />
              <Route
                path="*"
                exact={true}
                element={<Navigate to={{ pathname: "/" }} />}
              />
            </Routes>
          </BrowserRouter>
        </Elements>
      </SWRConfig>
    </>
  );
}

export default App;
