import React, { useEffect, useState } from "react";
import EditProgramView from "./EditProgramView";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../../../redux/reducers/LoaderSlice";
import { toast } from "react-toastify";
import { updateCompanyProgramApi, updateCompanyProgramLogoApi } from "services/ProgramService";
import useFileUpload from "hooks/useFileUpload";
import { useCheckPermissions } from "utils/helpers/Common";
import { EDIT_PROGRAMS } from "utils/constants/UserPermissions";
/**
 * Component for editing a company program's details including name and logo
 * @param {string} companyId - ID of the company the program belongs to
 * @param {Object} program - Program object containing details to edit
 * @param {string} program.id - ID of the program
 * @param {string} program.label - Current name/label of the program
 * @param {string} program.name - Current name of the program
 * @param {string} program.logo - URL of the program's current logo
 * @param {Function} programsMutate - Function to trigger programs data refresh
 * @param {Function} companyMutate - Function to trigger company data refresh
 * @returns {JSX.Element} Program edit form modal
 */
const EditProgram = ({ companyId, program, programsMutate, companyMutate }) => {
	const [programName, setProgramName] = useState(program.label);
	const [show, setShow] = useState(false);
	const dispatch = useDispatch();
	const { file, onFileChange, deleteFile, setFile } = useFileUpload();
	const hasEditProgramPermission = useCheckPermissions([EDIT_PROGRAMS], program.id);
	useEffect(() => {
		setProgramName(program.label);
	}, [program]);

	const handleShow = () => setShow(true);
	const handleClose = () => {
		setProgramName(program.label);
		setFile(null);
		setShow(false);
	};

	const handleSubmit = async () => {
		if (!programName) {
			toast.error("Please enter program name");
			return;
		}

		dispatch(showLoader());

		try {
			// If there's a new logo file, update it first
			if (file) {
				const logoFormData = new FormData();
				logoFormData.append("logo", file);
				logoFormData.append("company_id", companyId);

				await updateCompanyProgramLogoApi(program.id, logoFormData);
			}

			// Then update the program name
			const nameData = {
				name: programName,
				company_id: companyId,
			};

			await updateCompanyProgramApi(program.id, nameData);

			handleClose();
			programsMutate();
			companyMutate();
			toast.success("Program updated successfully");
		} catch (err) {
			toast.error(err.response.data.message);
		} finally {
			dispatch(hideLoader());
		}
	};

	return (
		<>
			{hasEditProgramPermission && (
				<EditProgramView
					programName={programName}
					setProgramName={setProgramName}
					show={show}
					handleShow={handleShow}
					handleClose={handleClose}
					handleSubmit={handleSubmit}
					file={file}
					onFileChange={onFileChange}
					deleteFile={deleteFile}
					companyProgramLogo={program.logo}
				/>
			)}
		</>
	);
};

export default EditProgram;
