import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import UpgradeIcon from "assets/images/icons/upgrade-icon.svg";
import DowngradeIcon from "assets/images/icons/downgrade-icon.svg";
import "./ConfirmationAlertStyles.css";
const ChangePlanAlert = ({ title, description, show, handleClose, handleConfirm, confirmText, confirmBtnText, type }) => {
	return (
		<div>
			<Modal show={show} onHide={handleClose} className="frmModalArea smModal changePlanModal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="modalCardBox ">
						<img src={type == "upgrade" ? UpgradeIcon : DowngradeIcon} className="mdlErrorIcon" alt="upgrade-downgrade-icon" />
						<h5>{title}</h5>
						<p className="mb-4">{description}</p>
						<p className="mb-2">{confirmText}</p>
						<div className="d-flex align-items-center justify-content-end gap-4">
							{confirmBtnText !== "Done" && (
								<Button className="bdrBtn mrLauto cancelBtn" onClick={handleClose}>
									Cancel
								</Button>
							)}
							<Button className="fillPrimarBtn" type="button" onClick={handleConfirm}>
								{confirmBtnText}
							</Button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ChangePlanAlert;
