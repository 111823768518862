import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./LoaderStyles.css";

function Loader() {
  return (
    <div className="loaderOverlay">
      <div className="loaderShape">
        <Spinner animation="border" />
      </div>
    </div>
  );
}

export default Loader;
