import React from "react";
import UploadIcon from "assets/images/icons/upload-icon.svg";

const UploadImageArea = ({ imageUrl, file, onFileChange, deleteFile }) => {
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.add('dragover');
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.remove('dragover');
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.remove('dragover');

        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile) {
            const syntheticEvent = {
                target: {
                    files: [droppedFile]
                }
            };
            onFileChange(syntheticEvent);
        }
    };

    return (
        <div className="UploadImgModalArea">
            <div className="mdlUploadArea">
                {/* upload file content box start */}
                {!file ? (
                    <div
                        className="dropFileBox"
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                className="uploadBoxImg"
                                alt="Preview"
                            />
                        ) :
                            (
                                <img src={UploadIcon} className="uploadIcon" alt="Upload" />
                            )}
                        <h5 className="h5Title mb-2">Drop a file here</h5>
                        <p className="smText mb-3">Select a JPEG or PNG image file</p>
                        <div className="input-group fileUpload">
                            <label className="input-group-text" htmlFor="uploadFile">
                                Choose file
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                id="uploadFile"
                                accept=".jpg, .jpeg, .png"
                                onChange={onFileChange}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="uploadShowImgBox">
                        <img
                            src={URL.createObjectURL(file)}
                            className="uploadBoxImg"
                            alt="Preview"
                        />
                        <button className="deleteImgBtn" onClick={deleteFile}>
                            Delete
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UploadImageArea; 