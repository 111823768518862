import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SettingView from "./SettingView";
import { useCheckHasInternalRole } from "utils/helpers/Common";
const Setting = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const isInternalUser = useCheckHasInternalRole();

  useEffect(() => {
    if (!isInternalUser) {
      navigate("/setting/profile");
    }
  }, [isInternalUser]);
  return (
    <SettingView
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      isInternalUser={isInternalUser}
    />
  );
};

export default Setting;
