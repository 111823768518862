export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const EULA_LINK = "https://docs.google.com/document/d/0B0K3mVLsL26eNHJpNDkzUFpBZzA/preview?usp=drive_link&ouid=113341564807057516149&resourcekey=0-928M6ksUb6rv-ANafHCJ2g&rtpof=true&sd=true";
export const PRIVACY_POLICY_LINK =
	"https://docs.google.com/document/d/0B5CB1HezAjMLb1dweGNIc1dJWEk/preview?usp=drive_link&ouid=113341564807057516149&resourcekey=0-AUqU9QGfIBoKrgdBBd2ZOg&rtpof=true&sd=true";
export const TERMS_OF_SERVICE_LINK =
	"https://docs.google.com/document/d/0B5CB1HezAjMLQ2kyZ0N4MER2dTg/preview?usp=drive_link&ouid=113341564807057516149&resourcekey=0-5aLX6SGixIG6-gt1o8HynA&rtpof=true&sd=true";
export const states = [
	{ label: "AL-Alabama", value: "Alabama" },
	{ label: "AK-Alaska", value: "Alaska" },
	{ label: "AZ-Arizona", value: "Arizona" },
	{ label: "AR-Arkansas", value: "Arkansas" },
	{ label: "CA-California", value: "California" },
	{ label: "CO-Colorado", value: "Colorado" },
	{ label: "CT-Connecticut", value: "Connecticut" },
	{ label: "DE-Delaware", value: "Delaware" },
	{ label: "DC-District of Columbia", value: "District of Columbia" },
	{ label: "FL-Florida", value: "Florida" },
	{ label: "GA-Georgia", value: "Georgia" },
	{ label: "HI-Hawaii", value: "Hawaii" },
	{ label: "ID-Idaho", value: "Idaho" },
	{ label: "IL-Illinois", value: "Illinois" },
	{ label: "IN-Indiana", value: "Indiana" },
	{ label: "IA-Iowa", value: "Iowa" },
	{ label: "KS-Kansas", value: "Kansas" },
	{ label: "KY-Kentucky", value: "Kentucky" },
	{ label: "LA-Louisiana", value: "Louisiana" },
	{ label: "ME-Maine", value: "Maine" },
	{ label: "MD-Maryland", value: "Maryland" },
	{ label: "MA-Massachusetts", value: "Massachusetts" },
	{ label: "MI-Michigan", value: "Michigan" },
	{ label: "MN-Minnesota", value: "Minnesota" },
	{ label: "MS-Mississippi", value: "Mississippi" },
	{ label: "MO-Missouri", value: "Missouri" },
	{ label: "MT-Montana", value: "Montana" },
	{ label: "NE-Nebraska", value: "Nebraska" },
	{ label: "NV-Nevada", value: "Nevada" },
	{ label: "NH-New Hampshire", value: "New Hampshire" },
	{ label: "NJ-New Jersey", value: "New Jersey" },
	{ label: "NM-New Mexico", value: "New Mexico" },
	{ label: "NY-New York", value: "New York" },
	{ label: "NC-North Carolina", value: "North Carolina" },
	{ label: "ND-North Dakota", value: "North Dakota" },
	{ label: "OH-Ohio", value: "Ohio" },
	{ label: "OK-Oklahoma", value: "Oklahoma" },
	{ label: "OR-Oregon", value: "Oregon" },
	{ label: "PA-Pennsylvania", value: "Pennsylvania" },
	{ label: "PR-Puerto Rico", value: "Puerto Rico" },
	{ label: "RI-Rhode Island", value: "Rhode Island" },
	{ label: "SC-South Carolina", value: "South Carolina" },
	{ label: "SD-South Dakota", value: "South Dakota" },
	{ label: "TN-Tennessee", value: "Tennessee" },
	{ label: "TX-Texas", value: "Texas" },
	{ label: "UT-Utah", value: "Utah" },
	{ label: "VT-Vermont", value: "Vermont" },
	{ label: "VA-Virginia", value: "Virginia" },
	{ label: "WA-Washington", value: "Washington" },
	{ label: "WV-West Virginia", value: "West Virginia" },
	{ label: "WI-Wisconsin", value: "Wisconsin" },
	{ label: "WY-Wyoming", value: "Wyoming" },
];

export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const fields = [
	{ label: "Name", value: "name", color: "" },
	{ label: "Category", value: "measure_category_name", color: "" },
	{ label: "Program", value: "program", color: "" },
	{ label: "Labor Cost", value: "labor_cost", color: "" },
	{ label: "Material Cost", value: "material_cost", color: "" },
	{ label: "ECM", value: "ecm", color: "" },
	{ label: "Units", value: "units", color: "" },
	{ label: "Type", value: "type", color: "" },
	{ label: "Dimension", value: "dimension", color: "darkgreen" },
	{ label: "Units", value: "dimension_units", color: "lightgreen" },
	{ label: "Description", value: "dimension_description", color: "lightgreen" },
	{ label: "Fuel Type", value: "fuel_type", color: "" },
	{ label: "Life Time", value: "life_time", color: "" },
	{ label: "Tax Status", value: "tax_status", color: "" },
	{ label: "Notes", value: "notes", color: "" },
	{ label: "Property A", value: "property_a", color: "darkgreen" },
	{ label: "Value", value: "property_a_value", color: "lightgreen" },
	{ label: "Units", value: "property_a_units", color: "lightgreen" },
	{ label: "Property B", value: "property_b", color: "darkgreen" },
	{ label: "Value", value: "property_b_value", color: "lightgreen" },
	{ label: "Units", value: "property_b_units", color: "lightgreen" },
	{ label: "Property C", value: "property_c_label", color: "darkgreen" },
	{ label: "Value", value: "property_c_value", color: "lightgreen" },
	{ label: "Units", value: "property_c_units", color: "lightgreen" },
	{ label: "Property D", value: "property_d_label", color: "darkgreen" },
	{ label: "Value", value: "property_d_value", color: "lightgreen" },
	{ label: "Units", value: "property_d_units", color: "lightgreen" },
	{ label: "User Item", value: "user_item", color: "" },
];
export const measureAdminFields = [
	{ label: "IMP", value: "imp" },
	{ label: "Lookup", value: "lookup" },
	{ label: "Additional Item Location", value: "additional_item_location" },
	{ label: "Sub Filter", value: "sub_filter" },
	{ label: "Suppress From Total", value: "supress_from_total" },
	{ label: "Funding Category", value: "funding_category" },
	{ label: "List Order", value: "listorder" },
	{ label: "Default Value", value: "default_value" },
	{ label: "Source", value: "source" },
	{ label: "Default Quantity", value: "default_qty" },
	{ label: "Pre Symbol", value: "pre_symbol" },
	{ label: "Post Symbol", value: "post_symbol" },
];
export const COOKIE_MAIN_DOMAIN = process.env.REACT_APP_COOKIE_MAIN_DOMAIN;
export const COOKIE_CONFIG_OPTIONS = { path: "/", domain: COOKIE_MAIN_DOMAIN, secure: process.env.REACT_APP_COOKIE_SECURE };
export const ZEUS_URL = process.env.REACT_APP_ZEUS_URL;
export const COMPANY_STANDARD_PROGRAM = "Standard";
export const COMPANY_USAGE_TAB = 0;
export const COMPANY_INVOICES_TAB = 1;
export const COMPANY_PRICING_TAB = 2;
export const COMPANY_USERS_TAB = 3;
export const COMPANY_PROFILE_TAB = 4;
export const COMPANY_PROGRAMS_TAB = 5;
export const COMPANY_MEASURES_TAB = 6;
export const COMPANY_STAGES_TAB = 7;
export const ONBOARDING_STEPS = {
	COMPANY_PROFILE: 0,
	SELECT_PLAN: 1,
	INVITE_TEAM: 2,
	TEST_DRIVE: 3,
};
export const UPPERCASE_REGEX = /[A-Z]/;
export const LOWERCASE_REGEX = /[a-z]/;
export const NUMBER_REGEX = /\d/;
export const SPECIAL_CHAR_REGEX = /[^a-zA-Z0-9]/;
export const PHONE_REGEX = /^[0-9+()\- ]*$/;
export const ZIP_REGEX = /^[0-9]*$/;
export const ZEUS_SESSION_COOKIE_NAME = process.env.REACT_APP_ZEUS_SESSION_COOKIE_NAME;
export const SESSION_TOKEN_COOKIE_NAME = "session_token";
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const ROLE_DESCRIPTION_LINK = "https://docs.google.com/spreadsheets/d/1L8U_RNGWmw38W28Tm13JAxW-XxeEZvhbaw99ZU5Xo-8/preview?gid=148052522#gid=148052522";

export const FILE_TYPES = {
	IMAGE: {
		JPEG: "image/jpeg",
		PNG: "image/png"
	},
	// We can add more types later like:
	// DOCUMENT: {
	//   PDF: "application/pdf",
	//   DOC: "application/msword"
	// }
};

export const PLAN_FIVE = 'Plan 5';
export const CUSTOMIZED_COLOR = '#ffd28073';
export const INHERITED_COLOR = '#FFFFE0';
export const DISABLED_COLOR = '#f6f6f6';
