import React, { useEffect, useState } from "react";
import "../CostingStyle.css";
import CostingTableView from "./CostingTableView";
import { getMeasuresApi, updateMeasureFieldApi, useGetCompanyPrograms, useGetMeasureCategories, useGetMeasureRows } from "services/CostingService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../../../redux/reducers/LoaderSlice";
import { COMPANY_STANDARD_PROGRAM, fields } from "utils/constants/Constants";
import { setItemsBorder } from "utils/helpers/Common";

const CostingTable = ({ companyId, programId, type, showBackBtn = true, userRole = null }) => {
	const [query, setQuery] = useState("");
	const [currentPage, setCurrentPage] = useState(0);
	const [lastPage, setLastPage] = useState(0);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedProgram, setSelectedProgram] = useState(null);
	const [lineItems, setLineItems] = useState([]);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const headers = type === "program" ? fields.filter((val) => val.value !== "program") : fields;
	const { categories } = useGetMeasureCategories();
	const { programs } = useGetCompanyPrograms(companyId);
	const { measures, companyName, programName, pagination } = useGetMeasureRows(companyId, programId, query, selectedCategory, selectedProgram, false, false);

	useEffect(() => {
		type == "company"  ? setLineItems(setItemsBorder(measures)) : setLineItems(measures);
		setCurrentPage(pagination?.current_page);
		setLastPage(pagination?.total_pages);
	}, [measures]);

	const handleBack = () => {
		navigate(`/setting/company/${companyId}`);
	};
	const handleChange = (value, fieldId, header, lineItemIndex) => {
		const clonedLineItems = [...lineItems];
		const companyProgramId = clonedLineItems[lineItemIndex]["program_id"];
		if (programId) {
			if (header == "material_cost" && clonedLineItems[lineItemIndex].material_cost !== value) {
				clonedLineItems[lineItemIndex]["material_cost"] = value;
				clonedLineItems[lineItemIndex]["is_material_cost_customized"] = !!value;
				clonedLineItems[lineItemIndex]["is_material_cost_inherited"] = false;
			}
			if (header == "labor_cost" && clonedLineItems[lineItemIndex].labor_cost !== value) {
				clonedLineItems[lineItemIndex]["labor_cost"] = value;
				clonedLineItems[lineItemIndex]["is_labor_cost_customized"] = !!value;
				clonedLineItems[lineItemIndex]["is_labor_cost_inherited"] = false;
			}
		}
		if (companyProgramId) {
			const companyValue = clonedLineItems.find((val) => val.id == fieldId && val.is_company_measure);
			if (header == "material_cost" && clonedLineItems[lineItemIndex]["material_cost"] !== value) {
				clonedLineItems[lineItemIndex]["material_cost"] = value ? value : companyValue.material_cost;
				clonedLineItems[lineItemIndex]["is_material_cost_customized"] = !!value;
				clonedLineItems[lineItemIndex]["is_material_cost_inherited"] = !value && companyValue.material_cost && companyValue.is_material_cost_customized ? true : false;
			}
			if (header == "labor_cost" && clonedLineItems[lineItemIndex]["labor_cost"] !== value) {
				clonedLineItems[lineItemIndex]["labor_cost"] = value ? value : companyValue.labor_cost;
				clonedLineItems[lineItemIndex]["is_labor_cost_customized"] = !!value;
				clonedLineItems[lineItemIndex]["is_labor_cost_inherited"] = !value && companyValue.labor_cost && companyValue.is_labor_cost_customized ? true : false;
			}
		}
		if (type == "company" || type == 'company_program' && !companyProgramId) {
			const stockValue = clonedLineItems.find((val) => val.id == fieldId && val.is_stock_measure);
			clonedLineItems
				.filter((clonedLineItem) => clonedLineItem.id == fieldId && !clonedLineItem.is_stock_measure)
				.map((item, index, arr) => {
					if (header == "material_cost" && item.material_cost !== value) {
						item["material_cost"] = item.is_material_cost_customized && !item.is_material_cost_inherited && index !== 0 ? item.material_cost : !value ? stockValue.material_cost : value;
						item["is_material_cost_customized"] = index !== 0 ? item.is_material_cost_customized : !!value;
					}
					if (header == "labor_cost" && item.labor_cost !== value) {
						item["labor_cost"] = item.is_labor_cost_customized && !item.is_labor_cost_inherited && index !== 0 ? item.labor_cost : !value ? stockValue.labor_cost : value;
						item["is_labor_cost_customized"] = index !== 0 ? item.is_labor_cost_customized : !!value;
					}
					if (item["program_id"] && header == "material_cost" && arr[0].is_material_cost_customized) {
						item["is_material_cost_inherited"] = item.is_material_cost_customized && !item.is_material_cost_inherited ? false : true;
					} else if (item["program_id"] && header == "material_cost" && !arr[0].is_material_cost_customized) {
						if (item["is_material_cost_inherited"]) {
							item["is_material_cost_customized"] = false;
						}
						item["is_material_cost_inherited"] = false;
					}
					if (item["program_id"] && header == "labor_cost" && arr[0].is_labor_cost_customized) {
						item["is_labor_cost_inherited"] = item.is_labor_cost_customized && !item.is_labor_cost_inherited ? false : true;
					} else if (item["program_id"] && header == "labor_cost" && !arr[0].is_labor_cost_customized) {
						if (item["is_labor_cost_inherited"]) {
							item["is_labor_cost_customized"] = false;
						}
						item["is_labor_cost_inherited"] = false;
					}
				});
		}
		updateMeasureFieldApi(companyId, {
			company_program_id: companyProgramId || programId,
			company_id: companyId,
			labor_cost: header == "labor_cost" ? value : clonedLineItems[lineItemIndex].labor_cost,
			material_cost: header == "material_cost" ? value : clonedLineItems[lineItemIndex].material_cost,
			measure_id: fieldId,
			update_type: companyProgramId ? "program" : type,
			program_id: programId,
		}).then((res) => {
			if (type == "program") {
				const companyValue = res.data?.data?.companyValue;
				const measureValue = res.data?.data?.measureValue;
				if (companyValue?.material_cost && header == "material_cost") {
					clonedLineItems[lineItemIndex]["material_cost"] = companyValue?.material_cost;
					clonedLineItems[lineItemIndex]["is_material_cost_inherited"] = true;
				}
				if (!companyValue?.material_cost && measureValue && !value && header == "material_cost") {
					clonedLineItems[lineItemIndex]["material_cost"] = measureValue?.material_cost;
				}

				if (companyValue?.labor_cost && header == "labor_cost") {
					clonedLineItems[lineItemIndex].labor_cost = companyValue?.labor_cost;
					clonedLineItems[lineItemIndex]["is_labor_cost_inherited"] = true;
				}

				if (!companyValue?.labor_cost && measureValue && !value && header == "labor_cost") {
					clonedLineItems[lineItemIndex]["labor_cost"] = measureValue?.labor_cost;
				}
			}
			setLineItems(clonedLineItems);
		});
	};

	const handleLoadMore = () => {
		const params = {
			company_id: companyId,
			company_program_id: selectedProgram || programId,
			measure_name: query,
			measure_category_id: selectedCategory,
			page: currentPage + 1,
			view: programId ? "program" : "company",
		};
		dispatch(showLoader());
		getMeasuresApi(params)
			.then((res) => {
				const items = type == "company" || type == 'company_program' ? setItemsBorder(res.data.data.measures) : res.data.data.measures;
				setLineItems((prev) => [...prev, ...items]);
				setCurrentPage(res.data.data.pagination.current_page);
			})
			.catch((err) => {})
			.finally(() => {
				dispatch(hideLoader());
			});
	};
	return (
		<CostingTableView
			lineItems={lineItems}
			handleBack={handleBack}
			companyName={companyName}
			programName={programName}
			handleChange={handleChange}
			query={query}
			setQuery={setQuery}
			categories={categories}
			selectedCategory={selectedCategory}
			setSelectedCategory={setSelectedCategory}
			handleLoadMore={handleLoadMore}
			currentPage={currentPage}
			lastPage={lastPage}
			type={type}
			headers={headers}
			programs={programs?.filter((program) => program.name !== COMPANY_STANDARD_PROGRAM)}
			setSelectedProgram={setSelectedProgram}
			selectedProgram={selectedProgram}
			showBackBtn={showBackBtn}
			userRole={userRole}
		/>
	);
};

export default CostingTable;
