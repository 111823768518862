import React from "react";
import CompanyProgram from "./CompanyProgram";
import { useParams } from "react-router-dom";
import CostingTable from "./table/CostingTable";

const Costing = ({ company }) => {
  const { id, type, programId } = useParams();
  return (
    <>
      {type == "company" || type == "program" ? (
        <CostingTable companyId={id} programId={programId} type={type}/>
      ) : (
        <CompanyProgram company={company} />
      )}
    </>
  );
};

export default Costing;
