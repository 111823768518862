import React, { useState } from "react";
import ForgotPasswordView from "./ForgotPasswordView";
import { useForgotPasswordForm } from "services/AuthService";

const ForgotPassword = ({ show, handleClose }) => {
  const [showAlert, setShowAlert] = useState(false);
  const { register, handleSubmit, errors } =
    useForgotPasswordForm(setShowAlert);
  return (
    <ForgotPasswordView
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      show={show}
      handleClose={handleClose}
      showAlert={showAlert}
      setShowAlert={setShowAlert}
    />
  );
};

export default ForgotPassword;
