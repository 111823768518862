import React from "react";
import PbackIcon from "assets/images/icons/back-icon.svg";
import "../StageStyle.css";
import AddStage from "../add/AddStage";
import TableInput from "../table/TableInput";
import InfoColorBox from "components/infoColorBox/InfoColorBox";
import TableCheckbox from "./TableCheckbox";
import TableSelect from "./TableSelect";
import { useCheckCompanyPermission } from "utils/helpers/Common";
import { EDIT_COMPANY_MEASURES_STAGES } from "utils/constants/UserPermissions";
const StageTableView = ({ title, stages, handleBack, programId, companyId, type, handleChange, stagesMutate, hpxmlEventTypes }) => {
	const hasCompanyPermission = useCheckCompanyPermission([EDIT_COMPANY_MEASURES_STAGES]);
	return (
		<div className="pageCard">
			<InfoColorBox />
			<div className="tblHeaderArea">
				<div className="tblLeftClm">
					<div className="flexTitleBox">
						<img src={PbackIcon} className="backIcon" onClick={handleBack} />
						<div className="TitleBox">
							<h4>{title}</h4>
							<p>Manage {type} stages</p>
						</div>
					</div>
				</div>
			</div>
			<div className={`tableScrollArea stageTableArea ${type == "program" ? "cellMaxWd" : ""}`}>
				<table className="tableArea">
					<thead>
						<tr>
							{type == "company" && (
								<th className="tblTh">
									<p className="tblText"></p>
								</th>
							)}
							<th className="tblTh">
								<p className="tblText">Name</p>
							</th>
							<th className="tblTh">
								<p className="tblText">HPXML Event Type</p>
							</th>
							<th className="tblTh">
								<p className="tblText">Include</p>
							</th>
						</tr>
					</thead>
					<div className="tableSpace"></div>
					<tbody>
						{stages?.map((stage, index) => (
							<tr
								key={"stage" + index}
								className={`${
									stage["border_color"] == "lightgreen" && stage["border_top"]
										? "bdrClrLightGreen bdrTopLightGreen"
										: stage["border_color"] == "lightgreen" && stage["border_bottom"]
										? "bdrClrLightGreen bdrBottomLightGreen"
										: stage["border_color"] == "lightgreen"
										? "bdrClrLightGreen"
										: stage["border_color"] == "darkgreen" && stage["border_top"]
										? "bdrClrDarkGreen bdrTopDarkGreen"
										: stage["border_color"] == "darkgreen" && stage["border_bottom"]
										? "bdrClrDarkGreen bdrBottomDarkGreen"
										: stage["border_color"] == "darkgreen"
										? "bdrClrDarkGreen"
										: ""
								}`}
							>
								{type == "company" && (
									<td className="tblTd">
										<p className="tblText">{`${
											stage?.is_stock_stage
												? "Stock Value"
												: stage.program_name
												? stage.program_name
												: !stage.is_stock_associated && !stage.is_stock_stage
												? "Custom Stage"
												: "Company Value"
										}`}</p>
									</td>
								)}

								<td className="tblTd">
									<TableInput
										fieldId={stage.id}
										stage={stage.name}
										isCustomized={stage.is_name_customized}
										isInherited={stage.is_name_inherited}
										isReadOnly={stage?.is_stock_stage ? true : type ==="company" && !stage?.program_id && !hasCompanyPermission ? true : false}
										handleChange={(value, stageIndex) => handleChange(value, stageIndex, "name")}
										stageIndex={index}
										isStockValue={stage?.is_stock_value}
									/>
								</td>
								<td className="tblTd">
									<TableSelect
										options={hpxmlEventTypes?.map((event) => ({ label: event.hpxml_event_type, value: event.id }))}
										value={stage.hpxml_event_type_id}
										isCustomized={stage.is_hpxml_event_type_customized}
										isInherited={stage.is_hpxml_event_type_inherited}
										onChange={(value) => handleChange(value.value, index, "hpxml_event_type_id")}
										isDisabled={stage?.is_stock_stage ? true : type ==="company" && !stage?.program_id && !hasCompanyPermission ? true : false}
									/>
								</td>
								<td className="tblTd">
									<TableCheckbox
										fieldId={stage.id}
										stage={stage.include}
										isCustomized={stage.is_include_customized}
										isInherited={stage.is_include_inherited}
										isReadOnly={stage?.is_stock_stage ? true : type ==="company" && !stage?.program_id && !hasCompanyPermission ? true : false}
										handleChange={(value, stageIndex) => handleChange(value, stageIndex, "include")}
										stageIndex={index}
										isStockValue={stage?.is_stock_value}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default StageTableView;
