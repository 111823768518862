import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import errorIcon from "assets/images/icons/mdl-error-icon.svg";
import "./ConfirmationAlertStyles.css";

const ConfirmationAlert = ({
  show,
  handleClose,
  title,
  description,
  handleConfirm,
  cancelBtnText,
  confirmBtnText,
}) => {
  return (
    <div>
      <Modal show={show} onHide={handleClose} className="frmModalArea smModal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modalCardBox">
            <img src={errorIcon} className="mdlErrorIcon" />
            <h5>{title}</h5>
            <p className="mb-5">{description}</p>
            <div className="d-flex align-items-center justify-content-end gap-4">
              <Button
                className="bdrBtn mrLauto cancelBtn"
                onClick={handleClose}
              >
                {cancelBtnText}
              </Button>
              <Button
                className="fillPrimarBtn"
                type="button"
                onClick={handleConfirm}
              >
                {confirmBtnText}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConfirmationAlert;
