import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useMatch, useSearchParams, createSearchParams } from "react-router-dom";
import "../styles/global.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/reducers/AuthSlice";
import { logoutApi } from "services/ProfileService";
import Sidebar from "./sidebar/Sidebar";
const Layout = () => {
	const [hideSidebar, setHideSidebar] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	const isJoinCompanyUrl = useMatch("/setting/join-company");

	useEffect(() => {
		if (!user && isJoinCompanyUrl) {
			const params = {};
			for (let [key, value] of searchParams.entries()) {
				params[key] = value;
			}
			const nextUrl = {
				pathname: "/setting/join-company",
				search: createSearchParams(params).toString(),
			};
			navigate("/?signin=true", { state: { nextUrl: nextUrl } });
		}
	});

	useEffect(() => {
		if (searchParams.get("fromiframe") === "yes") {
			setHideSidebar(true);
		}
	}, [searchParams]);

	const handleLogout = async () => {
		await logoutApi();
		dispatch(logout());
		navigate("/");
	};
	return (
		<>
			{user && (
				<div className="mainSection bgLight">
					{!hideSidebar && <Sidebar handleLogout={handleLogout} />}
					<Outlet />
				</div>
			)}
		</>
	);
};

export default Layout;
