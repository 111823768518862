import React, { useState, useEffect } from "react";
import InviteUserView from "./InviteUserView";
import { inviteUserApi, useGetRoles } from "services/CompanyService";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../../../redux/reducers/LoaderSlice";
import { filterCompanyPrograms, handleApiResponseError } from "utils/helpers/Common";
import { useNavigate } from "react-router-dom";
import { COMPANY_MANAGER } from "utils/constants/UserRoles";
import { getProgramRoleOptions } from "utils/helpers/Company";
import { COMPANY_STANDARD_PROGRAM } from "utils/constants/Constants";
const InviteUser = ({ company, useModal = true, saveBtnText = "Send Invite", cancelBtnText = "Cancel", callback = () => {}, onSkip, onCancel, invitedUsersMutate, programId = null, userPrograms, hasCompanyPermission }) => {
	const [formValues, setFormValues] = useState({
		company_id: null,
		email: "",
		role: "",
		programs: [{ program_id: "", role_id: "" }],
	});
	const [showPrograms, setShowPrograms] = useState(true);
	const [showRoleDescription, setShowRoleDescription] = useState(false);
	const [programRoleOptions, setProgramRoleOptions] = useState([]);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	const [errors, setErrors] = useState({});
	const { roles } = useGetRoles();
	const [companyPrograms, setCompanyPrograms] = useState([]);

	useEffect(() => {
		if (roles?.length > 0) {
			setProgramRoleOptions(roles.filter((role) => role.type === "program"));
		}
		if (company) {
			setFormValues({ ...formValues, company_id: company?.id });
		}
	}, [roles, company]);

	useEffect(() => {
		if (company) setCompanyPrograms(filterCompanyPrograms({ company: company, programId: programId }));
	}, [company, programId]);

	const handleAdd = () => {
		setFormValues({
			...formValues,
			programs: [...formValues.programs, { program_id: "", role_id: "" }],
		});
	};
	const handleRemoveProgram = (index) => {
		const newPrograms = formValues.programs.filter((_, i) => i !== index);
		setFormValues({ ...formValues, programs: newPrograms });
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
		if (name === "role") {
			const role = roles?.find((role) => role.id == value);
			if (role?.name === COMPANY_MANAGER) {
				setShowPrograms(false);
			} else {
				setShowPrograms(true);
				const options = getProgramRoleOptions(role?.name, roles);
				setProgramRoleOptions(options);
			}
		}
	};

	const handleProgramChange = (index, e) => {
		const { name, value } = e.target;
		const program = formValues.programs.find((item) => item.program_id == value && name !== "role_id");
		if (!program) {
			const newPrograms = formValues.programs.map((program, i) => (i === index ? { ...program, [name]: value } : program));
			setFormValues({ ...formValues, programs: newPrograms });
		}
	};

	const validate = () => {
		let formErrors = {};
		if (!formValues.email) {
			formErrors.email = "Email is required";
		} else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
			formErrors.email = "Invalid email format";
		} else if (formValues.email === user.email) {
			formErrors.email = "You cannot invite yourself";
		}
		if (company?.programs?.filter((program) => program.name !== COMPANY_STANDARD_PROGRAM)?.length > 0) {
			formValues.programs.forEach((program, index) => {
				if (!formValues.role && !program.program_id && index === 0) {
					formErrors[`program_id-${index}`] = "Program is required";
				}
				if (program.program_id && !program.role_id) {
					formErrors[`role_id-${index}`] = "Role is required";
				}
			});
		}

		return formErrors;
	};

	const handleSubmit = (callback) => {
		const formErrors = validate();
		if (Object.keys(formErrors).length === 0) {
			formValues.programs = formValues.programs.filter((program) => program.program_id && program.role_id);
			if (!formValues.role && formValues.programs.length === 0) {
				toast.error("You're trying to invite a user with company role None. Please assign at least one program to the user.");
				return;
			}
			dispatch(showLoader());
			inviteUserApi(formValues)
				.then(() => {
					callback && callback();
					toast.success("User invited successfully");
					invitedUsersMutate();
				})
				.catch((err) => {
					handleApiResponseError("", err.response, navigate, "/setting/company");
				})

				.finally(() => {
					dispatch(hideLoader());
				});
		} else {
			setErrors(formErrors);
		}
	};

	return (
		<InviteUserView
			company={company}
			errors={errors}
			roles={roles}
			handleAdd={handleAdd}
			handleProgramSelect={handleProgramChange}
			handleChange={handleChange}
			formValues={formValues}
			handleSubmit={handleSubmit}
			setFormValues={setFormValues}
			setErrors={setErrors}
			showPrograms={showPrograms}
			setShowPrograms={setShowPrograms}
			programRoleOptions={programRoleOptions}
			programs={hasCompanyPermission ? companyPrograms : companyPrograms.filter((program) => userPrograms.some((userProgram) => userProgram.id === program.id))}
			useModal={useModal}
			saveBtnText={saveBtnText}
			cancelBtnText={cancelBtnText}
			callback={callback}
			onSkip={onSkip}
			onCancel={onCancel}
			handleRemoveProgram={handleRemoveProgram}
			showRoleDescription={showRoleDescription}
			setShowRoleDescription={setShowRoleDescription}
			programId={programId}
			hasCompanyPermission={hasCompanyPermission}
		/>
	);
};

export default InviteUser;
