import React from "react";
import { Link } from "react-router-dom";
import Header from "layouts/header/Header";
import SettingSidebar from "pages/setting/sidebar/SettingSidebar";
import "./CompanyListStyle.css";
import AddCompany from "../add/AddCompany";
import { AUDITOR, COMPANY_MANAGER, CONTRACTOR, GUEST } from "utils/constants/UserRoles";
import AllCompaniesList from "./AllCompaniesList";
import { useCheckHasInternalRole, checkProgramsPermissions } from "utils/helpers/Common";
import { useSelector } from "react-redux";
import { EDIT_COMPANY_MEASURES_STAGES, INVITE_MANAGE_USERS } from "utils/constants/UserPermissions";
const CompanyListView = ({
	roles,
	isInternalUser,
	showCreateModal,
	setShowCreateModal,
	companiesMutate,
	navigate,
	noRoleCompanies,
	setSelectedCompany,
	onboardingStatus,
	handleCompleteOnboarding,
}) => {
	const user = useSelector((store) => store.auth?.user);
	return (
		<>
			<div className="wrapperArea">
				<Header title={user?.impersonated ? `Account Management (Impersonated as ${user?.first_name} ${user?.last_name})` : "Account Management"} />
				<div className="wrapperContainer">
					<SettingSidebar isSuperAdmin={isInternalUser} onboardingStatus={onboardingStatus} handleCompleteOnboarding={handleCompleteOnboarding} />
					<div className="wrappePageArea wrappePd">
						<div className="pageCard">
							<h4 className="h4MdTitle mb-1">Your Companies</h4>
							<p className="mdText mb-3">Manage your company including branding, billing & personnel</p>
							{roles?.map((data, index) => (
								<>
									{data?.role?.name === COMPANY_MANAGER && (
										<>
											<h4 className="h4MdTitle mb-1">Your are Company Manager for these Companies</h4>
											<p className="smText mb-3">You can manage contact information, billing, and users, as well as create, view, and edit projects for these companies.</p>
											<div className="panelList">
												{data?.companies?.map((company, index) => (
													<Link
														to={
															data?.can_view_details ? `/setting/company/${company.id}` : ``
														}
														key={"company" + index}
														className="text-decoration-none"
													>
														<div className="panelBar">
															<p className="cmpNameTag">{company?.name}</p>
														</div>
													</Link>
												))}
											</div>
										</>
									)}

									{data?.role?.name === AUDITOR && (
										<>
											<h5 className="h4MdTitle mb-1">You are Auditor for these Companies</h5>
											<p className="smText mb-2">
												You can create, view and edit projects for all users in these Companies, but you cannot manage their contact info, billing details, or users.
											</p>
											<div className="panelList">
												{data?.companies?.map((company, index) => (
													<Link
														to={
															data?.can_view_details ? `/setting/company/${company.id}` : ``
														}
														key={"company" + index}
														className={`text-decoration-none ${!data?.can_view_details ? "cursorDefault" : ""}`}
													>
														<div className="panelBar">
															<p className="cmpNameTag">{company?.name}</p>
														</div>
													</Link>
												))}
											</div>
										</>
									)}

									{data?.role?.name === CONTRACTOR && (
										<>
											<h5 className="h4MdTitle mb-1">You are Contractor for these Companies</h5>
											<p className="smText mb-2">You can create projects for these Companies.</p>
											<div className="panelList">
												{data?.companies?.map((company, index) => (
													<Link
														to={
															data?.can_view_details ? `/setting/company/${company.id}` : ``
														}
														key={"company" + index}
														className={`text-decoration-none ${!data?.can_view_details ? "cursorDefault" : ""}`}
													>
														<div className="panelBar">
															<p className="cmpNameTag">{company?.name}</p>
														</div>
													</Link>
												))}
											</div>
										</>
									)}

									{data?.role?.name === GUEST && (
										<>
											<h5 className="h4MdTitle mb-1">You are Guest for these Companies</h5>
											<p className="smText mb-2">You can view projects for these Companies.</p>
											<div className="panelList">
												{data?.companies?.map((company, index) => (
													<Link
														to={
															data?.can_view_details ? `/setting/company/${company.id}` : ``
														}
														key={"company" + index}
														className={`text-decoration-none ${!data?.can_view_details ? "cursorDefault" : ""}`}
													>
														<div className="panelBar">
															<p className="cmpNameTag">{company?.name}</p>
														</div>
													</Link>
												))}
											</div>
										</>
									)}
								</>
							))}
							{noRoleCompanies?.length > 0 && (
								<>
									<h5 className="h4MdTitle mb-1">You have access to programs in the following companies</h5>
									<p className="smText mb-2">You can view programs for these Companies.</p>
									<div className="panelList">
										{noRoleCompanies?.map((company, index) => (
											<>
												{checkProgramsPermissions(company?.programs, [INVITE_MANAGE_USERS, EDIT_COMPANY_MEASURES_STAGES]) ? (
													<Link className="viewDLink" to={`/setting/company/${company.id}`}>
														<div className="panelBar" key={"no-role-company" + index}>
															<p className="cmpNameTag">{company?.name}</p>
														</div>
													</Link>
												) : (
													<div className="panelBar" key={"no-role-company" + index}>
														<p className="cmpNameTag">{company?.name}</p>

														<Link className="viewDLink" onClick={() => setSelectedCompany({ companyId: company.id, companyName: company.name, action: "programs" })}>
															View Programs
														</Link>
													</div>
												)}
											</>
										))}
									</div>
								</>
							)}
							{useCheckHasInternalRole() && <AllCompaniesList navigate={navigate} />}
							<div className="contentCardBox mt-3">
								<h6 className="mdH6Text mb-2">Need another company?</h6>
								<p className="smText">
									Creating a new company is free. However, pricing plans are exclusive to each company and cannot be shared. Larger companies use the
									<br />
									“create a new company” feature to set up different divisions they would like the manage independently.{" "}
								</p>
								<button className="cpCreateBtn mt-3" onClick={() => setShowCreateModal(true)}>
									Create a new company
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showCreateModal && <AddCompany show={showCreateModal} handleClose={() => setShowCreateModal(false)} companiesMutate={companiesMutate} />}
		</>
	);
};

export default CompanyListView;
