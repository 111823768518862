import React, { useState, useEffect } from "react";
import EditProfileView from "./EditProfileView";
import { useEditProfileForm } from "services/ProfileService";

const EditProfile = ({ user, userMutate, isEditAble = false, saveBtnText = "Save", cancelBtnText = "Cancel", showCancelBtn = true, onSaveCallback = () => {} }) => {
	const [editAble, setEditAble] = useState(false);
	const [authUser, setAuthUser] = useState(null);
	const { register, handleSubmit, errors, handleFileUpload, Controller, control, isDirty } = useEditProfileForm(authUser, userMutate);
	useEffect(() => {
		setAuthUser(user);
		setEditAble(isEditAble);
	}, [user, isEditAble]);

	const _setEditAble = () => {
		if (isEditAble) {
			setEditAble(true);
		} else {
			setEditAble(!editAble);
		}
	};

	return (
		<EditProfileView
			register={register}
			handleSubmit={handleSubmit}
			errors={errors}
			editAble={editAble}
			setEditAble={_setEditAble}
			user={authUser}
			handleFileUpload={handleFileUpload}
			Controller={Controller}
			control={control}
			saveBtnText={saveBtnText}
			cancelBtnText={cancelBtnText}
			onSaveCallback={onSaveCallback}
			showCancelBtn={showCancelBtn}
			isDirty={isDirty}
		/>
	);
};

export default EditProfile;
