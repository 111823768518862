import * as yup from "yup";
import { UPPERCASE_REGEX, LOWERCASE_REGEX, NUMBER_REGEX, SPECIAL_CHAR_REGEX, PHONE_REGEX, ZIP_REGEX } from "../constants/Constants";
export const UpdateProfileSchema = yup.object().shape({
	first_name: yup.string().required("First Name is required"),
	last_name: yup.string().required("Last Name is required"),
	email: yup.string().email("Email must be a valid email").required("Email is required"),
	phone_number: yup.string().matches(PHONE_REGEX, "Phone Number is invalid"),
});

export const ChangePasswordSchema = yup.object().shape({
	current_password: yup.string().required("Current Password is required"),
	new_password: yup
		.string()
		.required("New Password is required")
		.test("password-complexity", (value, { createError, path }) => {
			const uppercaseValid = UPPERCASE_REGEX.test(value);
			const lowercaseValid = LOWERCASE_REGEX.test(value);
			const numberValid = NUMBER_REGEX.test(value);
			const specialCharValid = SPECIAL_CHAR_REGEX.test(value);

			if (!uppercaseValid || !lowercaseValid || !numberValid || !specialCharValid) {
				return createError({
					message: "Password is invalid",
					path: "new_password",
				});
			}

			return true;
		})
		.min(8, "Password must be at least 8 characters"),
	new_password_confirmation: yup
		.string()
		.oneOf([yup.ref("new_password"), null], "New Password must match")
		.required("Confirm New Password is required"),
});
