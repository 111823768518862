import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import PaymentIcon from "assets/images/icons/payment-icon.svg";
import ToggleIcon from "assets/images/icons/toggle-icon.svg";
import CheckIcon from "assets/images/icons/check-circle-icon.svg";
import { getCardBrandIcon } from "utils/helpers/Common";

const ListCard = ({ cards, handleDefaultCard, handleDeleteCard }) => {
  return (
    <>
      <div className="listCardRow">
        {cards?.map((card, cIndex) => (
          <div className="payCard" key={"card" + cIndex}>
            <div className={`paymentCard ${card.is_default ? "active" : ""}`}>
              <img src={CheckIcon} className="CheckIcon" />
              <div className="cartImgBox">
                <img
                  src={getCardBrandIcon(card.brand)}
                  className="paymentIcon"
                />
              </div>
              <div class="cartInfoBar">
                <p>**** **** **** {card.last4}</p>
                <div className="cartInfoTextBar">
                  <p>{card.name}</p>
                  {card.is_default && <span>Default</span>}
                </div>
              </div>
              {!card.is_default &&  (
                <Dropdown className="toggleDropdown">
                  <Dropdown.Toggle class="toggleBtn">
                    <img src={ToggleIcon} class="toggleIcon" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleDefaultCard(card.id)}>
                      Make Default
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeleteCard(card.id)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ListCard;
