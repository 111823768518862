import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { getInitialsFromName, styleSelectProfile } from "utils/helpers/Common";
import Select from "react-select";
import UploadProfileImage from "components/upload/uploadProfileImage/UploadProfileImage";
import { COMPANY_STANDARD_PROGRAM, states } from "utils/constants/Constants";
import FieldLabel from "components/form/label/FieldLabel";
import { useCheckPermissions } from "utils/helpers/Common";
import { EDIT_COMPANIES } from "utils/constants/UserPermissions";

const EditCompanyProfileView = ({
	register,
	handleFileUpload,
	handleSubmit,
	company,
	errors,
	editable,
	setEditable,
	Controller,
	control,
	reset,
	handleProgramSelect,
	handleProgramRemove,
	programs,
	selectedPrograms,
	canEdit,
	saveBtnText,
	cancelBtnText,
	onSaveCallback,
	onCancelCallback,
	showCancelBtn,
	isDirty,
	hideDeprecated,
	setHideDeprecated,
}) => {
	const hasEditCompanyPermission = useCheckPermissions([EDIT_COMPANIES]);
	return (
		<div className="pageCard">
			<div className="pCardHead">
				<p className="smText mb-2">Company Logo</p>
				<div className="uploadImgBox">
					<div className="userimgBox">
						{company?.logo ? <img src={company?.logo} className="companyImg" alt="" /> : <span className="userdefaultView">{getInitialsFromName(company?.name)}</span>}
						{editable && hasEditCompanyPermission && <UploadProfileImage onSave={handleFileUpload} callback={() => setEditable(false)} title={"Upload Company Logo"} />}
					</div>
					<div className="uplaodinfo">
						<h3>{company?.name}</h3>
					</div>
				</div>
				{!editable && canEdit && (
					<button className="frmEditBtn" onClick={() => setEditable(true)}>
						Edit
					</button>
				)}
			</div>
			<div className="pCardbody">
				<Form className="formArea">
					<p className="frmSmtext"></p>
					<Row>
						<Col md="6">
							<Form.Group className="mb-3" controlId="name">
								<FieldLabel label="Company Name" required />
								<Form.Control type="text" placeholder="Company Name" className="inputField bdr2" {...register("name")} readOnly={!editable} />
								{errors.name && <p className="text-danger">{errors.name.message}</p>}
							</Form.Group>
						</Col>
						<Col md="6">
							<Form.Group className="mb-3" controlId="admin_email">
								<FieldLabel label="Admin Email" />
								<Form.Control type="email" placeholder="Admin Email" className="inputField bdr2" readOnly {...register("admin_email")} />
							</Form.Group>
						</Col>
						<Col md="6">
							<Form.Group className="mb-3" controlId="office_phone">
								<FieldLabel label="Phone Number" />
								<Form.Control type="text" placeholder="Phone Number" className="inputField bdr2" {...register("office_phone")} readOnly={!editable} />
								{errors.office_phone && <p className="text-danger">{errors.office_phone.message}</p>}
							</Form.Group>
						</Col>
						<Col md="6">
							<Form.Group className="mb-3" controlId="website">
								<FieldLabel label="Website" />
								<Form.Control type="text" placeholder="Website" className="inputField bdr2" {...register("website")} readOnly={!editable} />
								{errors.website && <p className="text-danger">{errors.website.message}</p>}
							</Form.Group>
						</Col>
					</Row>
					{saveBtnText === "Continue" && programs?.length > 0 && (
						<>
							<Col md="6">
								<Form.Group controlId="programs">
									<FieldLabel label="Programs" />
									{editable && (
										<>
											<Select
												options={programs?.map((program) => ({
													label: program.name,
													value: program.id,
													is_trashed: program.is_trashed,
													company_id: program.company_id,
												}))}
												onChange={handleProgramSelect}
												placeholder="Select"
												value={""}
												styles={styleSelectProfile}
												className="programSelect"
											/>
											{errors.programs && <p className="text-danger">{errors.programs.message}</p>}
										</>
									)}
								</Form.Group>
							</Col>
							<Col md="12">
								<Form.Check label="Hide Deprecated" checked={hideDeprecated} onChange={() => setHideDeprecated(!hideDeprecated)} />
								<div className="multiSelectTagsBox color2">
									{selectedPrograms.map((program, pIndex) => (
										<div className="multiSelectItem color2">
											<span>{program.label}</span>
											{editable && !program.company_id && (
												<button className="crossBtn" type="button" onClick={() => handleProgramRemove(pIndex)}>
													<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M1 9L9 1M1 1L9 9" stroke="#0082FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
													</svg>
												</button>
											)}
										</div>
									))}
								</div>
							</Col>
						</>
					)}

					<p className="frmSmtext">Address Detail</p>
					<Row>
						<Col md="12">
							<Form.Group className="mb-3" controlId="address">
								<FieldLabel label="Address" />
								<Form.Control type="text" placeholder="Address" className="inputField bdr2" {...register("address")} readOnly={!editable} />
								{errors.address && <p className="text-danger">{errors.address.message}</p>}
							</Form.Group>
						</Col>
						<Col md="6">
							<Form.Group className="mb-3" controlId="city">
								<FieldLabel label="City" />
								<Form.Control type="text" placeholder="City" className="inputField bdr2" {...register("city")} readOnly={!editable} />
								{errors.city && <p className="text-danger">{errors.city.message}</p>}
							</Form.Group>
						</Col>
						<Col md="6">
							<Form.Group className="mb-3" controlId="state">
								<FieldLabel label="State" required />
								<Controller
									name="state"
									control={control}
									render={({ field: { onChange, value } }) => (
										<Select options={states} onChange={onChange} placeholder="Select" value={value} styles={styleSelectProfile} isDisabled={!editable} className="stateSelect" />
									)}
								/>

								{errors.state?.label && <p className="text-danger">{errors.state.label.message}</p>}
							</Form.Group>
						</Col>
						<Col md="6">
							<Form.Group className="mb-3" controlId="zip_code">
								<FieldLabel label="Zip" />
								<Form.Control type="text" placeholder="Zip" className="inputField bdr2" {...register("zip_code")} readOnly={!editable} />
								{errors.zip_code && <p className="text-danger">{errors.zip_code.message}</p>}
							</Form.Group>
						</Col>
					</Row>
					{editable && (
						<Row>
							<Col md="12 d-flex align-items-center justify-content-end gap-4 footerBtnBar">
								{showCancelBtn && (
									<button
										className="bdrBtn mrLauto cancelBtn"
										type="button"
										onClick={() => {
											setEditable(false);
											reset();
											onCancelCallback && onCancelCallback();
										}}
									>
										{cancelBtnText}
									</button>
								)}
								{hasEditCompanyPermission && (isDirty || saveBtnText === "Continue") && (
									<button
										className="fillPrimarBtn "
										type="button"
										onClick={() =>
											handleSubmit(() => {
												setEditable(false);
												onSaveCallback && onSaveCallback();
											})
										}
									>
										{saveBtnText}
									</button>
								)}
							</Col>
						</Row>
					)}
				</Form>
			</div>
		</div>
	);
};

export default EditCompanyProfileView;
