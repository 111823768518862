import React, { useState, useEffect } from "react";
import CompanyOnboardingView from "./CompanyOnboardingView";
import { useDispatch, useSelector } from "react-redux";
import { updateCompanyOnboardingStatusApi, updateCompanyOnboardingStepApi, useGetOnboardingCompany } from "services/CompanyService";
import { useSearchParams, useNavigate } from "react-router-dom";
import SuccessAlert from "components/alert/success/SuccessAlert";
import { ONBOARDING_STEPS, ZEUS_URL } from "utils/constants/Constants";
import { setOnboarding, setOnboardingBackUrl } from "../../redux/reducers/companySlice";
import { useGetCompanyUserRole } from "services/CompanyService";
import { setUserRole } from "../../redux/reducers/AuthSlice";
const CompanyOnboarding = () => {
	const [step, setStep] = useState(0);
	const [successAlert, setSuccessAlert] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { onboarding, onboardingBackUrl } = useSelector((state) => state.company);
	const { company, companyMutate } = useGetOnboardingCompany();
	const { user } = useSelector((state) => state.auth);
	const { companyRole } = useGetCompanyUserRole(company?.id, user.id);
	useEffect(() => {
		if (searchParams.get("pricing_plan") && searchParams.get("success") == 1) {
			setStep(ONBOARDING_STEPS.SELECT_PLAN);
		} else if (searchParams.get("pricing_plan") == 1 && searchParams.get("success") == 0) {
			setStep(ONBOARDING_STEPS.SELECT_PLAN);
			setSearchParams([]);
		} else if (searchParams.get("step")) {
			setStep(parseInt(searchParams.get("step")));
			setSearchParams([]);
		}
	}, [searchParams]);

	useEffect(() => {
		dispatch(setUserRole(companyRole));
	}, [companyRole]);

	const handleContinue = () => {
		setStep(step + 1);
	};

	const handleStepBack = () => {
		setStep(step - 1);
	};

	const handleCompleteProfile = () => {
		dispatch(setOnboarding(true));
		setStep(ONBOARDING_STEPS.COMPANY_PROFILE);
	};

	const handleSkipOnboarding = () => {
		window.location.href = ZEUS_URL;
	};

	const handleBack = async () => {
		await updateCompanyOnboardingStepApi(company?.id, { step });
		setStep(0);
		dispatch(setOnboarding(false));
		if (onboardingBackUrl) {
			const url = onboardingBackUrl;
			dispatch(setOnboardingBackUrl(null));
			navigate(url);
		}
	};

	const handleStart = async () => {
		await updateCompanyOnboardingStatusApi(company?.id, { status: 1 });
		dispatch(setOnboarding(false));
		window.location.href = ZEUS_URL;
	};
	return (
		<>
			<CompanyOnboardingView
				company={company}
				handleContinue={handleContinue}
				step={step}
				companyMutate={companyMutate}
				handleStepBack={handleStepBack}
				searchParams={searchParams}
				setSearchParams={setSearchParams}
				setSuccessAlert={setSuccessAlert}
				onboarding={onboarding}
				handleCompleteProfile={handleCompleteProfile}
				handleSkipOnboarding={handleSkipOnboarding}
				handleStart={handleStart}
				handleBack={handleBack}
			/>
			{successAlert && <SuccessAlert show={successAlert} handleClose={() => setSuccessAlert(false)} title={"Success"} description={"Plan Subscribed Successfully"} />}
		</>
	);
};

export default CompanyOnboarding;
