import React from "react";
import Select from "react-select";
import { styleSelectProfile } from "utils/helpers/Common";

const ProgramSelect = ({ programs, onChange, placeholder, value = "", isDisabled = false }) => {
	return (
		<Select
			options={programs?.map((program) => ({
				label: program.name,
				value: program.id,
				is_trashed: program.is_trashed,
				company_id: program.company_id,
			}))}
			onChange={onChange}
			placeholder={placeholder}
			value={value}
			styles={styleSelectProfile}
			className="programSelect"
			isDisabled={isDisabled}
		/>
	);
};

export default ProgramSelect;
