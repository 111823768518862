import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import tickIcon from "assets/images/icons/blue-tick-mark.svg";
import "./SuccessAlertStyles.css";
const SuccessAlert = ({ show, handleClose, title, description }) => {
  return (
    <div>
      <Modal show={show} onHide={handleClose} className="frmModalArea smModal notificationModal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modalCardBox">
            <img src={tickIcon} className="mdlErrorIcon" />
            <h5>{title}</h5>
            <p className="mb-5 titleText">{description}</p>
            <div className="d-flex align-items-center justify-content-end gap-4 doneBtn">
              <Button
                className="fillPrimarBtn"
                type="button"
                onClick={handleClose}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SuccessAlert;
