import React, { useState } from "react";
import InvoiceListView from "./InvoiceListView";
import {
  downloadInvoiceApi,
  useGetCompanyInvoices,
} from "services/CompanyService";
import { useDispatch } from "react-redux";
import fileDownload from "js-file-download";
import {
  hideLoader,
  showLoader,
} from "../../../../../redux/reducers/LoaderSlice";
import { toast } from "react-toastify";

const InvoiceList = ({ company }) => {
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const dispatch = useDispatch();
  const { data } = useGetCompanyInvoices(company);

  const handleDownload = (invoice) => {
    dispatch(showLoader());
    downloadInvoiceApi(company.id, { invoice_id: invoice.id })
      .then((res) => {
        fileDownload(res.data, `${invoice.invoice_number}.pdf`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => dispatch(hideLoader()));
  };
  return (
    <InvoiceListView
      data={data}
      handleDownload={handleDownload}
      itemsPerPage={10}
      selectedInvoice={selectedInvoice}
      setSelectedInvoice={setSelectedInvoice}
      company={company}
    />
  );
};

export default InvoiceList;
