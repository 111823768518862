import React from "react";
import Button from "react-bootstrap/Button";
import ProgramFormModal from "components/program/ProgramFormModal";
import ImgEditIcon from "assets/images/icons/imgEditIcon.svg";

const EditProgramView = ({
    programName,
    setProgramName,
    handleSubmit,
    show,
    handleShow,
    handleClose,
    file,
    onFileChange,
    deleteFile,
    companyProgramLogo
}) => {
   
    const triggerButton = (
        <button className="crossBtn" type="button" onClick={handleShow}>
            <img src={ImgEditIcon} alt="restore" />
        </button>
    );

    return (
        <ProgramFormModal
            programName={programName}
            setProgramName={setProgramName}
            handleSubmit={handleSubmit}
            show={show}
            handleClose={handleClose}
            file={file}
            onFileChange={onFileChange}
            deleteFile={deleteFile}
            isEdit={true}
            triggerButton={triggerButton}
            imageUrl={companyProgramLogo}
        />
    );
};

export default EditProgramView; 