import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "../ProfileStyle.css";
import { getInitialsFromName } from "utils/helpers/Common";
import UploadProfileImage from "components/upload/uploadProfileImage/UploadProfileImage";
import FieldLabel from "components/form/label/FieldLabel";
const EditProfileView = ({ register, handleSubmit, errors, editAble, setEditAble, user, handleFileUpload, Controller, control, saveBtnText, cancelBtnText, onSaveCallback, showCancelBtn, isDirty }) => {
	return (
		<div className="pageCard">
			<div className="pCardHead">
				<p className="smText mb-2">Your OptiMiser Profile</p>
				<div className="uploadImgBox">
					<div className="userimgBox">
						{user?.profile_image ? (
							<img src={user?.profile_image} className="uploadImg" />
						) : (
							<span className="userdefaultView">{getInitialsFromName(user?.first_name + " " + user?.last_name)}</span>
						)}
						{editAble && <UploadProfileImage onSave={handleFileUpload} callback={() => setEditAble(false)} />}
					</div>
					{user && (
						<div className="uplaodinfo">
							<h3>{user?.first_name + " " + user?.last_name}</h3>
							<p>{user?.email}</p>
						</div>
					)}
				</div>
				{!editAble && (
					<button className="frmEditBtn" onClick={() => setEditAble(true)}>
						Edit
					</button>
				)}
			</div>
			<div className="pCardbody">
				<Form className="formArea">
					<p className="frmSmtext">Personal Detail</p>
					<Row>
						<Col md="6">
							<Form.Group className="mb-3" controlId="first_name">
								<FieldLabel label="First Name" required />
								<Form.Control type="text" placeholder="First Name" className="inputField bdr2" readOnly={!editAble} {...register("first_name")} />
								{errors.first_name && <p className="text-danger">{errors.first_name.message}</p>}
							</Form.Group>
						</Col>
						<Col md="6">
							<Form.Group className="mb-3" controlId="last_name">
								<FieldLabel label="Last Name" required />
								<Form.Control type="text" placeholder="Last Name" className="inputField bdr2" readOnly={!editAble} {...register("last_name")} />
								{errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
							</Form.Group>
						</Col>
						<Col md="6">
							<Form.Group className="mb-3" controlId="email">
								<FieldLabel label="Email" required />
								<Form.Control type="email" placeholder="Email" className="inputField bdr2" readOnly {...register("email")} />
								{errors.email && <p className="text-danger">{errors.email.message}</p>}
							</Form.Group>
						</Col>
						<Col md="6">
							<Form.Group className="mb-3" controlId="phone_number">
								<FieldLabel label="Phone Number" />
								<Form.Control type="text" placeholder="Phone Number" className="inputField bdr2" readOnly={!editAble} {...register("phone_number")} />
								{errors.phone_number && <p className="text-danger">{errors.phone_number.message}</p>}
							</Form.Group>
						</Col>
					</Row>
					{editAble && (
						<Row>
							<Col md="12 d-flex align-items-center justify-content-end gap-4 footerBtnBar">
								{showCancelBtn && (
									<button
										className="bdrBtn mrLauto cancelBtn"
										type="button"
										onClick={() => {
											setEditAble(false);
										}}
									>
										{cancelBtnText}
									</button>
								)}
								{isDirty && (
								<button
									className="fillPrimarBtn "
									type="button"
									onClick={() =>
										handleSubmit(() => {
											onSaveCallback();
											setEditAble(false);
										})
									}
								>
									{saveBtnText}
									</button>
								)}
							</Col>
						</Row>
					)}
				</Form>
			</div>
		</div>
	);
};

export default EditProfileView;
