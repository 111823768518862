import React, { useState } from "react";
import ResetPasswordView from "./ResetPasswordView";
import { useResetPasswordForm } from "services/AuthService";

const ResetPassword = ({ show, handleClose, email, token }) => {
  const [password, setPassword] = useState(null);
  const { register, handleSubmit, errors } = useResetPasswordForm(email, token);
  return (
    <ResetPasswordView
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      show={show}
      handleClose={handleClose}
      password={password}
      setPassword={setPassword}
    />
  );
};

export default ResetPassword;
