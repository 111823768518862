import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import errorIcon from "assets/images/icons/cancel-plan-modal-icon.svg";
import "./ConfirmationAlertStyles.css";
import { formatDateToCustomString } from "utils/helpers/Common";
const CancelSubscriptionAlert = ({
  show,
  handleClose,
  handleConfirm,
  plan,
}) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        className="frmModalArea smModal mdModalArea"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modalCardBox">
            <img src={errorIcon} className="mdlErrorIcon" />
            <h5>Unsubscribe</h5>
            <p className="mb-3">
              Once you unsubscribe, your current plan will remain active until
              your billing cycle ends on
              {` ${
                plan?.ends_at ? formatDateToCustomString(plan.ends_at) : ""
              }`}
              . After that date you will still be able to access your account
              and to work on any existing projects
            </p>
            <p className="mb-1">Please let us know why you’re canceling.</p>
            <textarea className="mdlTextArea" placeholder="Text"></textarea>
            <p className="mb-5">
              We are sorry to see you go, and we hope to be able to work with
              you again in the future. You can re-subscribe at any time.
            </p>
            <div className="d-flex align-items-center justify-content-end gap-4 rtBtnsFlex">
              <Button
                className="bdrBtn mrLauto cancelBtn"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="fillPrimarBtn"
                type="button"
                onClick={handleConfirm}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CancelSubscriptionAlert;
