import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../redux/reducers/LoaderSlice";
import { successLogin } from "../redux/reducers/AuthSlice";
import { getImpersonationUserApi } from "services/AuthService";
import { toast } from "react-toastify";
import { removeCookie, getCookie } from "utils/helpers/Common";

export const useImpersonation = () => {
	const [searchParams] = useSearchParams();
	const impersonationToken = searchParams.get("impersonation_token");
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (impersonationToken) {
			if(getCookie('session_token')) removeCookie('session_token');

			dispatch(showLoader());

			getImpersonationUserApi(impersonationToken)
				.then((res) => {
					dispatch(successLogin(res.data.data));
					navigate("/setting/company", { replace: true });
				})
				.catch((err) => {
					toast.error(err.response?.data?.message || "Something went wrong");
				})
				.finally(() => {
					dispatch(hideLoader());
				});
		}
	}, [impersonationToken]);
};
