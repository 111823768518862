import React, { useState, useEffect } from "react";
import { deleteCompanyProgramApi, restoreCompanyProgramApi, assignCompanyProgramApi, unassignCompanyProgramApi, useGetCompanyCustomPrograms } from "services/ProgramService";
import ListCompanyProgramView from "./ListCompanyProgramView";
import ConfirmationAlert from "components/alert/confirmation/ConfirmationAlert";
import { useGetPrograms } from "services/ProgramService";
import { COMPANY_STANDARD_PROGRAM } from "utils/constants/Constants";
import { useGetCompanyPrograms } from "services/CostingService";

const ListCompanyProgram = ({ company, companyMutate }) => {
	const [customPrograms, setCustomPrograms] = useState([]);
	const [selectedExternalPrograms, setSelectedExternalPrograms] = useState([]);
	const [selectedProgram, setSelectedProgram] = useState({ action: null, id: null });
	const { customPrograms: companyPrograms, programsMutate: companyProgramsMutate } = useGetCompanyCustomPrograms(company.id, true);
	const { programs } = useGetPrograms();
	const { programs: assignedPrograms } = useGetCompanyPrograms(company.id);

	useEffect(() => {
		if (companyPrograms) {
			setCustomPrograms(companyPrograms.map((program) => ({ id: program.id, label: program.name, value: program.id, is_trashed: program.is_trashed, company_id: program.company_id, logo: program.logo })));
		}
	}, [companyPrograms]);

	useEffect(() => {
		setSelectedExternalPrograms(
			assignedPrograms
				?.filter((program) => program.company_id === null && program.is_active && program.name !== COMPANY_STANDARD_PROGRAM)
				.map((program) => ({ id: program.id, label: program.name, value: program.id, is_trashed: program.is_trashed, company_id: program.company_id, logo: program.logo }))
		);
	}, [assignedPrograms]);

	const handleSelectProgram = async (program) => {
		setSelectedExternalPrograms([...selectedExternalPrograms, program]);
		await assignCompanyProgramApi({ company_id: company.id, program_id: program.value });
	};

	const unassignProgram = async () => {
		setSelectedExternalPrograms(selectedExternalPrograms.filter((p) => p.value !== selectedProgram.id));
		await unassignCompanyProgramApi(selectedProgram.id, { company_id: company.id });
		setSelectedProgram({ action: null, id: null });
	};

	const handleDeleteProgram = async () => {
		await deleteCompanyProgramApi(selectedProgram.id, company.id);
		companyProgramsMutate();
		setSelectedProgram({ action: null, id: null });
	};

	const restoreProgram = async () => {
		await restoreCompanyProgramApi(selectedProgram.id, company.id);
		companyProgramsMutate();
		setSelectedProgram({ action: null, id: null });
	};

	const handleRestoreProgramClick = (program) => {
		setSelectedProgram({ action: "restore", id: program.value });
	};

	const handleRemoveProgramClick = (program) => {
		if (program.company_id === null) {
			setSelectedProgram({ action: "unassign", id: program.value });
		} else {
			setSelectedProgram({ action: "delete", id: program.value });
		}
	};

	return (
		<>
			<ListCompanyProgramView
				customPrograms={customPrograms}
				externalPrograms={programs}
				companyId={company?.id}
				companyName={company?.name}
				handleSelectProgram={handleSelectProgram}
				companyMutate={companyMutate}
				companyProgramsMutate={companyProgramsMutate}
				selectedExternalPrograms={selectedExternalPrograms}
				handleRemoveProgramClick={handleRemoveProgramClick}
				handleRestoreProgramClick={handleRestoreProgramClick}
			/>
			<ConfirmationAlert
				show={selectedProgram.id}
				title={selectedProgram.action === "delete" ? "Deprecate Program" : selectedProgram.action === "unassign" ? "Unassign Program" : "Restore Program"}
				description={
					selectedProgram.action === "delete"
						? "Are you sure you want to deprecate this program?"
						: selectedProgram.action === "unassign"
						? "You will still be able to view and search projects that have this program assigned. Are you sure you want to unassign this program?"
						: "Are you sure you want to restore this program?"
				}
				confirmBtnText={selectedProgram.action === "delete" ? "Deprecate" : selectedProgram.action === "unassign" ? "Unassign" : "Restore"}
				cancelBtnText={"Cancel"}
				handleClose={() => setSelectedProgram({ action: null, id: null })}
				handleConfirm={selectedProgram.action === "delete" ? handleDeleteProgram : selectedProgram.action === "unassign" ? unassignProgram : restoreProgram}
			/>
		</>
	);
};

export default ListCompanyProgram;
