import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CompanyIcon from "assets/images/icons/congratulation-icon.svg";
import checkCircle from "assets/images/icons/circle-box-check-icon.svg";
import "./JoinCompanyAlertStyle.css";
const JoinCompanyAlert = ({ show, handleClose, companyName, roleName }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="congratulationModal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalBody">
        <div className="cModalContentBox">
          <img src={CompanyIcon} className="mb-3" />

          <h3>Congratulations</h3>
          <p className="pText mb-5">
            You have successfully accepted the invitation from {companyName}{roleName !== 'none' ? ` as a ${roleName}` : ''}. We are excited to have you join our team.
          </p>
        </div>

        <div className="modalFtGrpBtn">
          <Button onClick={handleClose} className="fillBtn primerybtn">
            Check Your Companies
          </Button>
          <Button onClick={handleClose} className="bdrBtn">
            Done
          </Button>
        </div>

        <img src={checkCircle} className="checkCircleBg" />
      </Modal.Body>
    </Modal>
  );
};

export default JoinCompanyAlert;
