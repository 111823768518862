import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "./ChangePasswordStyles.css";
import { checkStringPattern } from "utils/helpers/Common";
import PasswordRule from "components/passwordRule/PasswordRule";
import FieldLabel from "components/form/label/FieldLabel";
const ChangePasswordView = ({
  show,
  handleShow,
  handleClose,
  register,
  handleSubmit,
  errors,
  getValues,
  getFieldState,
  password,
  setPassword,
}) => {
  return (
    <div className="modalArea">
      <Button onClick={handleShow} className="strokeBtn">
        Change your password
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        className="frmModalArea"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h5 className="h5Title mb-3">Change Password</h5>
          <p className="smText mdlTextMaxWd mb-4">
            Updating your password regularly is good security practice and helps
            prevent unauthorized access to your account.
          </p>

          <Form className="formArea">
            <div className="mobVtScroll">
              <Row>
                <Col md="12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <FieldLabel label="Current Password" required />
                    <Form.Control
                      type="password"
                      placeholder="Current Password"
                      className="inputField bdr2"
                      {...register("current_password")}
                    />
                    {errors.current_password && (
                      <p className="text-danger">
                        {errors.current_password.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <FieldLabel label="New OptiMiser Password" required />
                    <Form.Control
                      type="password"
                      placeholder="New Password"
                      className="inputField bdr2"
                      {...register("new_password", {
                        onChange: (e) => {
                          setPassword(e.target.value);
                        },
                      })}
                    />
                    {errors.new_password && (
                      <p className="text-danger">
                        {errors.new_password.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <FieldLabel label="Re-enter New OptiMiser Password to confirm" required />
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      className="inputField bdr2"
                      {...register("new_password_confirmation")}
                    />
                    {errors.new_password_confirmation && (
                      <p className="text-danger">
                        {errors.new_password_confirmation.message}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <PasswordRule password={password} />
              </Row>
              <Row>
                <Col md="12">
                  <svg
                    width="100%"
                    height="2"
                    viewBox="0 0 100% 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="-3.05176e-05"
                      y1="1"
                      x2="100%"
                      y2="1"
                      stroke="#CFD4D9"
                      stroke-width="2"
                      stroke-dasharray="4 4"
                    />
                  </svg>
                </Col>
              </Row>
            </div>

            <Row>
              <Col md="12">
                <button
                  className="fillPrimarBtn mrLauto mt-4"
                  type="button"
                  onClick={() => handleSubmit(handleClose)}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChangePasswordView;
