import React from "react";
import TabsList from "components/tabs/TabsList";
import Header from "layouts/header/Header";
import SettingSidebar from "pages/setting/sidebar/SettingSidebar";
import "../../company/detail/CompanyDetailStyle.css";
import BackArrow from "assets/images/icons/back-arrow-icon.svg";
import SuccessAlert from "components/alert/success/SuccessAlert";
import { useCheckCompanyRole, useCheckPermissions } from "utils/helpers/Common";
import { COMPANY_MEASURES_TAB, COMPANY_USERS_TAB } from "utils/constants/Constants";
import CompanyUserList from "pages/setting/company/user/list/CompanyUserList";
import CostingTable from "pages/setting/company/costing/table/CostingTable";
import { useSelector } from "react-redux";
import { INVITE_MANAGE_USERS, EDIT_COMPANY_MEASURES_STAGES } from "utils/constants/UserPermissions";

const ProgramAdminDetailView = ({ company, handleBackButton, activeTab, setActiveTab, successAlert, setSuccessAlert, isInternalUser, user, programId, program }) => {
	const userRole = useSelector((store) => store.auth?.user?.role);
	const tabsData = [];
	const usersTab = {
		title: "Users",
		eventKey: COMPANY_USERS_TAB,
		body: activeTab == COMPANY_USERS_TAB && <CompanyUserList company={company} authUser={user} programId={programId} />,
	};
	const costingTab = {
		title: "Measures",
		eventKey: COMPANY_MEASURES_TAB,
		body: activeTab == COMPANY_MEASURES_TAB && <CostingTable companyId={company?.id} type="company_program" programId={programId} showBackBtn={false} userRole={userRole} />,
	};

	if (useCheckPermissions(INVITE_MANAGE_USERS)) {
		tabsData.push(usersTab);
	}

	if (useCheckPermissions(EDIT_COMPANY_MEASURES_STAGES)) {
		tabsData.push(costingTab);
	}

	return (
		<>
			<div className="wrapperArea">
				<Header title={user?.impersonated ? `Account Management (Impersonated as ${user?.first_name} ${user?.last_name})` : "Account Management"} />
				<div className="wrapperContainer">
					<SettingSidebar isSuperAdmin={isInternalUser} />
					<div className="wrappePageArea wrappePd">
						<div className="pageArea ">
							<div className="pageHead d-flex align-items-center gap-3 mb-4">
								<img src={BackArrow} className="backArrow" onClick={handleBackButton} />
								<div>
									<h4 className="pageTitle">
										{company?.name} ({program?.name})
									</h4>
									<p className="pgText">Profile for Company Account </p>
								</div>
							</div>
							<div>
								<TabsList listData={tabsData} activeIndex={activeTab} onChange={setActiveTab} />
							</div>
						</div>
					</div>
				</div>
			</div>
			{successAlert && <SuccessAlert show={successAlert} handleClose={() => setSuccessAlert(false)} title={"Success"} description={"Plan Subscribed Successfully"} />}
		</>
	);
};

export default ProgramAdminDetailView;
