import React from "react";
import { Link } from "react-router-dom";
import { useGetAllCompaniesList } from "services/CompanyService";
const AllCompaniesList = ({ navigate }) => {
	const { companies } = useGetAllCompaniesList();
	return (
		<>
			<h4 className="h4MdTitle mb-1">All Companies</h4>
			<p className="smText mb-3">You can manage contact information, billing, and users, as well as create, view, and edit projects for these companies.</p>
			<div className="panelList">
				{companies?.map((company, index) => (
					<Link className="viewDLink" to={`/setting/company/${company.id}`}>
						<div className="panelBar" key={"company" + index}>
							<p
								className="cmpNameTag"
								onClick={() => {
									navigate(`/setting/company/${company.id}`);
								}}
							>
								{company?.name}
							</p>
						</div>
					</Link>
				))}
			</div>
		</>
	);
};

export default AllCompaniesList;
