import { createSlice } from "@reduxjs/toolkit";
import { SESSION_TOKEN_COOKIE_NAME, ZEUS_SESSION_COOKIE_NAME } from "utils/constants/Constants";
import { removeCookie, setCookie } from "utils/helpers/Common";

const initialState = {
	user: null,
	error: null,
	loading: false,
	nextUrl: null,
};

const AuthSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		loginRequest: (state, action) => {
			state.loading = true;
		},
		successLogin: (state, action) => {
			state.user = action.payload;
			setCookie(SESSION_TOKEN_COOKIE_NAME, action.payload.token);
			state.loading = false;
		},
		failLogin: (state, action) => {
			state.error = action.payload;
			state.loading = false;
		},
		logout: (state, action) => {
			state.user = null;
			removeCookie(SESSION_TOKEN_COOKIE_NAME);
			removeCookie(ZEUS_SESSION_COOKIE_NAME);
			localStorage.clear();
		},
		clearErrors: (state) => {
			state.error = null;
		},
		authUser: (state, action) => {
			state.user = action.payload;
		},
		setUserRole: (state, action) => {
			state.user.role = action.payload;
		},
		setUserPrograms: (state, action) => {
			state.user.programs = action.payload;
		},
		setNextUrl: (state, action) => {
			state.nextUrl = action.payload;
		},
	},
});
export const { loginRequest, successLogin, failLogin, logout, clearErrors, authUser, setUserRole, setNextUrl, setUserPrograms } = AuthSlice.actions;
export default AuthSlice.reducer;
