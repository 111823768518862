import React from "react";
import "./InfoColorBoxStyle.css";
const InfoColorBox = () => {

	return (
		<div className="d-flex gap-2 justify-content-end">
			<div className="d-flex gap-2 align-items-center">
				<div className="infoColorBoxItem" style={{ backgroundColor: "#fff" }}></div>
				<span className="infoColorBoxText">Stock</span>
			</div>
			<div className="d-flex gap-2 align-items-center">
				<div className="infoColorBoxItem" style={{ backgroundColor: "#ffd28073" }}></div>
				<span className="infoColorBoxText">Custom</span>
			</div>
			<div className="d-flex gap-2 align-items-center">
				<div className="infoColorBoxItem" style={{ backgroundColor: "#FFFFE0" }}></div>
				<span className="infoColorBoxText">Inherited</span>
			</div>
		</div>
	);
};

export default InfoColorBox;
