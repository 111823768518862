import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCookie } from "utils/helpers/Common";
import { showLoader, hideLoader } from "../redux/reducers/LoaderSlice";
import { getAuthUserApi } from "services/AuthService";
import { authUser } from "../redux/reducers/AuthSlice";
import AuthNavigator from "./AuthNavigator";
import Layout from "layouts/Layout";
import SettingNavigator from "./SettingNavigator";
import CompanyOnboarding from "pages/onboarding/CompanyOnboarding";
const RootNavigator = () => {
	const { user } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		if (getCookie("session_token")) {
			const nextUrl = location.pathname === "/" || location.pathname === "/reset-password" || location.pathname === "/join-company" ? "/setting/company" : location.pathname + location.search;
			if (!user) {
				dispatch(showLoader());
				getAuthUserApi()
					.then((res) => {
						dispatch(authUser(res?.data?.data));
					})
					.finally(() => dispatch(hideLoader()));
			}
			navigate(nextUrl);
		}
	}, []);
	return (
		<Routes>
			<Route path="/*" element={<AuthNavigator />} />
			<Route exact path="/onboarding" element={<CompanyOnboarding />} />
			<Route element={<Layout />}>
				<Route exact path="/setting/*" element={<SettingNavigator />} />
			</Route>
		</Routes>
	);
};

export default RootNavigator;
