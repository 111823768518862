import React, { useEffect } from "react";
import TabsList from "components/tabs/TabsList";
import Header from "layouts/header/Header";
import SettingSidebar from "pages/setting/sidebar/SettingSidebar";
import EditCompanyProfile from "./edit/EditCompanyProfile";
import "./CompanyDetailStyle.css";
import CompanyUserList from "../user/list/CompanyUserList";
import BackArrow from "assets/images/icons/back-arrow-icon.svg";
import PricingPlan from "../pricingPlan/PricingPlan";
import SuccessAlert from "components/alert/success/SuccessAlert";
import InvoiceList from "../invoice/list/InvoiceList";
import CurrentUsage from "../currentUsage/CurrentUsage";
import Costing from "../costing/Costing";
import Stage from "../stage/Stage";
import { useCheckPermissions } from "utils/helpers/Common";
import ListCompanyProgram from "../program/ListCompanyProgram";
import {
	COMPANY_INVOICES_TAB,
	COMPANY_MEASURES_TAB,
	COMPANY_PRICING_TAB,
	COMPANY_PROFILE_TAB,
	COMPANY_PROGRAMS_TAB,
	COMPANY_STAGES_TAB,
	COMPANY_USAGE_TAB,
	COMPANY_USERS_TAB,
} from "utils/constants/Constants";
import { EDIT_COMPANIES, INVITE_MANAGE_USERS, EDIT_PROGRAMS, EDIT_COMPANY_MEASURES_STAGES } from "utils/constants/UserPermissions";
const CompanyDetailView = ({ company, companyMutate, handleBackButton, activeTab, setActiveTab, successAlert, setSuccessAlert, searchParams, setSearchParams, isInternalUser, user }) => {
	const { special_features = {} } = company || {};
	const { stages = false, programs = false } = special_features || {};

	const showUsageGraph = programs && company?.programs?.length > 0;
	const tabsData = [];
	const usageTab = {
		title: "Usage",
		eventKey: COMPANY_USAGE_TAB,
		body: activeTab == COMPANY_USAGE_TAB && <CurrentUsage company={company} showUsageGraph={showUsageGraph} />,
	};
	const invoiceTab = {
		title: "Invoices",
		eventKey: COMPANY_INVOICES_TAB,
		body: activeTab == COMPANY_INVOICES_TAB && <InvoiceList company={company} />,
	};
	const pricingPlanTab = {
		title: "Pricing",
		eventKey: COMPANY_PRICING_TAB,
		body: activeTab == COMPANY_PRICING_TAB && <PricingPlan company={company} searchParams={searchParams} setSearchParams={setSearchParams} setSuccessAlert={setSuccessAlert} />,
	};
	const usersTab = {
		title: "Users",
		eventKey: COMPANY_USERS_TAB,
		body: activeTab == COMPANY_USERS_TAB && <CompanyUserList company={company} authUser={user} />,
	};
	const companyProfileTab = {
		title: "Company",
		eventKey: COMPANY_PROFILE_TAB,
		body: activeTab == COMPANY_PROFILE_TAB && <EditCompanyProfile company={company} companyMutate={companyMutate} isEditAble={true} showCancelBtn={false} />,
	};
	const programTab = {
		title: "Programs",
		eventKey: COMPANY_PROGRAMS_TAB,
		body: activeTab == COMPANY_PROGRAMS_TAB && <ListCompanyProgram company={company} companyMutate={companyMutate} />,
	};
	const costingTab = {
		title: "Measures",
		eventKey: COMPANY_MEASURES_TAB,
		body: activeTab == COMPANY_MEASURES_TAB && <Costing company={company} />,
	};
	const stageTab = {
		title: "Stages",
		eventKey: COMPANY_STAGES_TAB,
		body: activeTab == COMPANY_STAGES_TAB && <Stage company={company} />,
	};

	if (useCheckPermissions([EDIT_COMPANIES])) {
		tabsData.push(usageTab);
		tabsData.push(invoiceTab);
		tabsData.push(pricingPlanTab);
	}
	if (useCheckPermissions([INVITE_MANAGE_USERS])) {
		tabsData.push(usersTab);
	}
	if (useCheckPermissions([EDIT_COMPANIES])) {
		tabsData.push(companyProfileTab);
	}
	if (useCheckPermissions([EDIT_PROGRAMS])) {
		programs && tabsData.push(programTab);
	}
	if (useCheckPermissions([EDIT_COMPANY_MEASURES_STAGES])) {
		tabsData.push(costingTab);
		stages && tabsData.push(stageTab);
	}
	useEffect(() => {
		if (tabsData.length > 0) {
			setActiveTab(tabsData[0].eventKey);
		}
	}, [user]);

	return (
		<>
			<div className="wrapperArea">
				<Header title={user?.impersonated ? `Account Management (Impersonated as ${user?.first_name} ${user?.last_name})` : "Account Management"} />
				<div className="wrapperContainer">
					<SettingSidebar isSuperAdmin={isInternalUser} />
					<div className="wrappePageArea wrappePd">
						<div className="pageArea ">
							<div className="pageHead d-flex align-items-center gap-3 mb-4">
								<img src={BackArrow} className="backArrow" onClick={handleBackButton} />
								<div>
									<h4 className="pageTitle">{company?.name}</h4>
									<p className="pgText">Profile for Company Account </p>
								</div>
							</div>
							<div>
								<TabsList listData={tabsData} activeIndex={activeTab} onChange={setActiveTab} />
							</div>
						</div>
					</div>
				</div>
			</div>
			{successAlert && <SuccessAlert show={successAlert} handleClose={() => setSuccessAlert(false)} title={"Success"} description={"Plan Subscribed Successfully"} />}
		</>
	);
};

export default CompanyDetailView;
