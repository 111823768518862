import React from "react";
import "./HeaderStyles.css";
import MobileHeader from "./mobile/MobileHeader";

const Header = ({ title }) => {
  return (
    <div>
      <div className="headerArea">
        <div className="tileBar">
          <h4 className="wrappeTitle">{title}</h4>
        </div>
      </div>
      <MobileHeader />
    </div>
  );
};

export default Header;
