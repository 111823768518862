import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { setStageTabData } from "../../../../redux/reducers/companySlice";
import { Form } from "react-bootstrap";
import { useCheckCompanyPermission, useGetProgramsWhereUserHasPermission, filterCompanyPrograms } from "utils/helpers/Common";
import { EDIT_COMPANY_MEASURES_STAGES } from "utils/constants/UserPermissions";

const StageView = ({ company, dispatch, hideDeprecated, setHideDeprecated }) => {
	const [companyPrograms, setCompanyPrograms] = useState([]);
	const hasCompanyPermission = useCheckCompanyPermission([EDIT_COMPANY_MEASURES_STAGES]);
	const userPrograms = useGetProgramsWhereUserHasPermission([EDIT_COMPANY_MEASURES_STAGES]);

	useEffect(() => {
		company && setCompanyPrograms(filterCompanyPrograms({ company: company, hideDeprecated: hideDeprecated }));
	}, [company, hideDeprecated]);
	return (
		<div className="pageCard">
			<div className="TitleBox mb-4">
				<h4>Stages</h4>
				<p>View company and program stages</p>
			</div>
			<div className="costingBoxArea">
				<p>Company</p>
				<div className="btnsGirdList">
					<Link to={`/setting/company/${company?.id}/stages/company`} className="cmnyBtn" onClick={() => dispatch(setStageTabData(company.name))}>
						{company?.name}
					</Link>
				</div>
			</div>
			<div className="costingBoxArea">
				<p>Programs</p>
				<Form.Check label="Hide Deprecated" checked={hideDeprecated} onChange={() => setHideDeprecated(!hideDeprecated)} className="mb-2" />
				<div className="btnsGirdList">
					{companyPrograms
						?.filter((program) => (hasCompanyPermission ? true : userPrograms?.some((userProgram) => userProgram.id === program.id)))
						.map((program, pIndex) => (
							<Link
								className={`multiSelectItem color2 ${program.is_trashed ? "trashedItem" : ""}`}
								key={"program" + pIndex}
								to={`/setting/company/${company?.id}/stages/program/${program?.id}`}
								onClick={() => dispatch(setStageTabData(program.name))}
							>
								{program.name}
							</Link>
						))}
				</div>
			</div>
		</div>
	);
};

export default StageView;
