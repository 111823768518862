export const VIEW_COMPANIES = "View Companies";
export const CREATE_COMPANIES = "Create Companies";
export const EDIT_COMPANIES = "Edit Companies";
export const DELETE_COMPANIES = "Delete Companies";
export const EDIT_COMPANY_MEASURES_STAGES = "Edit Company Measures & Stages";
export const CREATE_PROJECTS = "Create Projects";
export const VIEW_PROJECTS = "View Projects";
export const EDIT_PROJECTS = "Edit Projects";
export const DELETE_PROJECTS = "Delete Projects";
export const REASSIGN_PROJECTS = "Reassign Projects";
export const MANAGE_TEMPLATES = "Manage Templates";
export const CREATE_PROGRAMS = "Create Programs";
export const EDIT_PROGRAMS = "Edit Programs";
export const DELETE_PROGRAMS = "Delete Programs";
export const COPY_PROJECTS_ANY_COMPANY_PROGRAM = "Copy Projects to any Company-Program";
export const ACCESS_ZEUS_DEBUG_MODE = "Access Zeus Debug Mode";
export const INVITE_MANAGE_USERS = "Invite and Manage Users";
