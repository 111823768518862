import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useGetMeasureRows,
  getMeasuresApi,
  updateStockMeasureApi,
  useGetMeasureCategories,
  cloneMeasureApi,
  initialSelectedMeasure,
  dedeprecateMeasureApi,
  deprecateMeasureApi,
} from "services/CostingService";
import MeasureTableView from "./MeasureTableView";
import { fields, measureAdminFields } from "utils/constants/Constants";
import { showLoader, hideLoader } from "../../../redux/reducers/LoaderSlice";
import ConfirmationAlert from "components/alert/confirmation/ConfirmationAlert";
import { upperCaseFirstLetter } from "utils/helpers/Common";

const MeasureTable = () => {
  const [lineItems, setLineItems] = useState([]);
  const [showDeprecated, setShowDeprecated] = useState(false);
  const [selectedMeasure, setSelectedMeasure] = useState(
    initialSelectedMeasure
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const headers = [...fields, ...measureAdminFields];
  const { categories } = useGetMeasureCategories();
  const { measures, pagination, rowsMutate } = useGetMeasureRows(
    null,
    null,
    query,
    selectedCategory,
    null,
    true,
    showDeprecated
  );

  useEffect(() => {
    setLineItems(measures);
    setCurrentPage(pagination?.current_page);
    setLastPage(pagination?.total_pages);
  }, [measures, pagination]);

  useEffect(() => {
    rowsMutate();
  }, [showDeprecated]);

  const handleLoadMore = () => {
    const params = {
      measure_name: query,
      measure_category_id: selectedCategory,
      view: "",
      is_deprecated: showDeprecated ? 1 : 0,
      page: currentPage + 1,
    };
    dispatch(showLoader());
    getMeasuresApi(params)
      .then((res) => {
        setLineItems((prev) => [...prev, ...res.data.data.measures]);
        setCurrentPage(res.data.data.pagination.current_page);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const handleToggleDeprecate = () => {
    dispatch(showLoader());
    const apiCall = selectedMeasure.is_deprecated
      ? dedeprecateMeasureApi
      : deprecateMeasureApi;
    apiCall(selectedMeasure.id)
      .then((res) => {
        toast.success(res.data.message);
        setLineItems((prev) =>
          prev.filter((item) => item.id !== selectedMeasure.id)
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        dispatch(hideLoader());
        setSelectedMeasure(initialSelectedMeasure);
      });
  };

  const handleCloneMeasure = () => {
    dispatch(showLoader());
    cloneMeasureApi(selectedMeasure?.id)
      .then((res) => {
        toast.success(res.data.message);
        const clonedMeasure = res.data.data;
        setLineItems((prev) => {
          const itemIndex = prev.findIndex(
            (item) => item.id === selectedMeasure.id
          );
          if (itemIndex !== -1) {
            const updatedItems = [...prev];
            clonedMeasure.measure_category_name =
              updatedItems[itemIndex].measure_category_name;
            updatedItems.splice(itemIndex + 1, 0, clonedMeasure);
            return updatedItems;
          }
          return [...prev, clonedMeasure];
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        dispatch(hideLoader());
        setSelectedMeasure(initialSelectedMeasure);
      });
  };

  const handleChange = (value, fieldId, header) => {
    const data = {
      field_name: header,
      field_value: value,
    };
    updateStockMeasureApi(fieldId, data)
      .then((res) => {})
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {});
  };

  return (
    <>
      <MeasureTableView
        lineItems={lineItems}
        headers={headers}
        currentPage={currentPage}
        lastPage={lastPage}
        handleLoadMore={handleLoadMore}
        handleChange={handleChange}
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        query={query}
        setQuery={setQuery}
        setSelectedMeasure={setSelectedMeasure}
        showDeprecated={showDeprecated}
        setShowDeprecated={setShowDeprecated}
      />
      <ConfirmationAlert
        show={selectedMeasure?.id}
        handleClose={() => setSelectedMeasure(initialSelectedMeasure)}
        title={` ${upperCaseFirstLetter(selectedMeasure?.action)} Measure`}
        description={`Are you sure to ${selectedMeasure?.action}  this measure?`}
        confirmBtnText={upperCaseFirstLetter(selectedMeasure?.action)}
        cancelBtnText={"Cancel"}
        handleConfirm={
          selectedMeasure?.action === "clone"
            ? handleCloneMeasure
            : handleToggleDeprecate
        }
      />
    </>
  );
};

export default MeasureTable;
