import React, { useEffect, useState } from "react";
import CompanyListView from "./CompanyListView";
import { addCompanyUserApi, useGetCompanies, useGetNoRoleCompaniesList, useGetCompanyUserPrograms } from "services/CompanyService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useMatch, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { showLoader, hideLoader } from "../../../../redux/reducers/LoaderSlice";
import { useCheckHasInternalRole } from "utils/helpers/Common";
import JoinCompanyAlert from "components/alert/success/JoinCompanyAlert";
import ProgramDetails from "../user/list/program/ProgramDetails";
import { COMPANY_MANAGER } from "utils/constants/UserRoles";
import { setOnboarding, setOnboardingBackUrl } from "../../../../redux/reducers/companySlice";

const CompanyList = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [joinAlert, setJoinAlert] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState({ companyId: null, companyName: "", action: "" });
	const [onboardingStatus, setOnboardingStatus] = useState({ percent: 0, show: false, onboardingStep: 0 });
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isJoinCompanyUrl = useMatch("/setting/join-company");
	const { companies, companiesMutate } = useGetCompanies();
	const { noRoleCompanies } = useGetNoRoleCompaniesList();
	const isInternalUser = useCheckHasInternalRole();

	useEffect(() => {
		if (isJoinCompanyUrl) {
			dispatch(showLoader());
			addCompanyUserApi({
				invite_id: searchParams.get("company-invitation"),
			})
				.then((res) => {
					setJoinAlert(true);
				})
				.catch((err) => {
					toast.error(err.response.data.message);
				})
				.finally(() => dispatch(hideLoader()));
		}
	}, [isJoinCompanyUrl]);

	useEffect(() => {
		if (companies?.length > 0) {
			const findIncompleteProfileCompany = companies
				.find((data) => data.role.name === COMPANY_MANAGER && data.companies.find((company) => company.onboarding_status !== 1 && company.owner.id === user?.id))
				?.companies?.find((company) => company.onboarding_status !== 1 && company.owner.id === user?.id);
			if (findIncompleteProfileCompany) {
				setOnboardingStatus({
					percent: (findIncompleteProfileCompany.onboarding_step / 4) * 100,
					show: true,
					onboardingStep: findIncompleteProfileCompany.onboarding_step,
				});
			}
		}
	}, [companies]);

	const handleCompleteOnboarding = () => {
		dispatch(setOnboarding(true));
		dispatch(setOnboardingBackUrl("/setting/company"));
		navigate(`/onboarding?step=${onboardingStatus.onboardingStep}`);
	};

	return (
		<>
			<CompanyListView
				roles={companies}
				showCreateModal={showCreateModal}
				setShowCreateModal={setShowCreateModal}
				companiesMutate={companiesMutate}
				navigate={navigate}
				isInternalUser={isInternalUser}
				noRoleCompanies={noRoleCompanies}
				setSelectedCompany={setSelectedCompany}
				onboardingStatus={onboardingStatus}
				handleCompleteOnboarding={handleCompleteOnboarding}
			/>
			{joinAlert && (
				<JoinCompanyAlert
					show={joinAlert}
					handleClose={() => {
						setJoinAlert(false);
						navigate("/setting/company");
					}}
					companyName={searchParams.get("company_name")}
					roleName={searchParams.get("role_name")}
				/>
			)}
			{selectedCompany.action === "programs" && (
				<CompanyUserPrograms
					companyId={selectedCompany.companyId}
					userId={user?.id}
					companyName={selectedCompany.companyName}
					handleClose={() => setSelectedCompany({ companyId: null, companyName: "", action: "" })}
				/>
			)}
		</>
	);
};

const CompanyUserPrograms = ({ companyId, companyName, userId, handleClose }) => {
	const { programs } = useGetCompanyUserPrograms(companyId, userId);

	return <ProgramDetails show={companyId} programs={programs} handleClose={handleClose} title={companyName} showViewDetails={true} />;
};
export default CompanyList;
