import { useState } from 'react';
import { validateFileType } from "utils/helpers/Common";
import { FILE_TYPES } from "utils/constants/Constants";
import { toast } from "react-toastify";

/**
 * A custom hook for handling file uploads with validation.
 * @param {Object} options - Configuration options for the file upload
 * @param {string[]} [options.acceptedTypes] - Array of accepted file types (defaults to JPEG and PNG)
 * @param {Function} [options.onFileSelect] - Callback function called when a file is selected
 * @param {Function} [options.onFileDelete] - Callback function called when a file is deleted
 * @returns {Object} File upload handlers and state
 * @returns {File|null} returns.file - The currently selected file
 * @returns {Function} returns.setFile - Function to set the file state
 * @returns {Function} returns.onFileChange - Handler for file input change events
 * @returns {Function} returns.deleteFile - Function to delete/clear the current file
 */
const useFileUpload = (options = {}) => {
    const [file, setFile] = useState(null);

    const onFileChange = (e) => {
        const fileData = e.target.files[0];

        if (fileData) {
            const acceptedTypes = options.acceptedTypes || [FILE_TYPES.IMAGE.JPEG, FILE_TYPES.IMAGE.PNG];
            const validation = validateFileType(fileData, acceptedTypes);

            if (!validation.isValid) {
                toast.error(validation.error);
                e.target.value = ""; // Reset the input
                return;
            }

            setFile(fileData);
            options.onFileSelect?.(fileData); // Optional callback
        }
    };

    const deleteFile = () => {
        setFile(null);
        options.onFileDelete?.(); // Optional callback
    };

    return {
        file,
        setFile,
        onFileChange,
        deleteFile
    };
};

export default useFileUpload; 