import React from "react";
import Button from "react-bootstrap/Button";
import ProgramFormModal from "components/program/ProgramFormModal";

const AddProgramView = ({
	companyName,
	programName,
	setProgramName,
	handleSubmit,
	show,
	handleShow,
	handleClose,
	file,
	onFileChange,
	deleteFile
}) => {
	const triggerButton = (
		<Button onClick={handleShow} className="InviteUserBtn">
			Add Program
		</Button>
	);

	return (
		<ProgramFormModal
			companyName={companyName}
			programName={programName}
			setProgramName={setProgramName}
			handleSubmit={handleSubmit}
			show={show}
			handleClose={handleClose}
			file={file}
			onFileChange={onFileChange}
			deleteFile={deleteFile}
			isEdit={false}
			triggerButton={triggerButton}
		/>
	);
};

export default AddProgramView;
