import httpClient from "./http-client";
import useSWR from "swr";
import { useDispatch } from "react-redux";
import {
  SwrConfigOptions,
  addParamsToURL,
  handleSwrResponseError,
} from "utils/helpers/Common";
import { toast } from "react-toastify";

export const initialSelectedMeasure = {
  id: null,
  is_deprecated: null,
  action: null,
};

export const useGetMeasureFields = () => {
  const dispatch = useDispatch();
  const {
    data: fields,
    error,
    mutate,
  } = useSWR(
    {
      url: `/measures/fields`,
      dispatch,
    },
    SwrConfigOptions
  );
  if (error) {
    toast.error("Network error occurs");
  }
  return { fields: fields?.data };
};

export const useGetMeasureCategories = () => {
  const dispatch = useDispatch();
  const {
    data: categories,
    error,
    mutate,
  } = useSWR(
    {
      url: `/measures/categories`,
      dispatch,
    },
    SwrConfigOptions
  );
  if (error) {
    toast.error("Network error occurs");
  }
  return { categories: categories?.data };
};
export const useGetCompanyPrograms = (companyId) => {
  const dispatch = useDispatch();
  const {
    data: programs,
    error,
    mutate,
  } = useSWR(
    companyId
      ? {
          url: `/companies/${companyId}/programs?company_id=${companyId}`,
          dispatch,
        }
      : null,
    SwrConfigOptions
  );
  if (error) {
    handleSwrResponseError(error);
  }
  return { programs: programs?.data };
};
export const useGetMeasureRows = (
  companyId,
  programId,
  query,
  categoryId,
  selectedProgram,
  isAdmin,
  isDeprecated,
) => {
  const dispatch = useDispatch();

  const params = {
    company_id: companyId,
    company_program_id: selectedProgram || programId,
    measure_name: query,
    measure_category_id: categoryId,
    view: isAdmin ? "" : programId ? "program" : "company",
    is_deprecated: isDeprecated ? 1 : 0,
  };
  const {
    data: rows,
    error,
    mutate,
  } = useSWR(
    companyId || isAdmin
      ? {
          url: addParamsToURL("/measures", params),
          dispatch,
        }
      : null,
    SwrConfigOptions
  );
  if (error) {
    toast.error("Network error occurs");
  }
  return {
    measures: rows?.data?.measures,
    companyName: rows?.data?.company_name,
    programName: rows?.data?.program_name,
    pagination: rows?.data?.pagination,
    fields: rows?.data?.fields,
    rowsMutate: mutate,
  };
};

export const updateMeasureFieldApi = (companyId, data) => {
  return httpClient.put(`/measures/${companyId}`, data);
};

export const getMeasuresApi = (params) => {
  const url = addParamsToURL("/measures", params);
  return httpClient.get(url);
};

export const updateStockMeasureApi = (measureId, data) => {
  return httpClient.put(`/measures/stock/${measureId}`, data);
};

export const deprecateMeasureApi = (measureId) => {
  return httpClient.put(`/measures/stock/${measureId}/deprecate`);
};

export const dedeprecateMeasureApi = (measureId) => {
  return httpClient.put(`/measures/stock/${measureId}/dedeprecate`);
};

export const cloneMeasureApi = (measureId) => {
  return httpClient.post(`/measures/clone/${measureId}`);
};
