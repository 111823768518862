import React from "react";
import { Form } from "react-bootstrap";

const FieldLabel = ({ label, required, className = "labelText", icon }) => {
	return (
		<Form.Label className={`${className}`}>
			{icon}
			{label} {required && <span className="text-danger">*</span>}
		</Form.Label>
	);
};

export default FieldLabel;
