import React from "react";
import { useSelector } from "react-redux";
import EditProfile from "./edit/EditProfile";
import TabsList from "components/tabs/TabsList";
import SettingSidebar from "../sidebar/SettingSidebar";
import Header from "layouts/header/Header";
import PasswordCredentials from "./passwordCredentials/PasswordCredentials";
import { useCheckHasInternalRole } from "utils/helpers/Common";
import { useGetUser } from "services/ProfileService";

const ProfileArea = () => {
	const authUser = useSelector(store => store.auth?.user);
	const { user, userMutate } = useGetUser();
	const isInternalUser = useCheckHasInternalRole();
	const tabsData = [
		{
			title: "Profile",
			body: <EditProfile user={user} userMutate={userMutate} isEditAble={true} showCancelBtn={false} />,
		},
		{
			title: "Password & Credentials",
			body: <PasswordCredentials />,
		},
	];
	return (
		<div className="wrapperArea">
			<Header title={authUser?.impersonated ? `Account Management (Impersonated as ${authUser?.first_name} ${authUser?.last_name})` : "Account Management"} />
			<div className="wrapperContainer">
				<SettingSidebar isSuperAdmin={isInternalUser} />
				<div className="wrappePageArea wrappePd">
					<div className="pageArea">
						<div className="pageHead">
							<h4 className="pageTitle">YOUR PROFILE</h4>
							<p className="pgText">Manage your profile</p>
						</div>
						<div>
							<TabsList listData={tabsData} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileArea;
