import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { COMPANY_STANDARD_PROGRAM } from "utils/constants/Constants";
import restoreIcon from "assets/images/icons/restore-icon.svg";
import EditProgram from "./edit/EditProgram";
const SelectedPrograms = ({
	selectedPrograms,
	handleRestoreProgramClick,
	handleRemoveProgramClick,
	programsMutate,
	companyMutate,
	companyId,
	isEditable = false,
	canRestore = false,
	canRemove = false,
}) => {
	const [hideDeprecated, setHideDeprecated] = useState(false);
	return (
		<>
			<Form.Check type="checkbox" label="Hide Deprecated" checked={hideDeprecated} onChange={() => setHideDeprecated(!hideDeprecated)} />
			<div className="multiSelectTagsBox color2">
				{selectedPrograms
					?.filter((program) => program.label !== COMPANY_STANDARD_PROGRAM && (!hideDeprecated || !program.is_trashed))
					.map((program) => (
						<div className={`multiSelectItem color2 ${program.is_trashed ? "trashedItem" : ""}`}>
							<span>{program.label}</span>
							{program.is_trashed ? (
								<>
									{canRestore && (
										<button className="crossBtn" type="button" onClick={() => handleRestoreProgramClick(program)}>
											<img src={restoreIcon} alt="restore" />
										</button>
									)}
								</>
							) : (
								<div className="d-flex align-items-center gap-2">
									{isEditable &&  <EditProgram companyId={companyId} program={program} programsMutate={programsMutate} companyMutate={companyMutate} />}
									{canRemove && (
										<button className="crossBtn" type="button" onClick={() => handleRemoveProgramClick(program)}>
											<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M1 9L9 1M1 1L9 9" stroke="#0082FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
											</svg>
										</button>
									)}
								</div>
							)}
						</div>
					))}
			</div>
		</>
	);
};

export default SelectedPrograms;
