import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListTree, faPenToSquare, faMoneyCheckDollarPen, faNotebook, faMessagesQuestion, faArrowRightFromBracket, faScaleBalanced, faTableList } from "@fortawesome/pro-regular-svg-icons";
import { faWpforms } from "@fortawesome/free-brands-svg-icons";
import { ZEUS_URL } from "../../utils/constants/Constants";
import "./SidebarStyles.css";
import SidebarLogo from "../../assets/images/sidebar-logo.png";

const Sidebar = ({ handleLogout }) => {
	return (
		<div className="sidebarArea">
			<div className="navRow">
				<div className="navListArea">
					<a href="#" className="sidebarLogoLink">
						<img src={SidebarLogo} />
					</a>
					<div className="navList">
						<div className="navListItem">
							<Link to={"/setting/profile"} className="navLink active">
								<FontAwesomeIcon icon={faMoneyCheckDollarPen} title="Accounts" />
							</Link>
							<span>Accounts</span>
						</div>
						<div className="navListItem">
							<a href={ZEUS_URL} className="navLink">
								<FontAwesomeIcon icon={faListTree} title="Projects" />
							</a>
							<span>Projects</span>
						</div>
						<div className="navListItem">
							<a href="#" className="navLink disabled">
								<FontAwesomeIcon icon={faPenToSquare} title="Input" />
							</a>
							<span>Input</span>
						</div>
						<div className="navListItem">
							<a href="#" className="navLink ">
								<FontAwesomeIcon icon={faScaleBalanced} className="navLink disabled" title="Calibrate" />
							</a>
							<span>Calibrate</span>
						</div>
						<div className="navListItem">
							<a href="#" className="navLink ">
								<FontAwesomeIcon icon={faTableList} className="navLink disabled" title="Tables" />
							</a>
							<span>Tables</span>
						</div>
						<div className="navListItem">
							<a href="#" className="navLink ">
								<FontAwesomeIcon icon={faWpforms} className="navLink disabled" title="Forms" />
							</a>
							<span>Forms</span>
						</div>
						<div className="navListItem">
							<a href="#" className="navLink ">
								<FontAwesomeIcon icon={faNotebook} className="navLink disabled" title="Reports" />
							</a>
							<span>Reports</span>
						</div>
					</div>
				</div>
				<div className="navItem">
					<div className="navListItem">
						<a href={`${ZEUS_URL}?support=true`} className="navLink">
							<FontAwesomeIcon icon={faMessagesQuestion} title="Support" />
						</a>
						<span>Support</span>
					</div>
					<div className="navListItem">
						<span className="navLink" onClick={handleLogout}>
							<FontAwesomeIcon icon={faArrowRightFromBracket} title="Logout" />
						</span>
						<span>Logout</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
