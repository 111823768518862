import React, { useState } from "react";
import AddCompanyView from "./AddCompanyView";
import { useCreateCompanyForm } from "services/CompanyService";
import { useGetPrograms } from "services/ProgramService";

const AddCompany = ({ show, handleClose, companiesMutate }) => {
	const [selectedPrograms, setSelectedPrograms] = useState([]);
	const [programDefaultValue, setProgramDefaultValue] = useState({ label: "None", value: "none" });
	const { register, handleSubmit, errors, Controller, control, setError, setValue } = useCreateCompanyForm(companiesMutate);
	const { programs } = useGetPrograms();
	const handleProgramSelect = (e) => {
		const clonedSelectedPrograms = [...selectedPrograms];
		const program = clonedSelectedPrograms.find((program) => program.value === e.value);
		if (!program) {
			clonedSelectedPrograms.push(e);
			if (clonedSelectedPrograms.length === 1) {
				setProgramDefaultValue("");
			}
			const programIds = clonedSelectedPrograms.map((program) => program.value);
			setValue("programs", programIds);
			setError("programs", "");
			setSelectedPrograms(clonedSelectedPrograms);
		}
	};

	const handleProgramRemove = (index) => {
		const clonedSelectedPrograms = [...selectedPrograms];
		clonedSelectedPrograms.splice(index, 1);
		const programIds = clonedSelectedPrograms.map((program) => program.value);
		if (clonedSelectedPrograms.length === 0) {
			setProgramDefaultValue({ label: "None", value: "none" });
		}
		setValue("programs", programIds);
		setSelectedPrograms(clonedSelectedPrograms);
	};
	return (
		<AddCompanyView
			show={show}
			handleClose={handleClose}
			register={register}
			handleSubmit={handleSubmit}
			errors={errors}
			Controller={Controller}
			control={control}
			programs={programs}
			handleProgramSelect={handleProgramSelect}
			handleProgramRemove={handleProgramRemove}
			programDefaultValue={programDefaultValue}
			selectedPrograms={selectedPrograms}
		/>
	);
};

export default AddCompany;
