import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FrmLogo from "assets/images/logo.svg";
import FlagIcon from "assets/images/icons/flag-icon.png";
import FormBgShape from "assets/images/icons/formBgShape.svg";
import Form from "react-bootstrap/Form";
import "./SignUpStyle.css";
import SignIn from "../signin/SignIn";
import ResetPassword from "../resetPassword/ResetPassword";
import JoinCompany from "../joinCompany/JoinCompany";
import PasswordRule from "components/passwordRule/PasswordRule";
import FieldLabel from "components/form/label/FieldLabel";
import LegalDocumentModal from "./LegalDocumentModal";
import { EULA_LINK, PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from "utils/constants/Constants";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SignUpView = ({
	register,
	handleSubmit,
	errors,
	showResetPasswordModal,
	setShowResetPasswordModal,
	showSignInModal,
	searchParams,
	isJoinCompanyUrl,
	password,
	setPassword,
	activeTab,
	setActiveTab,
	location,
	legalDocumentModalData,
	setLegalDocumentModalData,
	passwordType,
	confirmPasswordType,
	togglePasswordType
}) => {
	return (
		<>
			<section>
				<div className="formContainer">
					<Row>
						<Col md="6">
							<div className="formContentArea">
								<div className="logoBox">
									<img src={FrmLogo} className="frmLogo" alt="FrmLogo" />
								</div>
								<div className="mobSignBtn">
									<SignIn />
								</div>
								<p className="smText mb-3">You can register a new company, join an existing company (with invite) or sign into your OptiMiser account.</p>
								<div className="tabsArea">
									<Tabs defaultActiveKey="tab-1" className="mb-3 tabsList" activeKey={`${activeTab}`} onSelect={(k) => setActiveTab(k)}>
										<Tab eventKey="tab-1" title="New Company">
											<h5 className="mdH5 mb-1">Register a New Company</h5>
											<p className="smText mb-4">
												Create a new company if your company is not currently registered on OptiMiser. By creating a new company, you assume the role of administrator for this
												company
											</p>
											<Form className="formArea">
												<Row>
													<Col md="12">
														<Form.Group className="mb-3" controlId="company_name">
															<FieldLabel label="Company Name" required />
															<Form.Control type="text" placeholder="Company Name" className="inputField" {...register("company_name")} />
															{errors.company_name && <p className="text-danger">{errors.company_name.message}</p>}
														</Form.Group>
													</Col>
												</Row>
												<svg width="100%" height="2" viewBox="0 0 100% 2" fill="none" xmlns="http://www.w3.org/2000/svg" className="dashedLine">
													<line y1="1" x2="100%" y2="1" stroke="#CFD4D9" stroke-width="2" stroke-dasharray="4 4" />
												</svg>
												<Row>
													<Col md="6">
														<Form.Group className="mb-3" controlId="first_name">
															<FieldLabel label="First Name" required />
															<Form.Control type="text" placeholder="First Name" className="inputField" {...register("first_name")} />
															{errors.first_name && <p className="text-danger">{errors.first_name.message}</p>}
														</Form.Group>
													</Col>
													<Col md="6">
														<Form.Group className="mb-3" controlId="last_name">
															<FieldLabel label="Last Name" required />
															<Form.Control type="text" placeholder="Last Name" className="inputField" {...register("last_name")} />
															{errors.last_name && <p className="text-danger">{errors.last_name.message}</p>}
														</Form.Group>
													</Col>
													<Col md="6">
														<Form.Group className="mb-3" controlId="email">
															<FieldLabel label="Email" required />
															<Form.Control type="email" placeholder="Email" className="inputField" {...register("email")} />
															{errors.email && <p className="text-danger">{errors.email.message}</p>}
														</Form.Group>
													</Col>
													<Col md="6">
														<Form.Group className="mb-3" controlId="phone_number">
															<FieldLabel label="Phone" icon={<img src={FlagIcon} className="FlagIcon" alt="FlagIcon" />} />
															<Form.Control type="text" placeholder="Phone" className="inputField" {...register("phone_number")} />
															{errors.phone_number && <p className="text-danger">{errors.phone_number.message}</p>}
														</Form.Group>
													</Col>
													<Col md="6">
														<Form.Group className="mb-3" controlId="password">
															<FieldLabel label="Password" required />
														
															<div className="input-container" style={{ position: 'relative' }}>
															<Form.Control
																type={passwordType}
																placeholder="Password"
																className="inputField"
																{...register("password", {
																onChange: (e) => {
																	setPassword(e.target.value);
																},
																})}
																style={{ paddingRight: '35px' }}
															/>
															
															<p 
																className="eye-icon" 
																onClick={() => {
																togglePasswordType('password');
																}}
															>
																<FontAwesomeIcon icon={passwordType === 'password' ? faEye : faEyeSlash} />
															</p>
															</div>

															{errors.password && <p className="text-danger">{errors.password.message}</p>}
														</Form.Group>
														</Col>

													<Col md="6">
														<Form.Group className="mb-3" controlId="password_confirmation">
															<FieldLabel label="Confirm Password" required />
															
															<div className="input-container" style={{ position: 'relative' }}>
															<Form.Control 
																type={confirmPasswordType} 
																placeholder="Confirm Password" 
																className="inputField" 
																{...register("password_confirmation")} 
																style={{ paddingRight: '35px' }}
															/>
															
															<p 
																className="eye-icon" 
																onClick={() => {
																togglePasswordType('confirmPassword');
																}} 
															>
																<FontAwesomeIcon icon={confirmPasswordType === 'password' ? faEye : faEyeSlash} />
															</p>
															</div>

															{errors.password_confirmation && <p className="text-danger">{errors.password_confirmation.message}</p>}
														</Form.Group>
														</Col>

													<PasswordRule password={password} color={"green"} />
													<Col md="12">
														<Form.Check>
															<Form.Check.Input type="checkbox" {...register("legal_agreement")} />
															<Form.Check.Label>
																By creating an account, you agree to the{" "}
																<span className="font-weight-bold" onClick={() => setLegalDocumentModalData({ show: true, documentTitle: "EULA", documentLink: EULA_LINK })}>
																	EULA
																</span>
																,{" "}
																<span className="font-weight-bold" onClick={() => setLegalDocumentModalData({ show: true, documentTitle: "Privacy Policy", documentLink: PRIVACY_POLICY_LINK })}>
																	Privacy Policy
																</span>{" "}
																and{" "}
																<span className="font-weight-bold" onClick={() => setLegalDocumentModalData({ show: true, documentTitle: "Terms of Service", documentLink: TERMS_OF_SERVICE_LINK })}>
																	Terms of Service
																</span>
																.
															</Form.Check.Label>
														</Form.Check>
														{errors.legal_agreement && <p className="text-danger">{errors.legal_agreement.message}</p>}
													</Col>

													<Col md="12">
														<button type="button" className="submitBtn mrLauto mt-3" onClick={handleSubmit}>
															Create Account
														</button>
													</Col>
												</Row>
											</Form>
										</Tab>
										<Tab eventKey="tab-2" title="Join Existing company">
											{isJoinCompanyUrl ? (
												<JoinCompany searchParams={searchParams} />
											) : (
												<p className="smText mb-4">
													You need an invitation to join an existing company. Ask your company admin to invite you. Once you receive the email invitation, click on the link
													within the email to create your account under the existing company.
												</p>
											)}
										</Tab>
									</Tabs>
								</div>
							</div>
						</Col>

						<Col md="6">
							<div className="SignInArea">
								<img src={FormBgShape} className="FormBgShape" alt="FormBgShape" />
								<div className="signinContent contentCenter">
									<h5 className="h5Text">Already have an Account?</h5>
									<p className="pText">Log into your existing account by selecting the Sign in button below</p>
									<SignIn 
										showSignInModal={showSignInModal}
										location={location} 
									/>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</section>
			{showResetPasswordModal && (
				<ResetPassword show={showResetPasswordModal} handleClose={() => setShowResetPasswordModal(false)} email={searchParams.get("email")} token={searchParams.get("token")} />
			)}
			{legalDocumentModalData.show && (
				<LegalDocumentModal show={legalDocumentModalData.show} handleClose={() => setLegalDocumentModalData({ show: false, title: "" })} documentTitle={legalDocumentModalData.documentTitle} documentLink={legalDocumentModalData.documentLink} />
			)}
		</>
	);
};

export default SignUpView;
