import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListTree, faMoneyCheckDollarPen, faArrowRightFromBracket } from "@fortawesome/pro-regular-svg-icons";
import "./MobileSideBarStyles.css";
import { getPathNameFromURL } from "utils/helpers/Common";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../redux/reducers/AuthSlice";
import { logoutApi } from "services/ProfileService";
import { ZEUS_URL } from "utils/constants/Constants";
function MobileSideBar() {
	const [show, setShow] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const { user } = useSelector((state) => state.auth);

	const handleLogout = async () => {
		await logoutApi();
		dispatch(logout());
		navigate("/");
	};

	return (
		<>
			<div>
				<Button onClick={handleShow} className="menuToggleBtn">
					<svg width="23  " height="15" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1.47656 0.757812H19.5234M1.47656 6.5H19.5234M1.47656 12.2422H10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</Button>
				<div className="mobSidebarModal">
					<Offcanvas show={show} onHide={handleClose} className="mobSidebarModal">
						<Offcanvas.Header closeButton></Offcanvas.Header>
						<Offcanvas.Body className="mobSideManuArea">
							<div className="mobUserBox">
								{user?.profile_image && <img src={user?.profile_image} alt="" className="MobUserImg" />}

								<div>
									<h5>{`${user.first_name} ${user.last_name}`}</h5>
									<p>{user.email}</p>
								</div>
							</div>
							<Accordion className="mobSidebarCollapse">
								<Accordion.Item eventKey="0">
									<a href={ZEUS_URL} className={"text-decoration-none"}>
										<Accordion.Header>
											<FontAwesomeIcon icon={faListTree} />
											<h5>Projects</h5>
										</Accordion.Header>
									</a>
								</Accordion.Item>
								<Accordion.Item eventKey="1">
									<Accordion.Header>
										<FontAwesomeIcon icon={faMoneyCheckDollarPen} />
										<h5>Accounts</h5>
									</Accordion.Header>
									<Accordion.Body className="mobManuListArea">
										<ul className="mobManuList">
											<li>
												<Link to={"/setting/profile"} className={`mobManItem ${getPathNameFromURL(2) == "profile" ? "active" : ""}`}>
													Your Profile
												</Link>
											</li>
											<li>
												<Link to={"/setting/company"} className={`mobManItem ${getPathNameFromURL(2) == "company" ? "active" : ""}`}>
													Your Companies
												</Link>
											</li>
										</ul>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
							<button className="mobLogoutBtn" onClick={handleLogout}>
								<FontAwesomeIcon icon={faArrowRightFromBracket} />
								Logout
							</button>
						</Offcanvas.Body>
					</Offcanvas>
				</div>
			</div>
		</>
	);
}

export default MobileSideBar;
