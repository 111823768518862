import React from "react";
import { toast } from "react-toastify";
import CurrentUsageView from "./CurrentUsageView";
import { retryPaymentApi, useGetCurrentUsage } from "services/CompanyService";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/reducers/LoaderSlice";

const CurrentUsage = ({ company, showUsageGraph }) => {

  const { usage } = useGetCurrentUsage(company);
  const dispatch = useDispatch();
  const handleRetryPayment = () => {
    dispatch(showLoader());
    retryPaymentApi(company.id, { company_id: company.id })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => dispatch(hideLoader()));
  };
  return (
    <CurrentUsageView companyBillingType={company?.billing_type} showUsageGraph={showUsageGraph} usage={usage} handleRetryPayment={handleRetryPayment} />
  );
};

export default CurrentUsage;
