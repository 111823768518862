import React from "react";
import AddProgram from "./add/AddProgram";
import SelectedPrograms from "./SelectedPrograms";
import "./ListCompanyProgramStyle.css";
import ProgramSelect from "./ProgramSelect";
import { useCheckPermissions, useCheckCompanyPermission } from "utils/helpers/Common";
import { EDIT_PROGRAMS, EDIT_COMPANIES, CREATE_PROGRAMS } from "utils/constants/UserPermissions";

const ListCompanyProgramView = ({
	companyId,
	companyName,
	companyMutate,
	externalPrograms,
	customPrograms,
	companyProgramsMutate,
	selectedExternalPrograms,
	handleSelectProgram,
	handleRemoveProgramClick,
	handleRestoreProgramClick,
}) => {
	const hasEditProgramsPermission = useCheckPermissions([EDIT_PROGRAMS]);
	const hasEditCompanyPermission = useCheckCompanyPermission([EDIT_COMPANIES]);
	const hasAddProgramPermission = useCheckCompanyPermission([CREATE_PROGRAMS]);
	return (
		<div className="pageCard">
			<div className="tblHeaderArea">
				<div className="tblLeftClm">
					<div className="flexTitleBox">
						<div className="TitleBox">
							<h4>Programs</h4>
							<p>Manage company programs</p>
						</div>
					</div>
				</div>
				<div className="tblRightClm flexEnd"></div>
			</div>
			<div className="row mb-4">
				<div className="col-md-12 externalProgramsBox">
					<div className="text-left">
						<h6 className="font-weight-bold font-size-14">External Programs</h6>
						<ProgramSelect
							programs={externalPrograms?.filter((program) => !selectedExternalPrograms?.find((selected) => selected.value === program.id))}
							onChange={handleSelectProgram}
							placeholder="Select Program"
							isDisabled={!hasEditCompanyPermission}
						/>
						{selectedExternalPrograms?.length > 0 && (
							<SelectedPrograms
								selectedPrograms={selectedExternalPrograms}
								handleRestoreProgramClick={handleRestoreProgramClick}
								handleRemoveProgramClick={handleRemoveProgramClick}
								canRemove={hasEditCompanyPermission}
							/>
						)}
					</div>
				</div>

				<div className="col-md-12 customProgramsBox">
					<div className="d-flex align-items-center gap-3 mb-3">
						<h6 className="font-weight-bold font-size-14 m-0">Custom Programs</h6>
						{hasAddProgramPermission && (
							<AddProgram companyId={companyId} companyName={companyName} programsMutate={companyProgramsMutate} companyMutate={companyMutate} />
						)}
					</div>
					<div className="text-left">
						{customPrograms?.length > 0 ? (
							<SelectedPrograms
								selectedPrograms={customPrograms}
								handleRestoreProgramClick={handleRestoreProgramClick}
								handleRemoveProgramClick={handleRemoveProgramClick}
								programsMutate={companyProgramsMutate}
								companyMutate={companyMutate}
								companyId={companyId}
								isEditable={hasEditProgramsPermission}
								canRemove={hasEditCompanyPermission}
								canRestore={hasEditCompanyPermission}
							/>
						) : (
							<p className="text-muted">No Custom Program(s) Created</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ListCompanyProgramView;
