import React from "react";
import { useState } from "react";

import { useSignInForm } from "services/AuthService";
import SignInView from "./SignInView";

const SignIn = ({ showSignInModal, location }) => {
  const [show, setShow] = useState(showSignInModal);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const { register, handleSubmit, errors } = useSignInForm(location);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [passwordType, setPasswordType]= useState('password');
  
  const togglePasswordType = () => {
    passwordType === 'password' ? setPasswordType('text') : setPasswordType('password')
  }

  return (
    <SignInView
      handleClose={handleClose}
      handleShow={handleShow}
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      show={show}
      showForgotPasswordModal={showForgotPasswordModal}
      setShowForgotPasswordModal={setShowForgotPasswordModal}
      passwordType={passwordType}
      togglePasswordType={togglePasswordType}
    />
  );
};

export default SignIn;
