import useSWR from "swr";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import httpClient from "./http-client";
import ErrorToast from "components/toast/ErrorToast";
import { hideLoader, showLoader } from "../redux/reducers/LoaderSlice";
import { EditCompanyUserSchema, InviteUserSchema, UpdateCompanyProfileSchema } from "utils/validations/CompanyValidation";
import { SwrConfigOptions, fetchResponseErrors, handleApiResponseError, handleSwrResponseError } from "utils/helpers/Common";
import { useNavigate } from "react-router-dom";
export const useGetCompanies = () => {
	const dispatch = useDispatch();
	const { data: companies, error, mutate } = useSWR({ url: `/companies`, dispatch }, SwrConfigOptions);
	if (error) {
		handleSwrResponseError(error);
	}
	return { companies: companies?.data, companiesMutate: mutate };
};

export const useGetAllCompaniesList = () => {
	const dispatch = useDispatch();
	const { data: companies, error, mutate } = useSWR({ url: `/companies/all`, dispatch }, SwrConfigOptions);
	if (error) {
		handleSwrResponseError(error);
	}
	return { companies: companies?.data, companiesMutate: mutate };
};

export const useGetNoRoleCompaniesList = () => {
	const dispatch = useDispatch();
	const { data: companies, error } = useSWR({ url: `/companies/no-role`, dispatch }, SwrConfigOptions);
	if (error) {
		handleSwrResponseError(error);
	}
	return { noRoleCompanies: companies?.data };
};

export const useGetOnboardingCompany = () => {
	const dispatch = useDispatch();
	const { data: company, error, mutate } = useSWR({ url: `/companies/onboarding`, dispatch }, SwrConfigOptions);
	if (error) {
		handleSwrResponseError(error);
	}
	return { company: company?.data, companyMutate: mutate };
};
export const useGetCompanyDetails = (id) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		data: company,
		error,
		mutate,
	} = useSWR(
		id
			? {
					url: `/companies/${id}?company_id=${id}`,
					dispatch,
			  }
			: null,
		SwrConfigOptions
	);
	if (error) {
		handleSwrResponseError(error, navigate, "/setting/company");
	}
	return { company: company?.data, companyMutate: mutate };
};

export const useGetCompanyUsers = (id, programId = null) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	let url = `/companies/${id}/users?company_id=${id}`;
	programId && (url += `&program_id=${programId}`);

	const {
		data: users,
		error,
		mutate,
	} = useSWR(
		id
			? {
					url: url,
					dispatch,
			  }
			: null,
		SwrConfigOptions
	);
	if (error) {
		handleSwrResponseError(error, navigate, "/setting/company");
	}
	return { users: users?.data, usersMutate: mutate };
};

export const useGetCompanyUserPrograms = (companyId, userId, programId = null) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let url = `/companies/user/${userId}/programs?company_id=${companyId}`;
	programId && (url += `&program_id=${programId}`)
	const {
		data: programs,
		error,
		mutate,
	} = useSWR(
		companyId && userId
			? {
					url: url,
					dispatch,
			  }
			: null,
		SwrConfigOptions
	);
	if (error) {
		handleSwrResponseError(error, navigate, "/setting/company");
	}
	return { programs: programs?.data, programsMutate: mutate };
};

export const useGetInvitedUsers = (companyId, programId = null) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let url = `/companies/${companyId}/invite-users?company_id=${companyId}`;

	programId && (url += `&program_id=${programId}`);

	const { data: invitedUsers, error, mutate } = useSWR({ url: url, dispatch }, SwrConfigOptions);
	if (error) {
		handleSwrResponseError(error, navigate, "/setting/company");
	}
	return { invitedUsers: invitedUsers?.data, invitedUsersMutate: mutate };
};

export const useGetCurrentUsage = (company) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		data: usage,
		error,
		mutate,
	} = useSWR(
		company
			? {
					url: `/usage/show?company_id=${company.id}`,
					dispatch,
			  }
			: null,
		SwrConfigOptions
	);
	if (error) {
		handleSwrResponseError(error, navigate, "/setting/company");
	}
	return { usage: usage?.data };
};

export const useGetUserDetails = (id) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		data: user,
		error,
		mutate,
	} = useSWR(
		id
			? {
					url: `/user/${id}`,
					dispatch,
			  }
			: null,
		SwrConfigOptions
	);
	if (error) {
		handleSwrResponseError(error, navigate, "/setting/company");
	}
	return { user: user?.data, userMutate: mutate };
};

export const useGetCompanyUserRole = (companyId, userId, programId = null) => {
	const dispatch = useDispatch();
	let url = `/companies/user-role/${userId}?company_id=${companyId}`;
	programId && (url += `&program_id=${programId}`);

	const { data: role, error } = useSWR(
		companyId && userId
			? {
					url: url,
					dispatch,
			  }
			: null,
		SwrConfigOptions
	);
	if (error) {
		toast.error("Network error occurs");
	}
	return { companyRole: role?.data };
};

export const useGetRoles = () => {
	const dispatch = useDispatch();
	const {
		data: roles,
		error,
		mutate,
	} = useSWR(
		{
			url: `/roles`,
			dispatch,
		},
		SwrConfigOptions
	);
	if (error) {
		toast.error("Network error occurs");
	}
	return { roles: roles?.data, rolesMutate: mutate };
};

export const useGetPricingPlans = (company) => {
	const dispatch = useDispatch();
	const {
		data: plans,
		error,
		mutate,
	} = useSWR(
		company
			? {
					url: `/billing/tiers?company_id=${company.id}`,
					dispatch,
			  }
			: null,
		SwrConfigOptions
	);
	if (error) {
		toast.error("Network error occurs");
	}
	return { plans: plans?.data, plansMutate: mutate };
};

export const useGetPaymentMethods = (company) => {
	const dispatch = useDispatch();
	const {
		data: cards,
		error,
		mutate,
	} = useSWR(
		company
			? {
					url: `/payment-methods/${company.id}?company_id=${company.id}`,
					dispatch,
			  }
			: null,
		SwrConfigOptions
	);
	if (error) {
		toast.error("Network error occurs");
	}
	return { cards: cards?.data, cardsMutate: mutate };
};

export const useGetCompanyInvoices = (company) => {
	const dispatch = useDispatch();
	const {
		data: invoices,
		error,
		mutate,
	} = useSWR(
		company
			? {
					url: `/billing/${company.id}/invoices?company_id=${company.id}`,
					dispatch,
			  }
			: null,
		SwrConfigOptions
	);
	if (error) {
		toast.error("Network error occurs");
	}
	return { data: invoices?.data };
};

export const useGetCompanyInvoiceDetail = (company, invoice) => {
	const dispatch = useDispatch();
	const {
		data: invoiceDetails,
		error,
		mutate,
	} = useSWR(
		invoice && company
			? {
					url: `/billing/${company.id}/invoice/${invoice.id}?company_id=${company.id}`,
					dispatch,
			  }
			: null,
		SwrConfigOptions
	);
	if (error) {
		toast.error("Network error occurs");
	}
	return { details: invoiceDetails?.data };
};
export const useEditCompanyProfileForm = (company, companyMutate) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
		setError,
		control,
		reset,
		setValue,
	} = useForm({
		resolver: yupResolver(UpdateCompanyProfileSchema),
		values: {
			name: company?.name,
			office_phone: company?.office_phone ?? "",
			address: company?.address ?? "",
			city: company?.city ?? "",
			state: company?.state ? { label: company?.state, value: company?.state } : { label: null, value: null },
			website: company?.website ?? "",
			admin_email: company?.admin_email,
			zip_code: company?.zip_code ?? "",
			programs: company?.programs?.map((program) => program.id),
		},
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: "programs",
	});
	const _handleSubmit = (data, callback) => {
		data.company_id = company.id;
		dispatch(showLoader());
		updateCompanyProfileApi(company.id, data)
			.then((res) => {
				companyMutate();
				toast.success("Company updated successfully");
				callback && callback();
			})
			.catch((err) => {
				handleApiResponseError(setError, err.response, navigate, "/setting/company");
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};

	const handleFileUpload = (file, handleClose) => {
		const formData = new FormData();
		formData.append("company_id", company.id);
		formData.append("logo", file);
		dispatch(showLoader());
		uploadCompanyLogoApi(company.id, formData)
			.then((res) => {
				toast.success("Company Logo updated successfully");
				companyMutate();
				handleClose && handleClose();
			})
			.catch((err) => {
				handleApiResponseError(setError, err.response, navigate, "/setting/company");
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};
	return {
		register,
		errors,
		handleSubmit: handleSubmit(_handleSubmit),
		handleFileUpload,
		Controller,
		control,
		reset,
		setValue,
		setError,
		isDirty,
		fields,
		append,
		remove,
	};
};

export const useCreateCompanyForm = (companiesMutate) => {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		setError,
		setValue,
	} = useForm({
		resolver: yupResolver(UpdateCompanyProfileSchema),
	});
	const _handleSubmit = (data, callback) => {
		dispatch(showLoader());
		createCompanyApi(data)
			.then((res) => {
				toast.success("Company Created successfully");
				callback && callback();
				companiesMutate();
			})
			.catch((err) => {
				toast.error(<ErrorToast error={fetchResponseErrors(err.response)} />);
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};

	return {
		register,
		errors,
		handleSubmit: handleSubmit(_handleSubmit),
		Controller,
		control,
		setError,
		setValue,
	};
};

export const useEditCompanyUserForm = (user, usersMutate, company) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm({
		resolver: yupResolver(EditCompanyUserSchema),
		values: {
			first_name: user?.first_name,
			last_name: user?.last_name,
			email: user?.email,
			phone_number: user?.phone_number,
			address: user?.address,
			city: user?.city,
			state: user?.state,
			website: user?.website,
			role_id: user?.role?.id,
		},
	});
	const _handleSubmit = (data, callback) => {
		data.company_id = company.id;
		data.user_id = user.id;
		dispatch(showLoader());
		updateUserApi(company.id, user.id, data)
			.then((res) => {
				toast.success("User updated successfully");
				callback && callback();
				usersMutate();
			})
			.catch((err) => {
				handleApiResponseError(setError, err.response, navigate, "/setting/company");
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};

	return {
		register,
		errors,
		handleSubmit: handleSubmit(_handleSubmit),
	};
};

export const useInviteUserForm = (company) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setError,
	} = useForm({
		resolver: yupResolver(InviteUserSchema),
	});
	const _handleSubmit = (data, callback) => {
		data.company_id = company?.id;
		dispatch(showLoader());
		inviteUserApi(data)
			.then((res) => {
				toast.success("User invited successfully");
				callback && callback();
			})
			.catch((err) => {
				handleApiResponseError(setError, err.response, navigate, "/setting/company");
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};
	return {
		register,
		errors,
		handleSubmit: handleSubmit(_handleSubmit),
		reset,
	};
};

export const updateCompanyProfileApi = (id, data) => {
	return httpClient.put(`/companies/${id}`, data);
};

export const updateUserApi = (companyId, id, data) => {
	return httpClient.put(`/companies/${companyId}/user/${id}`, data);
};

export const createCompanyApi = (data) => {
	return httpClient.post(`/companies`, data);
};

export const inviteUserApi = (data) => {
	return httpClient.post(`/companies/invite/users`, data);
};
export const addCompanyUserApi = (data) => {
	return httpClient.post(`/companies/add-user`, data);
};

export const uploadCompanyLogoApi = (id, data) => {
	return httpClient.post(`/companies/${id}/update-logo`, data);
};

export const updateCompanyUserStatusApi = (companyId, userId, data) => {
	return httpClient.post(`/companies/${companyId}/users/${userId}/change-status`, data);
};

export const subscribePlanApi = (data) => {
	return httpClient.post(`/billing/checkout`, data);
};

export const downloadInvoiceApi = (companyId, data) => {
	return httpClient.post(`/billing/${companyId}/download-invoice?company_id=${companyId}`, data, {
		responseType: "blob",
	});
};

export const updateCustomerApi = (companyId) => {
	return httpClient.get(`/billing/${companyId}/update/customer`);
};

export const cancelPlanApi = (companyId, data) => {
	return httpClient.post(`/billing/${companyId}/cancel/subscription`, data);
};

export const defaultCardApi = (companyId, cardId) => {
	return httpClient.put(`/payment-methods/${companyId}/${cardId}?company_id=${companyId}`);
};

export const deleteCardApi = (cardId, companyId) => {
	return httpClient.delete(`/payment-methods/${cardId}?company_id=${companyId}`);
};

export const addCardApi = (companyId, data) => {
	return httpClient.post(`/payment-methods/${companyId}`, data);
};

export const retryPaymentApi = (companyId, data) => {
	return httpClient.post(`/billing/retry-payment/${companyId}?company_id=${companyId}`, data);
};

export const updateCompanyOnboardingStatusApi = (id, data) => {
	return httpClient.put(`/companies/${id}/update-onboarding-status?company_id=${id}`, data);
};

export const updateCompanyOnboardingStepApi = (id, data) => {
	return httpClient.put(`/companies/${id}/update-onboarding-step?company_id=${id}`, data);
};


export const revokeInviteApi = (companyId, inviteId) => {
	return httpClient.delete(`/companies/revoke-invite/${inviteId}?company_id=${companyId}`);
};
