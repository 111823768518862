import React from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import MeasureTableHeader from "./MeasureTableHeader";
import MeasureTableRow from "./MeasureTableRow";
import SearchIcon from "assets/images/icons/search-icon.svg";
import "./MeasureTableStyle.css";
import { useCheckPermissions } from "utils/helpers/Common";
import { EDIT_COMPANY_MEASURES_STAGES } from "utils/constants/UserPermissions";
const MeasureTableView = ({
	lineItems,
	currentPage,
	lastPage,
	headers,
	handleLoadMore,
	handleChange,
	categories,
	selectedCategory,
	setSelectedCategory,
	query,
	setQuery,
	setSelectedMeasure,
	showDeprecated,
	setShowDeprecated,
}) => {
	const hasEditMeasurePermission = useCheckPermissions([EDIT_COMPANY_MEASURES_STAGES]);
	return (
		<div className="pageCard">
			<div className="tblHeaderArea">
				<div className="tblLeftClm">
					<div className="flexTitleBox">
						<div className="TitleBox">
							<h4>Measures</h4>
							<p>Lorem ipsum dolor sit amet consectetur. Morbi commodo elementum</p>
						</div>
					</div>
					<Form.Check type={"checkbox"} label={"Show Deprecated"} value={showDeprecated} onChange={() => setShowDeprecated(!showDeprecated)} />
				</div>
				<div className="tblRightClm ftContent">
					<div className="tblSearchBar">
						<input type="search" className="searchField" placeholder="Search" value={query} onChange={(e) => setQuery(e.target.value)} />
						<img src={SearchIcon} className="searchIcon" alt="Search" />
					</div>
					<Select
						className="tblSelectInput"
						isClearable={true}
						placeholder="All Category"
						options={categories?.map((c) => ({ value: c.id, label: c.name }))}
						onChange={(e) => {
							if (e) {
								setSelectedCategory(e.value);
							} else {
								setSelectedCategory("");
							}
						}}
						styles={{
							control: (baseStyles, state) => ({
								...baseStyles,
								border: 0,
								boxShadow: "none",
							}),
						}}
					/>
				</div>
			</div>
			<div className="tableScrollArea costingTableArea verticalScroll">
				<table className="tableArea measureTable">
					<MeasureTableHeader headers={headers} />
					<div className="tableSpace"></div>
					<tbody>
						{lineItems?.length > 0 &&
							lineItems.map((item, index) => (
								<MeasureTableRow
									key={index}
									lineItem={item}
									headers={headers}
									handleChange={handleChange}
									setSelectedMeasure={setSelectedMeasure}
									canEdit={hasEditMeasurePermission}
									canClone={hasEditMeasurePermission}
									canDeprecate={hasEditMeasurePermission}
								/>
							))}
					</tbody>
				</table>
			</div>
			{currentPage < lastPage && (
				<div className="d-flex justify-content-center align-items-center">
					<button className="loadMoreBtn" onClick={handleLoadMore}>
						Load More
					</button>
				</div>
			)}
		</div>
	);
};

export default MeasureTableView;
