import React, { useState, useEffect } from "react";

const MeasureTableInput = ({ data, fieldId, header, handleChange, isReadOnly }) => {
  const [value, setValue] = useState(data);
  useEffect(() => {
    setValue(data ?? "");
  }, [data]);
  
  return (
    <input
      className="tblText inputTblText"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => handleChange(value, fieldId, header)}
      readOnly={isReadOnly}
    />
  );
};

export default MeasureTableInput;
