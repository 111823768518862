import React from "react";
import { Routes, Route } from "react-router-dom";
import CompanyDetail from "pages/setting/company/detail/CompanyDetail";
import CompanyList from "pages/setting/company/list/CompanyList";
import ProfileArea from "pages/setting/profile/ProfileArea";
import Setting from "pages/setting/Setting";
import ProgramAdminDetail from "pages/setting/program-admin/detail/ProgramAdminDetail";

const SettingNavigator = () => {
	return (
		<Routes>
			<Route exact path="/" element={<Setting />} />
			<Route exact path="/profile" element={<ProfileArea />} />
			<Route exact path="/company" element={<CompanyList />} />
			<Route exact path="/company/:id" element={<CompanyDetail />} />
			<Route exact path="/join-company" element={<CompanyList />} />
			<Route exact path="/company/:id/measures/:type/:programId?" element={<CompanyDetail />} />
			<Route exact path="/company/:id/stages/:type/:programId?" element={<CompanyDetail />} />
			<Route exact path="/company/:id/program/:programId" element={<ProgramAdminDetail />} />
		</Routes>
	);
};

export default SettingNavigator;
