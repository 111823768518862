import React from "react";
import "./SettingSidebarStyles.css";
import { Link } from "react-router-dom";
import { getPathNameFromURL } from "utils/helpers/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
const SettingSidebar = ({ isSuperAdmin, onboardingStatus, handleCompleteOnboarding }) => {
	return (
		<div className="settingSidebar">
			<ul className="stSidebarList">
				<li>
					<Link to={"/setting/company"} className={`stSidebarItem ${getPathNameFromURL(2) == "company" ? "active" : ""}`}>
						Your Companies
					</Link>
				</li>
				<li>
					<Link to={"/setting/profile"} className={`stSidebarItem ${getPathNameFromURL(2) == "profile" ? "active" : ""}`}>
						Your Profile
					</Link>
				</li>
				{isSuperAdmin && (
					<li>
						<Link to={"/setting"} className={`stSidebarItem ${getPathNameFromURL(1) == "setting" && !getPathNameFromURL(2) ? "active" : ""}`}>
							Settings
						</Link>
					</li>
				)}
				<li>
					{onboardingStatus?.show && (
						<>
							<div className="stSidebarFooter">
								<button className="stSidebarFooterBtn" onClick={handleCompleteOnboarding}>
									Complete Onboarding
								</button>
								<FontAwesomeIcon icon={faClock} width={24} height={24}/>
							</div>
							<div className="progress mt-2 mx-3" style={{ height: "10px", position: "relative" }}>
								<div className="progress-bar" role="progressbar" style={{ width: `${onboardingStatus?.percent}%`, backgroundColor: "#0084ff"	 }} aria-valuenow={onboardingStatus?.percent} aria-valuemin="0" aria-valuemax="100">
									<span style={{ position: "absolute", width: "100%", textAlign: "center", color: "#04223e", fontSize: "10px", lineHeight: "6px", paddingRight: "20px", fontWeight: "700" }}>{onboardingStatus?.percent}%</span>
								</div>
							</div>
						</>
					)}
				</li>
			</ul>
		</div>
	);
};

export default SettingSidebar;
