import { toast } from "react-toastify";
import { hideLoader, showLoader } from "../../redux/reducers/LoaderSlice";
import httpClient from "services/http-client";
import { COMPANY_STANDARD_PROGRAM, COOKIE_CONFIG_OPTIONS, COOKIE_MAIN_DOMAIN, monthNames } from "utils/constants/Constants";
import VisaIcon from "assets/images/icons/visa.svg";
import MasterCardIcon from "assets/images/icons/payment-icon.svg";
import AmericanExpressIcon from "assets/images/icons/american-express.svg";
import DiscoverIcon from "assets/images/icons/discover.svg";
import UnionPayIcon from "assets/images/icons/unionpay.svg";
import JCBIcon from "assets/images/icons/jcb.svg";
import DinnerClub from "assets/images/icons/dinner-club.svg";
import BankIcon from "assets/images/icons/bank-icon.svg";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { INTERNAL_ROLES } from "utils/constants/UserRoles";
export function fetchResponseErrors(response) {
	let msgs = [];
	if (response?.status == 422) {
		let errors = response.data.errors;
		let _errors = Object.keys(errors);
		for (let i = 0; i < _errors.length; i++) {
			msgs.push(errors[_errors[i]][0]);
		}

		return msgs;
	} else {
		msgs.push(response?.data?.message);
		return msgs;
	}
}

export const getInitialsFromName = (name) => {
	const splittedName = name?.split(" ");
	return (splittedName?.[0]?.charAt(0)?.toUpperCase() || "") + (splittedName?.[1]?.charAt(0)?.toUpperCase() || "");
};

export const checkStringPattern = (pattern, string) => {
	let regex = new RegExp(pattern);
	return regex.test(string);
};

export const getPathNameFromURL = (index) => {
	return window.location.pathname.split("/")[index];
};

export const fetcher = async ({ url, dispatch }) => {
	try {
		dispatch && dispatch(showLoader());
		const { data } = await httpClient.get(url);
		dispatch && dispatch(hideLoader());
		return data;
	} catch (error) {
		dispatch && dispatch(hideLoader());
		throw error.response.data;
	}
};

export const SwrConfigOptions = {
	revalidateIfStale: false,
	revalidateOnFocus: false,
	refreshInterval: false,
};

export const addServerErrorsToForm = (setError, errors) => {
	return Object.keys(errors).forEach((key) => {
		setError(key, {
			type: "server",
			message: errors[key].join(". "),
		});
	});
};

export const handleApiResponseError = (setError, response, navigate, redirectUrl) => {
	if (response.status == 422) {
		return addServerErrorsToForm(setError, response.data.errors);
	} else if (response.status == 500 || response.status == 400) {
		toast.error(response.data.message);
	} else if (response.status == 403) {
		navigate(redirectUrl);
	}
};

export const handleSwrResponseError = (error, navigate, redirectUrl) => {
	if (error.status == 403) {
		navigate(redirectUrl);
	} else {
		toast.error("Network error occurred");
	}
};

export const formatDateToCustomString = (isoDateString, addDay = 0) => {
	const date = new Date(isoDateString);
	date.setDate(date.getDate() + addDay);
	const day = date.getUTCDate();
	const monthIndex = date.getUTCMonth();
	const year = date.getUTCFullYear();
	const formattedDate = `${monthNames[monthIndex]} ${day}, ${year}`;
	return formattedDate;
};
export const formatDateToMonthYear = (dateString) => {
	const date = new Date(dateString);
	const monthIndex = date.getMonth();
	const year = date.getFullYear();
	const formattedDate = `${monthNames[monthIndex]} ${year}`;
	return formattedDate;
};

export const calculatePercentage = (part, total) => {
	if (total !== 0) {
		const percentage = (part / total) * 100;
		return parseInt(percentage);
	} else {
		return "Cannot divide by zero";
	}
};

export const calculateRemainingDays = (endDate) => {
	const end = new Date(endDate);
	const today = new Date();
	const differenceMs = end - today;
	const daysRemaining = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
	return daysRemaining;
};

export const getCardBrandIcon = (brand) => {
	switch (brand) {
		case "unionpay":
			return UnionPayIcon;
		case "diners":
			return DinnerClub;
		case "discover":
			return DiscoverIcon;
		case "amex":
			return AmericanExpressIcon;
		case "mastercard":
			return MasterCardIcon;
		case "visa":
			return VisaIcon;
		case "jcb":
			return JCBIcon;
		case "us_bank_account":
			return BankIcon;
		default:
			return VisaIcon;
	}
};
export const formatWithCommas = (number) => {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const upperCaseFirstLetter = (str) => str?.charAt(0).toUpperCase() + str?.slice(1);

export const setCookie = (name, value) => {
	Cookies.set(name, value, COOKIE_CONFIG_OPTIONS);
};

export const getCookie = (name) => {
	return Cookies.get(name, { domain: COOKIE_MAIN_DOMAIN });
};

export const removeCookie = (name) => {
	Cookies.remove(name, COOKIE_CONFIG_OPTIONS);
};

export const generateRandomColor = (opacity) => {
	const r = Math.floor(Math.random() * 255);
	const g = Math.floor(Math.random() * 255);
	const b = Math.floor(Math.random() * 255);
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const styleSelect = {
	option: (provided, state) => ({
		...provided,
		borderBottom: "1px dotted pink",
		color: "#212529",
		fontSize: "14px",
		fontWeight: "400",
		backgroundColor: "#fff",
		border: "0",
		cursor: "Pointer",
		overflow: "visiable",
		position: "relative",
	}),
	control: (provided, state) => ({
		...provided,
		overflow: "visiable",
		padding: "0px",
		border: "1px solid #cfd4d9",
		fontSize: "12px",
		color: "#212529",
		height: "44px",
		fontWeight: "500",
		backgroundColor: "#fff",
		boxShadow: "none",
	}),
};

export const styleSelectProfile = {
	option: (provided, state) => ({
		...provided,
		borderBottom: "1px dotted pink",
		color: "#212529",
		fontSize: "14px",
		fontWeight: "400",
		backgroundColor: "#fff",
		border: "0",
		cursor: "Pointer",
		overflow: "visiable",
		position: "relative",
	}),
	control: (provided, state) => ({
		...provided,
		overflow: "visiable",
		padding: "0px",
		border: "1px solid #1b1b1b",
		borderRadius: "5px",
		fontSize: "12px",
		color: "#212529",
		height: "44px",
		fontWeight: "500",
		backgroundColor: "#fff",
		boxShadow: "none",
	}),
};
export const getNestedQueryParams = (params) => {
	const queryObject = {};
	for (const [key, value] of params.entries()) {
		if (key.includes("[")) {
			const keys = key.split(/[[\]]{1,2}/).filter(Boolean);
			if (!queryObject[keys[0]]) {
				queryObject[keys[0]] = [];
			}
			const index = parseInt(keys[1], 10);
			if (!queryObject[keys[0]][index]) {
				queryObject[keys[0]][index] = {};
			}
			queryObject[keys[0]][index][keys[2]] = value;
		}
	}
	return queryObject;
};

export const useCheckPermissions = (names, programId = null) => {
	const hasCompanyPermission = useCheckCompanyPermission(names);
	const hasAdminPermission = useCheckHasAdminPermission(names);
	const hasProgramPermission = useCheckHasProgramPermission(names, programId);
	//check if user has admin permission
	if (hasAdminPermission) {
		return true;
	}
	//check if user has company permission
	if (hasCompanyPermission) {
		return true;
	}
	//check if user has program permission
	if (hasProgramPermission) {
		return true;
	}
	return false;
};

export const useCheckHasProgramPermission = (names, programId = null) => {
	const { user } = useSelector((state) => state.auth);
	//check if user has permission in specific program
	if (programId) {
		return user?.programs?.some((program) => program.id === programId && checkProgramPermissions(program, names));
	}
	//check if user has permission in any program
	return user?.programs?.some((program) => checkProgramPermissions(program, names));
};

export const useCheckCompanyPermission = (names) => {
	const { user } = useSelector((state) => state.auth);
	//check if user has permission in internal role
	if (useCheckHasAdminPermission(names)) {
		return true;
	}
	return checkRolePermission(user?.role, names);
};

export const useCheckHasInternalRole = () => {
	const { user } = useSelector((state) => state.auth);
	return INTERNAL_ROLES.includes(user?.internal_role?.name);
};

export const useCheckHasAdminPermission = (names) => {
	const { user } = useSelector((state) => state.auth);
	return checkRolePermission(user?.internal_role, names);
};

export const useCheckCompanyRole = (name) => {
	const { user } = useSelector((state) => state.auth);
	return user?.role?.name === name;
};

export const addParamsToURL = (url, params = {}) => {
	const keys = Object.keys(params);
	let isFirst = 0; // This is used to check whether to add ? or & in query
	keys.forEach((key) => {
		if (!!params?.[key]) {
			url = isFirst === 0 ? url + `?${key}=${params[key]}` : url + `&${key}=${params[key]}`;
			isFirst++;
		}
	});
	return url;
};

export const removeUnderscores = (str) => {
	return str.replace(/_/g, " ");
};

export const setItemsBorder = (data) => {
	data?.map((item, index, arr) => {
		item.border_color = item.id % 2 == 0 ? "darkgreen" : "lightgreen";
		if (index === 0 || item.id !== arr[index - 1].id) {
			item.border_top = true;
		}

		if (index === arr.length - 1 || item.id !== arr[index + 1].id) {
			item.border_bottom = true;
		}

		return item;
	});
	return data;
};

/**
 * Validates if a file is of an accepted type
 * @param {File} file - The file to validate
 * @param {string[]} acceptedTypes - Array of accepted MIME types (e.g. ['image/jpeg', 'image/png'])
 * @returns {Object} Validation result
 * @returns {boolean} returns.isValid - Whether the file type is valid
 * @returns {string|null} returns.error - Error message if invalid, null if valid
 */
export const validateFileType = (file, acceptedTypes) => {
	// If no file, return early
	if (!file) {
		return {
			isValid: false,
			error: "No file selected",
		};
	}

	// Validate file type
	const isValidType = acceptedTypes.includes(file.type);

	// Format error message to show readable file types
	const readableTypes = acceptedTypes.map((type) => {
		const extension = type.split("/")[1].toUpperCase();
		return extension;
	});

	return {
		isValid: isValidType,
		error: isValidType ? null : `Only ${readableTypes.join(" and ")} files are allowed.`,
	};
};

export const filterCompanyPrograms = ({ company, hideDeprecated = null, programId = null }) => {
	const companyPrograms =
		company?.programs?.filter((program) => {
			let filterCondition = program.name !== COMPANY_STANDARD_PROGRAM;
			if (hideDeprecated !== null) {
				filterCondition = filterCondition && (!hideDeprecated || !program.is_trashed);
			} else {
				filterCondition = filterCondition && !program.is_trashed;
			}
			if (programId) {
				filterCondition = filterCondition && program.id == programId;
			}
			return filterCondition;
		}) ?? [];

	return companyPrograms;
};

export const checkProgramPermissions = (program, names) => {
	return program?.role?.permissions?.some((permission) => names.includes(permission.name));
};

export const checkProgramsPermissions = (programs, names) => {
	return programs?.some((program) => checkProgramPermissions(program, names));
};

export const useGetProgramsWhereUserHasPermission = (names) => {
	const { user } = useSelector((state) => state.auth);
	return user?.programs?.filter((program) => checkProgramPermissions(program, names));
};

export const checkRolePermission = (role, names) => {
	return role?.permissions?.some((permission) => names.includes(permission.name));
};
