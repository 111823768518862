import React, { useEffect, useState } from "react";
import EditCompanyUserView from "./EditCompanyUserView";
import { updateUserApi, useGetRoles } from "services/CompanyService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { hideLoader, showLoader } from "../../../../../redux/reducers/LoaderSlice";
import { handleApiResponseError } from "utils/helpers/Common";
import { getProgramRoleOptions, findRole } from "utils/helpers/Company";
import { COMPANY_MANAGER } from "utils/constants/UserRoles";
import { COMPANY_STANDARD_PROGRAM } from "utils/constants/Constants";
import { filterCompanyPrograms } from "utils/helpers/Common";

const EditCompanyUser = ({ show, handleClose, user, usersMutate, company, programId = null }) => {
	const [formValues, setFormValues] = useState({
		company_id: "",
		role_id: "",
		programs: [{ program_id: "", role_id: "" }],
	});
	const [errors, setErrors] = useState({});
	const [showPrograms, setShowPrograms] = useState(false);
	const [programRoleOptions, setProgramRoleOptions] = useState([]);
	const [companyPrograms, setCompanyPrograms] = useState([]);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { roles } = useGetRoles();

	useEffect(() => {
		setFormValues({
			company_id: company?.id,
			role_id: user?.role?.id,
			programs:
				user?.programs?.length > 0
					? user?.programs?.map((program) => ({
							program_id: program?.id,
							role_id: program?.role?.id,
					  }))
					: [{ program_id: "", role_id: "" }],
		});
		if (user?.role?.name !== COMPANY_MANAGER) {
			const options = getProgramRoleOptions(user?.role?.name, roles);
			setProgramRoleOptions(options);
			setShowPrograms(true);
		}
	}, [user, company]);

	useEffect(() => {
		if(company) setCompanyPrograms(filterCompanyPrograms({"company": company, "programId": programId}));
	}, [company, programId]);

	const handleAdd = () => {
		setFormValues({
			...formValues,
			programs: [...formValues.programs, { program_id: "", role_id: "" }],
		});
	};

	const handleRemoveProgram = (index) => {
		if (!formValues.role_id && formValues.programs.length === 1) {
			toast.error("User must have at least one program");
		} else {
			const newPrograms = formValues.programs.filter((_, i) => i !== index);
			setFormValues({ ...formValues, programs: newPrograms });
		}
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
		if (name === "role_id") {
			const role = roles?.find((role) => role.id == value);
			if (role?.name === COMPANY_MANAGER) {
				setShowPrograms(false);
			} else {
				setShowPrograms(true);
				const options = getProgramRoleOptions(role?.name, roles);
				setProgramRoleOptions(options);
			}
		}
	};

	const handleProgramChange = (index, e) => {
		const { name, value } = e.target;
		const program = formValues.programs.find((item) => item.program_id == value && name != "role_id");
		if (!program) {
			const newPrograms = formValues.programs.map((program, i) => (i === index ? { ...program, [name]: value } : program));
			setFormValues({ ...formValues, programs: newPrograms });
		}
	};

	const validate = () => {
		let formErrors = {};
		const role = findRole(roles, formValues.role_id);
		if (role?.name !== COMPANY_MANAGER && company?.programs?.length > 0) {
			formValues.programs.forEach((program, index) => {
				if (!formValues.role_id && !program.program_id && index === 0) {
					formErrors[`program_id-${index}`] = "Program is required";
				}
				if (program.program_id && !program.role_id) {
					formErrors[`role_id-${index}`] = "Role is required";
				}
			});
		}

		return formErrors;
	};

	const handleSubmit = () => {
		const formErrors = validate();
		if (Object.keys(formErrors).length === 0) {
			formValues.programs = formValues.programs.filter((program) => program.program_id && program.role_id);
			if (!formValues.role_id && formValues.programs.length === 0) {
				toast.error("User must have at least one program");
				return;
			}
			dispatch(showLoader());
			updateUserApi(company.id, user.id, formValues)
				.then(() => {
					handleClose();
					usersMutate();
					toast.success("User updated successfully");
				})
				.catch((err) => {
					handleApiResponseError("", err.response, navigate, "/setting/company");
				})

				.finally(() => {
					dispatch(hideLoader());
				});
		} else {
			setErrors(formErrors);
		}
	};
	
	return (
		<EditCompanyUserView
			show={show}
			handleClose={handleClose}
			handleSubmit={handleSubmit}
			errors={errors}
			roles={roles}
			user={user}
			formValues={formValues}
			handleChange={handleChange}
			handleProgramChange={handleProgramChange}
			handleAdd={handleAdd}
			handleRemoveProgram={handleRemoveProgram}
			programRoleOptions={programRoleOptions}
			showPrograms={showPrograms}
			programs={companyPrograms}
			programId={programId}
		/>
	);
};

export default EditCompanyUser;
