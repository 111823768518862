import React from "react";
import Header from "layouts/header/Header";
import SettingSidebar from "pages/setting/sidebar/SettingSidebar";
import TabsList from "components/tabs/TabsList";
import MeasureTable from "./measure/MeasureTable";
import { useCheckHasAdminPermission } from "utils/helpers/Common";
import { EDIT_COMPANY_MEASURES_STAGES } from "utils/constants/UserPermissions";
import { useSelector } from "react-redux";
const SettingView = ({ activeTab, setActiveTab, isInternalUser }) => {
  const user = useSelector(store => store.auth?.user);
	const tabsData = [];
	const costingTab = {
		title: "Measures",
		body: activeTab == 0 && <MeasureTable />,
	};

	if (useCheckHasAdminPermission([EDIT_COMPANY_MEASURES_STAGES])) {
		tabsData.push(costingTab);
	}

  return (
    <div className="wrapperArea">
      <Header title={user?.impersonated ? `Account Management (Impersonated as ${user?.first_name} ${user?.last_name})` : "Account Management"} />
      <div className="wrapperContainer">
        <SettingSidebar isSuperAdmin={isInternalUser} />
        <div className="wrappePageArea wrappePd">
          <div className="pageArea ">
            <div className="pageHead d-flex align-items-center gap-3 mb-4">
              <div>
                <h4 className="pageTitle">Settings</h4>
                <p className="pgText">Manage settings</p>
              </div>
            </div>
            <div>
              <TabsList
                listData={tabsData}
                activeIndex={activeTab}
                onChange={setActiveTab}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingView;
