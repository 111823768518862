import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CompanyUserListView from "./CompanyUserListView";
import { updateCompanyUserStatusApi, useGetCompanyUsers, useGetInvitedUsers, revokeInviteApi } from "services/CompanyService";
import { COMPANY_MANAGER } from "utils/constants/UserRoles";

const CompanyUserList = ({ company, authUser, programId = null }) => {
	const [selectedUser, setSelectedUser] = useState({ user: null, action: "" });
	const [selectedProgram, setSelectedProgram] = useState(null);
	const [filteredUsers, setFilteredUsers] = useState([]);

	const { users, usersMutate } = useGetCompanyUsers(company?.id, programId);
	const { invitedUsers, invitedUsersMutate } = useGetInvitedUsers(company?.id, programId);

	useEffect(() => {
		if (selectedProgram) {
			const filteredData = users.filter((user) => {
				return user.programs.find((program) => program.id == selectedProgram) || user?.role?.name === COMPANY_MANAGER;
			});
			setFilteredUsers(filteredData);
		} else {
			setFilteredUsers(users);
		}
	}, [selectedProgram, users]);

	const handleUserStatusChange = () => {
		if (selectedUser?.action === "revoke-invite") {
			revokeInviteApi(company.id, selectedUser?.user?.id)
				.then((res) => {
					toast.success("Invite revoked successfully");
					setSelectedUser({ user: null, action: "" });
					invitedUsersMutate();
				})
				.catch((err) => {
					toast.error(err.response?.data?.message);
				});
		} else {
			updateCompanyUserStatusApi(company.id, selectedUser?.user?.id, {
				active_status: selectedUser?.action === "suspend" ? false : true,
				company_id: company.id,
				program_id: programId,
			})
			.then((res) => {
				toast.success("User status updated successfully");
				setSelectedUser({ user: null, action: "" });
				usersMutate();
			})
			.catch((err) => {
				toast.error(err.response?.data?.message);
			});
		}
	};

	return (
		<CompanyUserListView
			users={filteredUsers}
			company={company}
			selectedUser={selectedUser}
			setSelectedUser={setSelectedUser}
			usersMutate={usersMutate}
			handleUserStatusChange={handleUserStatusChange}
			authUser={authUser}
			selectedProgram={selectedProgram}
			setSelectedProgram={setSelectedProgram}
			invitedUsers={invitedUsers}
			programId={programId}
			invitedUsersMutate={invitedUsersMutate}
		/>
	);
};

export default CompanyUserList;
