import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import FrmLogo from "assets/images/logo.svg";
import { checkStringPattern } from "utils/helpers/Common";
import PasswordRule from "components/passwordRule/PasswordRule";
const ResetPasswordView = ({
  register,
  handleSubmit,
  errors,
  show,
  handleClose,
  password,
  setPassword,
}) => {
  return (
    <Modal show={show} onHide={handleClose} className="signInMoal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalBodyArea">
        <img src={FrmLogo} className="mdlFrmLogo" />
        <div className="modalContent">
          <h3 className="mdlTitle mb-5">Reset Your Password</h3>
          <p className="mdlText mb-4">
            Create a new password for your account.
          </p>

          <Form className="formArea">
            <Row>
              <Col md="12">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="labelText">New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    className="inputField"
                    {...register("password", {
                      onChange: (e) => {
                        setPassword(e.target.value);
                      },
                    })}
                  />
                  {errors.password && (
                    <p className="text-danger">{errors.password.message}</p>
                  )}
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="labelText">
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    className="inputField"
                    {...register("password_confirmation")}
                  />
                  {errors.password_confirmation && (
                    <p className="text-danger">
                      {errors.password_confirmation.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <PasswordRule password={password} color={"green"} />
            </Row>
            <Row>
              <button
                className="frmMdlBtn mrLauto mt-5 "
                type="button"
                onClick={() => handleSubmit(handleClose)}
              >
                Reset
              </button>
            </Row>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPasswordView;
