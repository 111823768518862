import React, { useEffect, useState } from "react";
import CompanyDetailView from "./CompanyDetailView";
import { useMatch, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetCompanyDetails, useGetCompanyUserRole, useGetCompanyUserPrograms } from "services/CompanyService";
import { useSelector, useDispatch } from "react-redux";
import { setUserRole, setUserPrograms } from "../../../../redux/reducers/AuthSlice";
import { useCheckHasInternalRole } from "utils/helpers/Common";
import { COMPANY_MEASURES_TAB, COMPANY_PRICING_TAB, COMPANY_STAGES_TAB } from "utils/constants/Constants";

const CompanyDetail = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [activeTab, setActiveTab] = useState(0);
	const [successAlert, setSuccessAlert] = useState(false);
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isCostingUrl = useMatch("/setting/company/:id/measures/:type");
	const isStagesUrl = useMatch("/setting/company/:id/stages/:type");
	const { user } = useSelector((state) => state.auth);
	const { companyRole } = useGetCompanyUserRole(id, user.id);
	const { programs } = useGetCompanyUserPrograms(id, user.id);
	const { company, companyMutate } = useGetCompanyDetails(id);
	const isInternalUser = useCheckHasInternalRole();

	useEffect(() => {
		if (searchParams.get("pricing_plan") && searchParams.get("success") == 1) {
			setActiveTab(COMPANY_PRICING_TAB);
		} else if (searchParams.get("pricing_plan") == 1 && searchParams.get("success") == 0) {
			setActiveTab(COMPANY_PRICING_TAB);
			setSearchParams([]);
		}
	}, [searchParams]);

	useEffect(() => {
		if (isCostingUrl) {
			setActiveTab(COMPANY_MEASURES_TAB);
		}
		if (isStagesUrl) {
			setActiveTab(COMPANY_STAGES_TAB);
		}
	}, [isCostingUrl, isStagesUrl]);

	useEffect(() => {
		dispatch(setUserRole(companyRole));
		dispatch(setUserPrograms(programs));
	}, [companyRole, programs]);

	const handleBackButton = () => {
		navigate("/setting/company");
	};

	return (
		<CompanyDetailView
			company={company}
			companyMutate={companyMutate}
			handleBackButton={handleBackButton}
			activeTab={activeTab}
			setActiveTab={setActiveTab}
			successAlert={successAlert}
			setSuccessAlert={setSuccessAlert}
			searchParams={searchParams}
			setSearchParams={setSearchParams}
			isInternalUser={isInternalUser}
			user={user}
		/>
	);
};

export default CompanyDetail;
