import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import LoaderSlice from "./LoaderSlice";
import AuthSlice from "./AuthSlice";
import companySlice from "./companySlice";

const PersistConfig = {
  key: "root",
  storage,
};
const reducers = combineReducers({
  auth: AuthSlice,
  loader: LoaderSlice,
  company: companySlice,
});
const PersistedReducer = persistReducer(PersistConfig, reducers);

export default PersistedReducer;
