import React, { useEffect, useState } from "react";
import PricingPlanView from "./PricingPlanView";
import { addCardApi, cancelPlanApi, defaultCardApi, deleteCardApi, subscribePlanApi, useGetPaymentMethods, useGetPricingPlans } from "services/CompanyService";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/reducers/LoaderSlice";
import { toast } from "react-toastify";
import { formatDateToCustomString } from "utils/helpers/Common";

const PricingPlan = ({ company, searchParams, setSearchParams, setSuccessAlert, showManageCard = true, showFooterText = true, handleBack, handleContinue, isOnboarding = false }) => {
	const [selectedPlan, setSelectedPlan] = useState(null);
	const [cancelPlan, setCancelPlan] = useState(false);
	const [changePlan, setChangePlan] = useState(null);
	const [changeAlert, setChangeAlert] = useState({ type: null, message: null });
	const [addCard, setAddCard] = useState(false);
	const { plans, plansMutate } = useGetPricingPlans(company);
	const selectedCustomPlan = plans?.find(plan => plan.is_custom && plan.selected);
	const selectedPlanWithNextCustom = plans?.find(plan => plan.selected && plan.next_plan?.is_custom);
	const nonCustomPlans = plans?.filter(plan => !plan.is_custom);
	const nextCustomPlan = selectedPlanWithNextCustom ? 
		plans?.find(plan => plan.id === selectedPlanWithNextCustom.next_plan.id) : null;
	const filteredPlans = selectedCustomPlan ? 
		[selectedCustomPlan, ...nonCustomPlans] : 
		selectedPlanWithNextCustom && nextCustomPlan ? 
			[...nonCustomPlans, {...nextCustomPlan, starts_at: selectedPlanWithNextCustom.ends_at}] : 
			nonCustomPlans;
	const { cards, cardsMutate } = useGetPaymentMethods(company);
	const dispatch = useDispatch();

	useEffect(() => {
		if (plans?.length > 0) {
			const currentPlan = plans.find((plan) => plan.selected == true);
			if (currentPlan) {
				setSelectedPlan(currentPlan);
				if (searchParams?.get("pricing_plan") && searchParams?.get("success") == 1 && currentPlan?.id == searchParams.get("plan_id")) {
					setSuccessAlert(true);
					setSearchParams([]);
				}
			}
		}
	}, [plans]);

	const handleChoosePlan = (plan, resubscribe = false) => {
		dispatch(showLoader());
		subscribePlanApi({
			plan_id: plan.id,
			company_id: company.id,
			upgrade: plan?.price > selectedPlan?.price ? true : false,
			downgrade: plan?.price < selectedPlan?.price ? true : false,
			is_onboarding: isOnboarding,
			resubscribe,
		})
			.then((res) => {
				if (res?.data?.url) window.location.href = res.data.url;
				else {
					setChangeAlert({
						type: resubscribe ? "resubscribe" : "change",
						message: resubscribe
							? "Your Package has been resubscribed successfully."
							: ` You have successfully unsubscribed from your current plan
                  which ends on
                   ${formatDateToCustomString(selectedPlan.ends_at)}. Your
                  new plan will start at the beginning of your next billing
                  cycle,
                  ${formatDateToCustomString(selectedPlan.ends_at, 1)}`,
					});
					plansMutate();
				}
			})
			.catch((err) => {
				toast.error(err.response.data.message);
			})
			.finally(() => dispatch(hideLoader()));
	};

	const handleCancelPlan = () => {
		dispatch(showLoader());
		cancelPlanApi(company.id, {
			company_id: company.id,
			plan_id: selectedPlan.id,
		})
			.then((res) => {
				setCancelPlan(false);
				setChangeAlert({
					type: "cancel",
					message: `   You have successfully unsubscribed from your current plan
                  which ends on
                  ${formatDateToCustomString(selectedPlan?.ends_at)}. Your
                  new plan will default to Plan 1 under which you will be
                  charged per project. You will still be able to access your
                  account and view existing projects. If you subscribe to a new
                  plan, it will start at the beginning of your next billing
                  cycle.`,
				});
				plansMutate();
			})
			.catch((err) => {
				toast.error(err.response.data.message);
			})
			.finally(() => dispatch(hideLoader()));
	};

	const handleDefaultCard = (cardId) => {
		dispatch(showLoader());
		defaultCardApi(company.id, cardId)
			.then((res) => {
				toast.success(res.data.message);
				cardsMutate();
			})
			.catch((err) => {
				toast.error(err.response.data.message);
			})
			.finally(() => dispatch(hideLoader()));
	};

	const handleDeleteCard = (cardId) => {
		dispatch(showLoader());
		deleteCardApi(cardId, company.id)
			.then((res) => {
				toast.success(res.data.message);
				cardsMutate();
			})
			.catch((err) => {
				toast.error(err.response.data.message);
			})
			.finally(() => dispatch(hideLoader()));
	};

	const handleAddCard = (data) => {
		dispatch(showLoader());
		addCardApi(company.id, data)
			.then((res) => {
				toast.success(res.data.message);
				setAddCard(false);
				cardsMutate();
			})
			.catch((err) => {
				toast.error(err.response.data.message);
			})
			.finally(() => dispatch(hideLoader()));
	};
	return (
		<PricingPlanView
			plans={filteredPlans}
			handleChoosePlan={handleChoosePlan}
			company={company}
			selectedPlan={selectedPlan}
			handleCancelPlan={handleCancelPlan}
			cancelPlan={cancelPlan}
			setCancelPlan={setCancelPlan}
			changePlan={changePlan}
			setChangePlan={setChangePlan}
			cards={cards}
			handleDefaultCard={handleDefaultCard}
			handleDeleteCard={handleDeleteCard}
			addCard={addCard}
			setAddCard={setAddCard}
			handleAddCard={handleAddCard}
			changeAlert={changeAlert}
			setChangeAlert={setChangeAlert}
			showManageCard={showManageCard}
			showFooterText={showFooterText}
			handleBack={handleBack}
			handleContinue={handleContinue}
		/>
	);
};

export default PricingPlan;
