import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import FrmLogo from "assets/images/logo.svg";
import "./SignInStyle.css";
import ForgotPassword from "../forgotPassword/ForgotPassword";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SignInView = ({
  handleClose,
  handleShow,
  register,
  handleSubmit,
  errors,
  show,
  showForgotPasswordModal,
  setShowForgotPasswordModal,
  passwordType,
  togglePasswordType,
}) => {
  return (
    <>
      <Button variant="primary" onClick={handleShow} className="signInBtn">
        Sign in
      </Button>

      <Modal show={show} onHide={handleClose} className="signInMoal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modalBodyArea">
          <img src={FrmLogo} className="mdlFrmLogo" />
          <div className="modalContent">
            <h3 className="mdlTitle">Sign in</h3>
            <p className="mdlText mb-3">
              Enter your email and password to sign into your existing OptiMiser
              Account. If you do not have an account close this window and
              register a new company or join an existing company (invite
              required).
            </p>

            <Form className="formArea">
              <Row>
                <Col md="12">
                  <Form.Group
                    className="mb-2"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="labelText">Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      className="inputField"
                      {...register("email")}
                    />
                    {errors.email && (
                      <p className="text-danger">{errors.email.message}</p>
                    )}
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group 
                    className="mb-3" 
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="labelText">Password</Form.Label>
                    <div className="input-container" style={{ position: 'relative' }}>
                      <Form.Control
                        type={passwordType}
                        placeholder="Password"
                        className="inputField"
                        {...register("password")}
                        style={{ paddingRight: '35px' }} 
                      />
                      <p
                        className="eye-icon"
                        onClick={() => {
                          togglePasswordType('password');
                        }}
                      >
                        <FontAwesomeIcon icon={passwordType === 'password' ? faEye : faEyeSlash} />
                      </p>
                    </div>

                    {errors.password && (
                      <p className="text-danger">{errors.password.message}</p>
                    )}
                    <span
                      className="frmLink"
                      onClick={() => {
                        setShowForgotPasswordModal(true);
                        handleClose();
                      }}
                    >
                      Forgot Password
                    </span>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <button
                  className="frmMdlBtn mrLauto mt-5"
                  type="button"
                  onClick={handleSubmit}
                >
                  Login
                </button>
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      <ForgotPassword
        show={showForgotPasswordModal}
        handleClose={() => {
          setShowForgotPasswordModal(false);
          handleShow();
        }}
      />
    </>
  );
};

export default SignInView;
