import React from "react";
import MeasureTableInput from "./MeasureTableInput";
import deleteIcon from "assets/images/icons/delete-icon.svg";
import cloneIcon from "assets/images/icons/clone-icon.svg";
import revertIcon from "assets/images/icons/revert-icon.svg";
const MeasureTableRow = ({ lineItem, headers, handleChange, setSelectedMeasure, canEdit, canClone, canDeprecate }) => {
	return (
		<tr>
			<td>
				<div className="btnGroup">
					{canDeprecate && (
						<button
							className="tblActionBtn"
							onClick={() =>
								setSelectedMeasure({
									id: lineItem["id"],
									is_deprecated: lineItem["is_deprecated"],
									action: lineItem["is_deprecated"] ? "undeprecate" : "deprecate",
								})
							}
						>
							<img src={lineItem["is_deprecated"] ? revertIcon : deleteIcon} alt="" />
						</button>
					)}
					{canClone && (
						<button className="tblActionBtn" onClick={() => setSelectedMeasure({ id: lineItem["id"], action: "clone" })}>
							<img src={cloneIcon} alt="" />
						</button>
					)}
				</div>
			</td>
			{headers
				.filter((item) => item.value !== "program")
				.map((header, index) => (
					<td className="tblTd" key={index}>
						{header.value == "measure_category_name" ? (
							<p className="tblText">{lineItem[header.value]}</p>
						) : (
							<MeasureTableInput data={lineItem[header.value]} fieldId={lineItem["id"]} header={header.value} handleChange={handleChange} isReadOnly={!canEdit} />
						)}
					</td>
				))}
		</tr>
	);
};

export default MeasureTableRow;
