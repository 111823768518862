import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	company: null,
	stageTitle: null,
	onboarding: false,
	onboardingBackUrl: null,
};

const companySlice = createSlice({
	name: "company",
	initialState,
	reducers: {
		setCompany: (state, action) => {
			state.company = action.payload;
		},
		setStageTabData: (state, action) => {
			state.stageTitle = action.payload;
		},
		setOnboarding: (state, action) => {
			state.onboarding = action.payload;
		},
		setOnboardingBackUrl: (state, action) => {
			state.onboardingBackUrl = action.payload;
		},
	},
});
export const { setCompany, setStageTabData, setOnboarding, setOnboardingBackUrl } = companySlice.actions;
export default companySlice.reducer;
