import React from "react";
import Row from "react-bootstrap/Row";
import "./PricingPlanStyles.css";

import CancelSubscriptionAlert from "components/alert/confirmation/CancelSubscriptionAlert";
import ChangePlanAlert from "components/alert/confirmation/ChangePlanAlert";

import AddCard from "../card/add/AddCard";
import ListCard from "../card/ListCard";
import { calculateRemainingDays, formatDateToCustomString, formatWithCommas } from "utils/helpers/Common";
import { PLAN_FIVE } from "utils/constants/Constants";

const PricingPlanView = ({
	plans,
	handleChoosePlan,
	company,
	selectedPlan,
	handleCancelPlan,
	cancelPlan,
	setCancelPlan,
	changePlan,
	setChangePlan,
	cards,
	handleDefaultCard,
	handleDeleteCard,
	addCard,
	setAddCard,
	handleAddCard,
	changeAlert,
	setChangeAlert,
	showManageCard,
	showFooterText,
	handleBack,
	handleContinue,
}) => {
	return (
		<>
			<div className="pageCard">
				<div className="mb-4">
					<h4 className="h4MdTitle mb-2">Pricing Plans</h4>
					<p className="smText mb-4">
						Pricing plans are billed monthly. Each plan includes a set number of projects, with an additional fee for each extra project. Unused projects do not roll over, and no refunds
						are issued in the event of cancellation.
					</p>
					{selectedPlan?.id != selectedPlan?.next_plan?.id && selectedPlan?.next_plan?.price != 0 && (
						<div className="textboxBar">
							<p>
								You have successfully unsubscribed from your current plan which ends on
								{` ${formatDateToCustomString(selectedPlan.ends_at)}`}. Your new plan will start at the beginning of your next billing cycle,
								{` ${formatDateToCustomString(selectedPlan.ends_at, 1)}`}.
							</p>
						</div>
					)}

					<div className="tableScrollArea">
						<table className="tableArea pricingTable mb-3 pricingTableth">
							<thead>
								<tr>
									<th className="tblTh">
										<p className="tblText">Name</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Projects Included</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Cost</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Each Additional Project*</p>
									</th>
									<th className="tblTh">
										<p className="tblText">Tech Support</p>
									</th>

									<th className="tblTh">
										<p className="tblText">Action</p>
									</th>
								</tr>
							</thead>
							<div className="tableSpace"></div>
							<tbody>
								{plans?.map((plan, index) => (
									<tr className={`${plan.selected ? "active" : ""}`} key={index + "plan"}>
										<td className="tblTd">
											<button className="mdRadiolBtn">{plan?.title}</button>
										</td>
										<td className="tblTd">
											<div className="tblFlexBar">
												<p className="tblText">{plan?.features?.jobs_per_month_include + " / month"}</p>
											</div>
										</td>
										<td className="tblTd">
											<div className="tblFlexBar">
												<p className="tblText">{`$${formatWithCommas(plan.price)} ${index == 0 ? " " : "/ month"}`}</p>
											</div>
										</td>
										<td className="tblTd">
											<div className="tblFlexBar">
												<p className="tblText">${`${plan?.features?.each_additional_job} / Project`}</p>
											</div>
										</td>
										<td className="tblTd">
											<div className="tblFlexBar">
											{plan?.title == PLAN_FIVE ? <span>1 hour/month</span> : <span>-</span>}
											</div>
										</td>

										<td className="tblTd">
											{plan.selected ? (
												<button
													className={`chooseBtn text-danger  textLine`}
													type="button"
													onClick={() => {
														if (!plan.canceled) setCancelPlan(true);
														else handleChoosePlan(plan, true);
													}}
													disabled={plan.price == 0 ? true : false}
												>
													<p>{plan.price == 0 ? "Selected" : plan.canceled ? `Resubscribe` : "Unsubscribe"}</p>

													{plan.canceled && (
														<p className="textLineNone">
															{`Ends 
                              ${formatDateToCustomString(selectedPlan?.ends_at)}`}
														</p>
													)}
												</button>
											) : (
												<button
													className={`chooseBtn ${selectedPlan?.next_plan?.id == plan?.id && selectedPlan?.next_plan?.price != 0 ? "textLine" : ""} `}
													type="button"
													disabled={selectedPlan?.next_plan?.id == plan?.id ? true : false}
													onClick={() => {
														if (selectedPlan?.price > 0 || selectedPlan?.price === 0) {
															setChangePlan(plan);
														} else {
															handleChoosePlan(plan);
														}
													}}
												>
													<p>
														{`${
															selectedPlan?.next_plan?.id == plan?.id && selectedPlan?.next_plan?.price != 0
																? "New Plan"
																: plan?.price > selectedPlan?.price
																? "Upgrade"
																: plan?.price < selectedPlan?.price
																? "Downgrade"
																: "Select"
														}`}
													</p>

													{selectedPlan?.next_plan?.id == plan?.id && selectedPlan?.next_plan?.price != 0 && (
														<p className="textLineNone">
															{`Starts 
                            ${formatDateToCustomString(selectedPlan.ends_at, 1)}`}
														</p>
													)}
												</button>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					{showFooterText && (
						<>
							<div className="noteBar">
								<p>
									<span>Notes:</span> Monthly plans are set to auto-renew. <br />
									There are no refunds and unused projects do not roll over.
								</p>
							</div>
							<p className="smText mb-4">*Each Additional Job is the price per job once you have exceeded your monthly included projects.</p>

							<Row>
								<div className="checkboxList">
									<h5 className="checkListTtile bold700">System Requirements</h5>
									<p className="smText mb-4">
										OptiMiser works in your browser on any device (desktop, phone, tablet). We know you need to work anywhere, so we designed our software so your audits can be
										completed in the field without cellular or internet access.
									</p>
								</div>
							</Row>
							<h4 className="h4MdTitle mb-2">Have questions or need a custom solution?</h4>
							<p className="smText mb-4">
								Contact us:
								<a className="ml-4" href="mailto:support@optimiserenergy.com">
									support@optimiserenergy.com
								</a>
							</p>
						</>
					)}
				</div>

				{showManageCard && (
					<>
						<div className="d-flex align-items-center">
							<h4 className="h4MdTitle mb-3">Payment Methods <span className="invoiceTagBtn">{company?.billing_type}</span></h4>
							
						</div>
						<div className="d-flex align-items-center">
							<h5 className="h5MdTitle mb-3">Credit Cards</h5>
							<button className="btn btn-primary mb-3 ms-2 py-0" onClick={() => setAddCard(true)}>
								Add Card
							</button>
						</div>
						{cards?.length > 0 && <ListCard cards={cards} handleDefaultCard={handleDefaultCard} handleDeleteCard={handleDeleteCard} />}
					</>
				)}

				{handleBack && handleContinue && (
					<div className="d-flex align-items-center gap-3 justify-content-end footerBtnBar  px-3">
						<button className="btn mdBtn border" onClick={handleBack}>
							Back
						</button>
						<button className="btn mdBtn primaryBtn" onClick={handleContinue}>
							Continue
						</button>
					</div>
				)}
			</div>
			{cancelPlan && <CancelSubscriptionAlert show={cancelPlan} plan={selectedPlan} handleClose={() => setCancelPlan(false)} handleConfirm={handleCancelPlan} />}
			{changePlan && (
				<ChangePlanAlert
					title={changePlan.price > selectedPlan.price ? "Upgrade your Plan" : "Downgrade your Plan"}
					type={changePlan.price > selectedPlan.price ? "upgrade" : "downgrade"}
					description={
						selectedPlan.price == 0
							? ""
							: `There are still ${calculateRemainingDays(selectedPlan.ends_at)}  days left on your current plan, the new plan will take effect once your current plan period ends.`
					}
					confirmText={`Select agree to process the ${changePlan.price > selectedPlan.price ? "upgrade" : "downgrade"}`}
					show={changePlan}
					handleClose={() => setChangePlan(false)}
					handleConfirm={() => {
						setChangePlan(null);
						handleChoosePlan(changePlan);
					}}
					confirmBtnText={"Agree"}
				/>
			)}
			{changeAlert.type && (
				<ChangePlanAlert
					title={changeAlert.type == "cancel" ? "Plan Canceled" : changeAlert.type == "resubscribe" ? "Plan Resubscribed" : "Plan Changed "}
					show={changeAlert.type}
					description={changeAlert.message}
					handleClose={() => setChangeAlert({ type: null, message: null })}
					confirmBtnText={"Done"}
					handleConfirm={() => setChangeAlert({ type: null, message: null })}
				/>
			)}
			{addCard && <AddCard show={addCard} handleClose={() => setAddCard(false)} handleAddCard={handleAddCard} firstCard={cards?.length < 1 ? true : false} />}
		</>
	);
};

export default PricingPlanView;
