import React from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { states } from "utils/constants/Constants";
import Select from "react-select";
import { styleSelect } from "utils/helpers/Common";
import FieldLabel from "components/form/label/FieldLabel";
const AddCompanyView = ({ show, handleClose, register, handleSubmit, errors, Controller, control, programs, handleProgramSelect, handleProgramRemove, programDefaultValue, selectedPrograms }) => {
	return (
		<Modal show={show} onHide={handleClose} className="frmModalArea">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<h5 className="h5Title mb-3">Create New Company</h5>
				<Form className="formArea">
					<div className="mobVtScroll">
						<Row>
							<Col md="6">
								<Form.Group className="mb-3" controlId="name">
									<FieldLabel label="Name" required />
									<Form.Control type="text" placeholder="Name" className="inputField " {...register("name")} />
									{errors.name && <p className="text-danger">{errors.name.message}</p>}
								</Form.Group>
							</Col>
							<Col md="6">
								<Form.Group className="mb-3" controlId="office_phone">
									<Form.Label className="labelText">Phone Number</Form.Label>
									<Form.Control type="text" placeholder="Phone Number" className="inputField " {...register("office_phone")} />
									{errors.office_phone && <p className="text-danger">{errors.office_phone.message}</p>}
								</Form.Group>
							</Col>
							{programs?.length > 0 && (
								<>
									<Col md="12">
										<Form.Group controlId="programs">
											<Form.Label className="labelText">Programs</Form.Label>
											<Select
												options={[
													...(programs?.map((program) => ({
														label: program.name,
														value: program.id,
													})) ?? []),
												]}
												onChange={handleProgramSelect}
												value={programDefaultValue}
												defaultValue={programDefaultValue}
												styles={styleSelect}
											/>
											{errors.programs && <p className="text-danger">{errors.programs.message}</p>}
										</Form.Group>
									</Col>
									<Col md="12">
										<div className="multiSelectTagsBox">
											<duv className="multiSelectTagsBox">
												{selectedPrograms.map((program, pIndex) => (
													<div className="multiSelectItem" key={"program" + pIndex}>
														<span>{program.label}</span>
														<button className="crossBtn" type="button" onClick={() => handleProgramRemove(pIndex)}>
															<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M1 9L9 1M1 1L9 9" stroke="#019550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
															</svg>
														</button>
													</div>
												))}
											</duv>
										</div>
									</Col>
								</>
							)}
							<Col md="12">
								<Form.Group className="mb-3" controlId="address">
									<Form.Label className="labelText">Address</Form.Label>
									<Form.Control type="text" placeholder="Address" className="inputField " {...register("address")} />
									{errors.address && <p className="text-danger">{errors.address.message}</p>}
								</Form.Group>
							</Col>
							<Col md="6">
								<Form.Group className="mb-3" controlId="city">
									<Form.Label className="labelText">City</Form.Label>
									<Form.Control type="text" placeholder="City" className="inputField " {...register("city")} />
									{errors.city && <p className="text-danger">{errors.city.message}</p>}
								</Form.Group>
							</Col>
							<Col md="6">
								<Form.Group className="mb-3" controlId="state">
									<FieldLabel label="State" required />
									<Controller
										name="state"
										control={control}
										render={({ field: { onChange, value } }) => <Select options={states} onChange={onChange} value={value} styles={styleSelect} />}
									/>
									{errors.state?.label && <p className="text-danger">{errors.state.label.message}</p>}
								</Form.Group>
							</Col>
							<Col md="6">
								<Form.Group className="mb-3" controlId="zip_code">
									<Form.Label className="labelText">Zip</Form.Label>
									<Form.Control type="number" placeholder="Zip" className="inputField " {...register("zip_code")} />
									{errors.zip_code && <p className="text-danger">{errors.zip_code.message}</p>}
								</Form.Group>
							</Col>
							<Col md="6">
								<Form.Group className="mb-3" controlId="website">
									<Form.Label className="labelText">Website</Form.Label>
									<Form.Control type="text" placeholder="Website" className="inputField " {...register("website")} />
									{errors.website && <p className="text-danger">{errors.website.message}</p>}
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md="12">
								<svg width="100%" height="2" viewBox="0 0 100% 2" fill="none" xmlns="http://www.w3.org/2000/svg">
									<line x1="-3.05176e-05" y1="1" x2="100%" y2="1" stroke="#CFD4D9" stroke-width="2" stroke-dasharray="4 4" />
								</svg>
							</Col>
						</Row>
					</div>
					<Row>
						<Col md="12">
							<button className="fillPrimarBtn mrLauto mt-4 InviteBtn" type="button" onClick={() => handleSubmit(handleClose)}>
								Create
							</button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default AddCompanyView;
