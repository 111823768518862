import React, { useState } from "react";
import { useJoinCompanyForm, useGetCompanyInvite } from "services/AuthService";
import JoinCompanyView from "./JoinCompanyView";
import OptimiserModal from "components/Modal/OptimiserModal";

const JoinCompany = ({ searchParams }) => {
	const [password, setPassword] = useState(null);
	const { invite, error } = useGetCompanyInvite(searchParams.get("company-invitation"));
	const { register, errors, handleSubmit } = useJoinCompanyForm(invite);
	if (!invite) {
		return <OptimiserModal show={true} handleClose={() => {}} text={error?.message} navigateUrl="/?enforce_tab1=true" />;
	}
	return <JoinCompanyView register={register} errors={errors} handleSubmit={handleSubmit} password={password} setPassword={setPassword} companyName={invite?.company?.name} />;
};

export default JoinCompany;
