import React from "react";
import { checkStringPattern } from "utils/helpers/Common";
import "./PasswordRuleStyle.css";
const PasswordRule = ({ password, color }) => {
  return (
    <div className={`checkboxList ${color == "green" ? "bgGreen" : ""} `}>
      <h5 className="checkListTtile">Password Requirements:</h5>
      <div class="form-check checkBoxbar">
        <input
          class="form-check-input circleCheck"
          type="checkbox"
          value=""
          id="check-1"
          checked={checkStringPattern(/[^a-zA-Z0-9]/, password)}
        />
        <label class="form-check-label checkLabel" for="check-1">
          Password must contain at least one special or punctuation character.
        </label>
      </div>
      <div class="form-check checkBoxbar">
        <input
          class="form-check-input circleCheck"
          type="checkbox"
          value=""
          id="check-2"
          checked={password?.length >= 8 ? true : false}
        />
        <label class="form-check-label checkLabel" for="check-2">
          Password must be at least 8 characters in length.
        </label>
      </div>
      <div class="form-check checkBoxbar">
        <input
          class="form-check-input circleCheck"
          type="checkbox"
          value=""
          id="check-3"
          checked={
            checkStringPattern(/[A-Z]/, password) &&
            checkStringPattern(/[a-z]/, password)
          }
        />
        <label class="form-check-label checkLabel" for="check-3">
          Password must contain at least one uppercase and at least one
          lowercase character.
        </label>
      </div>
      <div class="form-check checkBoxbar">
        <input
          class="form-check-input circleCheck"
          type="checkbox"
          value=""
          id="check-4"
          checked={checkStringPattern(/\d/, password)}
        />
        <label class="form-check-label checkLabel" for="check-4">
          Password must contain at least one number.
        </label>
      </div>
    </div>
  );
};
export default PasswordRule;
