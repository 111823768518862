import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import FrmLogo from "assets/images/logo.svg";
import Alert from "react-bootstrap/Alert";
const ForgotPasswordView = ({
  register,
  handleSubmit,
  errors,
  show,
  handleClose,
  showAlert,
  setShowAlert,
}) => {
  return (
    <Modal show={show} onHide={handleClose} className="signInMoal">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalBodyArea">
        <img src={FrmLogo} className="mdlFrmLogo" />
        <div className="modalContent">
          <h3 className="mdlTitle">Forgot Password</h3>
          <p className="mdlText mb-3">
            Please enter your email to received a verification link to reset
            your password.
          </p>

          <Form className="formArea">
            <Row>
              <Col md="12">
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="labelText">Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    className="inputField"
                    {...register("email")}
                  />
                  {errors.email && (
                    <p className="text-danger">{errors.email.message}</p>
                  )}
                  <p className="backText">
                    Back to
                    <span className="frmLink" onClick={handleClose}>
                      Sign-in
                    </span>
                  </p>
                </Form.Group>
              </Col>
              {showAlert && (
                <Alert
                  onClose={() => setShowAlert(false)}
                  dismissible
                  className="frmAlert"
                >
                  <Alert.Heading>Verification Link</Alert.Heading>
                  <p>
                    We just sent you a reset link to your email! Click on the
                    link in email to reset your password.
                  </p>
                </Alert>
              )}
            </Row>
            <Row>
              <button
                className="frmMdlBtn mrLauto mt-5 "
                type="button"
                onClick={handleSubmit}
              >
                Continue
              </button>
            </Row>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPasswordView;
