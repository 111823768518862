import { useForm, Controller } from "react-hook-form";
import useSWR from "swr";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import httpClient from "./http-client";
import { SignUpSchema, SignInSchema, ForgotPasswordSchema, ResetPasswordSchema, JoinCompanySchema } from "utils/validations/AuthValidation";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../redux/reducers/LoaderSlice";
import { successLogin } from "../redux/reducers/AuthSlice";
import { useNavigate } from "react-router-dom";
import { handleApiResponseError, handleSwrResponseError, SwrConfigOptions } from "utils/helpers/Common";



export const useGetCompanyInvite = (inviteId) => {
	const dispatch = useDispatch();
	const { data: invite, error } = useSWR(
		inviteId
			? {
					url: `/company-invite/${inviteId}`,
					dispatch,
			  }
			: null,
		SwrConfigOptions
	);
	if (error) {
		//no toaster error because it will show in the modal
	}
	return { invite: invite?.data, error: error };
};
export const useSignUpForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		control,
		setValue,
	} = useForm({ resolver: yupResolver(SignUpSchema) });

	const _handleSubmit = (data) => {
		const filteredPrograms = data?.programs?.filter((program) => program !== "none");
		data.programs = filteredPrograms;
		dispatch(showLoader());
		signUpApi(data)
			.then((res) => {
				toast.success(res.data.message);
				dispatch(successLogin(res.data.data));
				navigate("/onboarding");
			})
			.catch((err) => {
				handleApiResponseError(setError, err.response);
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};
	return {
		register,
		handleSubmit: handleSubmit(_handleSubmit),
		errors,
		Controller,
		control,
		setValue,
		setError,
	};
};

export const useSignInForm = (location) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm({ resolver: yupResolver(SignInSchema) });

	const _handleSubmit = (data) => {
		dispatch(showLoader());
		signInApi(data)
			.then((res) => {
				dispatch(successLogin(res.data.data));
				let url = location?.state ? location.state.nextUrl : res?.data?.data?.is_om_super_admin ? "/setting" : "/setting/company";
				navigate(url);
			})
			.catch((err) => {
				handleApiResponseError(setError, err.response);
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};
	return { register, errors, handleSubmit: handleSubmit(_handleSubmit) };
};

export const useForgotPasswordForm = (setShowAlert) => {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		reset,
	} = useForm({ resolver: yupResolver(ForgotPasswordSchema) });

	const _handleSubmit = (data) => {
		dispatch(showLoader());
		forgotPasswordApi(data)
			.then((res) => {
				setShowAlert(true);
				reset();
			})
			.catch((err) => {
				handleApiResponseError(setError, err.response);
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};
	return { register, errors, handleSubmit: handleSubmit(_handleSubmit) };
};

export const useResetPasswordForm = (email, token) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
		getFieldState,
		setError,
	} = useForm({ resolver: yupResolver(ResetPasswordSchema) });

	const _handleSubmit = (data, handleClose) => {
		data.token = token;
		data.email = email;
		dispatch(showLoader());
		resetPasswordApi(data)
			.then((res) => {
				toast.success(res.data.message);
				handleClose();
				navigate("/");
			})
			.catch((err) => {
				handleApiResponseError(setError, err.response);
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};
	return {
		register,
		errors,
		handleSubmit: handleSubmit(_handleSubmit),
		getValues,
		getFieldState,
	};
};

export const useJoinCompanyForm = (invite) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm({
		resolver: yupResolver(JoinCompanySchema),
		values: {
			first_name: "",
			last_name: "",
			email: invite?.email,
			company_id: invite?.company?.id,
			role_id: invite?.role?.id,
		},
	});

	const _handleSubmit = (data) => {
		data.programs = invite?.programs;
		dispatch(showLoader());
		joinCompanyApi(data)
			.then((res) => {
				toast.success(res.data.message);
				dispatch(successLogin(res.data.data));
				navigate("/setting/company");
			})
			.catch((err) => {
				handleApiResponseError(setError, err.response);
			})
			.finally(() => {
				dispatch(hideLoader());
			});
	};
	return { register, errors, handleSubmit: handleSubmit(_handleSubmit) };
};

export const signUpApi = (data) => {
	return httpClient.post("/register", data);
};

export const joinCompanyApi = (data) => {
	return httpClient.post("/add-user", data);
};

export const signInApi = (data) => {
	return httpClient.post("/login", data);
};

export const forgotPasswordApi = (data) => {
	return httpClient.post("/forgot-password", data);
};

export const resetPasswordApi = (data) => {
	return httpClient.post("/reset-password", data);
};

export const getAuthUserApi = () => {
	return httpClient.get("/user/auth-user");
};

export const getImpersonationUserApi = (impersonationToken) => {
	return httpClient.get(`/impersonate?impersonation_token=${impersonationToken}`);
};
