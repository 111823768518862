import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalLogo from "assets/images/logo.svg";
import AscOrderIcon from "assets/images/icons/asc-order-icon.svg";
import DescOrderIcon from "assets/images/icons/desc-order-icon.svg";
import "./InvoiceDetailStyle.css";
import { formatDateToCustomString } from "utils/helpers/Common";
const InvoiceDetailView = ({ show, handleClose, company, invoice, details, search, setSearch, items, handleDateSort }) => {
	return (
		<Modal show={show} onHide={handleClose} size="lg" className="invoiceModalArea">
			<Modal.Header closeButton>
				<img src={ModalLogo} className="modalLogo" alt="" />
			</Modal.Header>
			<Modal.Body className="modalBodyArea">
				<div className="accountInfoBox">
					<h5>Company Name - {company?.name}</h5>
					<div className="mdlTextBar">
						<h6>Billing Cycle:</h6>
						<p>{`${invoice?.start_date} - ${invoice?.end_date}`}</p>
					</div>
					<div className="mdlTextBar">
						<h6>Invoice Date:</h6>
						<p>{invoice?.end_date}</p>
					</div>
				</div>
				<div className="invoiceMdlCard">
					<h5 className="pannelSmTitle">Plan Usage</h5>
					<div className="priceCard bglight">
						<div className="progressContent">
							<span className="invoiceTagBtn">{details?.plan?.title}</span>
							<span className="invoiceTagBtn">{details?.billing_type}</span>
							<div className="prCardTitleBar">
								<div className="spaceBetweenBar">
									<p>Plan Projects Used</p>
									<p className="tBold">{`${invoice?.utilized_projects} / ${details?.plan?.features?.jobs_per_month_include}`}</p>
								</div>
								<div className="spaceBetweenBar">
									<p>Additional Projects</p>
									<p className="tBold">{invoice?.additional_projects}</p>
								</div>
							</div>
							<div className="cardFtPannel">
								<div className="cardFtPannelItem">
									<p className="pXSText">Total Projects</p>
								</div>
								<div className="cardFtPannelItem">
									<span className="tBold">{invoice?.utilized_projects + invoice?.additional_projects}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="invoiceMdlCard">
					<h5 className="pannelSmTitle">Current Billing Cycle</h5>
					<div className="priceCard invoiceCard">
						<div className="priceTblArea">
							<table className="crdTabel">
								<thead>
									<tr>
										<th className="crdTblTh">
											<p className="tblText">Title</p>
										</th>
										<th className="crdTblTh">
											<p className="tblText">Total Jobs</p>
										</th>
										<th className="crdTblTh">
											<p className="tblText">Per Project</p>
										</th>
										<th className="crdTblTh">
											<p className="tblText">Total</p>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="crdTblTh">
											<p className="tblText">Plan Price</p>
										</td>
										<td className="crdTblTh">
											<p className="tblText">{invoice?.utilized_projects}</p>
										</td>
										<td className="crdTblTh">
											<p className="tblText">-</p>
										</td>
										<td className="crdTblTh">
											<p className="tblText bold">${details?.plan?.price}</p>
										</td>
									</tr>
									<tr className="">
										<td className="crdTblTh">
											<p className="tblText">Additional Projects</p>
										</td>
										<td className="crdTblTh">
											<p className="tblText">{invoice?.additional_projects}</p>
										</td>
										<td className="crdTblTh">
											<p className="tblText">${details?.plan?.features?.each_additional_job}</p>
										</td>
										<td className="crdTblTh">
											<p className="tblText bold">${invoice?.additional_projects_amount}</p>
										</td>
									</tr>

									<tr className="mdlTblTotalBar">
										<td className="mdlTblTotalTd" colSpan={4}>
											<div className="d-flex align-items-center innvoiceTotalBar">
												<p className="tblText">Total:</p>
												<p className="tblText rateText">${invoice?.total_amount}</p>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div className="invoiceMdlCard">
					<h5 className="pannelSmTitle">Projects Table</h5>
					<div className="searchBarArea">
						<input type="" className="searchInput" placeholder="search" value={search} onChange={(e) => setSearch(e.target.value)} />
						<svg className="serachIcon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M11.25 11.2505L8.21845 8.21887M8.21845 8.21887C9.03895 7.39837 9.4999 6.28553 9.4999 5.12517C9.4999 3.9648 9.03895 2.85196 8.21845 2.03146C7.39794 1.21095 6.2851 0.75 5.12474 0.75C3.96437 0.75 2.85153 1.21095 2.03103 2.03146C1.21053 2.85196 0.749573 3.9648 0.749573 5.12517C0.749573 6.28553 1.21053 7.39837 2.03103 8.21887C2.85153 9.03938 3.96437 9.50033 5.12474 9.50033C6.2851 9.50033 7.39794 9.03938 8.21845 8.21887Z"
								stroke="#A4A4A4"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</div>
					<div className="priceCard invoiceCard">
						<div className="priceTblArea mdlDataTable">
							<table className="crdTabel">
								<thead>
									<tr>
										<th className="crdTblTh">
											<p className="tblText">#</p>
										</th>
										<th className="crdTblTh">
											<div className="scrolldata">
												<div className="thArrows">
													<img src={DescOrderIcon} onClick={() => handleDateSort("desc")} />
													<img src={AscOrderIcon} onClick={() => handleDateSort("asc")} />
												</div>
												<p className="tblText">Date</p>
											</div>
										</th>
										<th className="crdTblTh">
											<p className="tblText">Program</p>
										</th>
										<th className="crdTblTh">
											<p className="tblText">Stage</p>
										</th>

										<th className="crdTblTh">
											<p className="tblText">Creator</p>
										</th>
									</tr>
								</thead>
								<tbody>
									{items?.map((item, ItemIndex) => (
										<>
											<tr key={"Item" + ItemIndex}>
												<td className="crdTbltd">
													<p className="tblText">{item.id}</p>
												</td>
												<td className="crdTbltd tdTextLeft">
													<p className="tblText">{formatDateToCustomString(item.CreatedAt)}</p>
												</td>
												<td className="crdTbltd">
													<p className="tblText">{item.program?.name}</p>
												</td>
												<td className="crdTbltd">
													<p className="tblText">{item.stage?.name}</p>
												</td>
												<td className="crdTbltd">
													<p className="tblText">{`${item.creator?.first_name} ${item.creator?.last_name}`}</p>
												</td>
											</tr>
											<tr>
												<td></td>
												<td colSpan={4} className="tdTextLeft">
													<p className="tblText">Address: {item.ProjectAddress}</p>
												</td>
											</tr>
											<tr>
												<td></td>
												<td colSpan={4} className="tdTextLeft">
													<p className="tblText tagFlex">
														Project: {item.ProjectName}
														<span className="tblTextTag">In plan</span>
													</p>
												</td>
											</tr>
										</>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default InvoiceDetailView;
