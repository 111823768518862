import React, { useState } from "react";
import ChangePasswordView from "./ChangePasswordView";
import { useChangePasswordForm } from "services/ProfileService";

const ChangePassword = () => {
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState(null);
  const { register, handleSubmit, errors, getValues, getFieldState, reset } =
    useChangePasswordForm();
  const handleClose = () => {
    setShow(false);
    setPassword(null);
    reset();
  };
  const handleShow = () => setShow(true);
  return (
    <ChangePasswordView
      show={show}
      handleClose={handleClose}
      handleShow={handleShow}
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      getValues={getValues}
      getFieldState={getFieldState}
      password={password}
      setPassword={setPassword}
    />
  );
};

export default ChangePassword;
