import React, { useEffect, useState } from "react";

const TableInput = ({
  stage,
  fieldId,
  isReadOnly,
  handleChange,
  stageIndex,
  isCustomized,
  isInherited,
  isStockValue,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(stage ?? "");
  }, [stage, isStockValue]);

  const backgroundColor =
    isCustomized && !isInherited ? "#ffd28073" : isInherited ? "#FFFFE0" : "";
  return (
    <input
      className={`tblText  inputTblText ${isReadOnly ? "bgGray" : ""}`}
      style={{ backgroundColor: backgroundColor }}
      placeholder=""
      value={value}
      onChange={(e) => setValue(e.target.value)}
      readOnly={isReadOnly}
      onBlur={() => handleChange(value, stageIndex)}
      disabled={isReadOnly}
    />
  );
};

export default TableInput;
