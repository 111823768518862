import { AUDITOR, CONTRACTOR, GUEST, PROGRAM_ADMIN } from "utils/constants/UserRoles";
export const getProgramRoleOptions = (companyRole, roles) => {
	const programRoles = roles?.filter((role) => role.type === "program");
	let options = [];
	switch (companyRole) {
		case AUDITOR:
			options = programRoles?.filter((role) => role.name === PROGRAM_ADMIN);
			break;
		case CONTRACTOR:
			options = programRoles?.filter((role) => role.name === PROGRAM_ADMIN || role.name === AUDITOR);
			break;
		case GUEST:
			options = programRoles?.filter((role) => role.name !== GUEST);
			break;
		default:
			options = programRoles;
	}
	return options;
};

export const findRole = (roles, roleId) => {
	return roles?.find((role) => role.id === roleId);
};
